/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button } from '@material-ui/core';
import { Column } from 'react-table';
import { useParams } from 'react-router-dom';
import PaginationTable from '../../../../components/PaginationTable';
import AdminService from '../../../../api/admin';
import { UserReducerContext } from './duck';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    paddingBottom: 32,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
  },
}));

const COLUMNS: Array<Column<any>> = [
  {
    Header: 'manage_user:hk_ticker',
    accessor: 'cmpOid',
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 250,
    Cell: ({ value, row: { original } }) => {
      if (original.status === 'pendingUnassign')
        return (
          <div style={{ textDecorationLine: 'line-through' }}>{value}</div>
        );
      return value || '-';
    },
  },
  {
    Header: 'manage_user:name',
    accessor: 'cmpEngName',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value, row: { original } }) => {
      if (original.status === 'pendingUnassign')
        return (
          <div style={{ textDecorationLine: 'line-through' }}>{`${
            original.cmpEngName || ''
          } ${original.cmpChiName || ''}`}</div>
        );
      return `${original.cmpEngName || ''} ${original.cmpChiName || ''}` || '-';
    },
  },
  {
    Header: 'manage_applications:approval_status',
    accessor: 'status',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => {
      if (value === 'pendingAssign' || value === 'pendingUnassign')
        return 'Pending';
      return 'Assigned';
    },
  },
];

const TabHKTicker: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    state: { user },
    dispatch,
  } = React.useContext(UserReducerContext);
  const [data, setData] = React.useState<(any & { status: string })[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getApplicationHkTicker(
        id,
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(
            res.data.data.map((item) => {
              let status = 'assigned';
              if (
                user?.pendingAssignedCsTickerNos?.includes(
                  item.cmpOid.replace('HK', ''),
                )
              ) {
                status = 'pendingAssign';
              } else if (
                user?.pendingUnassignedCsTickerNos?.includes(
                  item.cmpOid.replace('HK', ''),
                )
              ) {
                status = 'pendingUnassign';
              }
              return { ...item, status };
            }),
          );
          setPageCount(res.data.totalPage);
          setLoading(false);
        }
      });
    },
    [user?.romOids],
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={15}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name"
        placeholder={t('manage_user:rom_placeholder')}
      />
    </div>
  );
};

export default TabHKTicker;
