export default {
  common: {
    required: '*必须填写',
    create: 'Create',
    go_back: 'Go Back',
    select: '选择',
    cancel: '取消',
    close: '关闭',
    submit: '提交',
    delete: '删除',
    edit: '编辑',
    ok: '好',
    timeout_title: 'Timeout',
    timeout_message:
      "Your Tricor service will automatically time-out in around 1 minute.\nIf you wish to continue, please click 'OK'.",
    download: '下载',
    export: '转存',
    done: 'Done',
    assign: 'Assign',
    unassign: 'Unassign',
    save: '储存',
    remove: 'Remove',
    confirm: '确认',
    select_fields: '选择栏目',
    fields: '栏目',
    all: '全部',
    from: '由',
    to: '至',
    learn_more: '了解更多',
    learn_more_message:
      '感谢您对{{feature}}服务的关注。您的客户服务总监将与您联系。',
    learn_more_eagm: '「融会」',
    learn_more_eproxy: '电子委任代表系统「融会」',
    learn_more_boardfolio: '董事会议平台(Boardfolio)',
    learn_more_hkscc_registered_holders: '登记持有人之电子公司通讯',
    learn_more_hkscc_non_registered_holders:
      '香港中央结算非登记持有人之电子公司通讯',
    learn_more_ccass: '中央结算系统持股纪录',
    learn_more_ownership_analytics: '持有人及股东分析',
    learn_more_request_new_report:
      '已收悉您希望索取定制的报告。您的客户服务总监将尽快与您联系。',
    back: '返回',
    date_should_after_2021: '日期需为2021年1月1日或之后',
    start_date_should_before_end_date: '开始日期应早于结束日期或与结束日期相同',
    end_date_should_after_start_date: '结束日期应晚于开始日期或与开始日期相同',
    start_must_after_2016: '开始日期必须在2016年1月1日之后',
    date_must_within_a_year: '日期必须在今天止一年内。',
    date_cannot_be_future: '日期不能为未来日期。',
    start_must_after_minimal_date: '输入日期不应早于最小日期',
    enter_any_date_preceding_current_date: '输入当前日期之前的任何日期',
    date_must_before_2100: '日期需为2100年1月1日或之前',
    end_time_should_be_after_start_time: '结束时间不应早于开始时间',
    please_enter_valid_date: '请输入有效的日期格式(DD/MM/YYYY)',
  },
  feedback: {
    let_us_know_your_feedback: '让我们知道您的反馈，以便我们改善您的体验。',
    ask_feedback_title: '我们希望您的反馈。',
    ask_feedback_description:
      '很荣幸能为您提供帮助。反馈有助于我们建立更好的在线形象并提供更好的服务。您愿意花几分钟时间给我们评论吗？非常感谢您抽出宝贵时间分享您的经验。感谢您的意见。',
    leave_feedback: '留下反馈',
    maybe_later: '稍后留',
    do_not_show_again: '不再显示',

    name: 'Name',
    date: 'Date',
    comments: '意见',
    rating: '评分',

    give_us_feedback_title: '给我们反馈',
    give_us_feedback_message:
      '请与我们分享您的想法，逐步更好的体验。有了您的反馈，我们将能够不断改进并为您提供更好的服务。',
  },
  error: {
    required: '必须填写',
    invalid_email: 'Invalid email address',
    required_id_or_passport_number:
      'Please enter "Hong Kong Identity Card Number" or "Passport Number"',
  },
  tabbar: {
    logout: 'Log Out',
    home: '主页',
    register_of_members: '成员登记册',
    ccass_holding: '中央结算系统持股记录',
    reports: '报告',
    entity_management: '管理我的公司',
    market_intelligence: '市场信息',
    industry_analyst_report: '行业分析报告',
    shareholder_analytics: '持有人及股东分析',
    eagm: '融会',
    eproxy: '电子委任代表系统',
    invoice_management: '账单管理',
    board_portal: '董事会议平台',
    thought_leadership: '卓尔洞察',
    manage_company: 'Manage Company',
    manage_rom: 'Manage ROM',
    manage_user: 'Manage User',
    manage_request: 'Manage Request',
    manage_feedback: 'Manage Feedback',
    manage_thought_leadership: 'Manage Tricor Inside Newsletter',
    manage_staff: 'Manage Staff',
  },
  login: {
    root_title: 'Navigating Possibilities',
    welcome: 'Welcome 欢迎',
    title: 'Online Company Secretary Platform',
    contact_us: 'Contact Us 联络我们',
    email: 'Email 电邮',
    password: 'Password 密码',
    forget_password: 'Forget Password? 忘记密码?',
    sign_in: 'Sign In 登记',
    reset_password: 'Reset Password',
    reset_password_description:
      'Enter your registered email below to receive password reset instruction',
    reset_password_success_description: '已发出重置密码的电邮。',
    reset_password_expired: 'Reset Password',
    reset_password_expired_message:
      'We are sorry, the password reset link seems expired or invalid.',
    set_password_success: 'Set Password Success',
    set_password_success_message: 'Please use the new password to sign in.',

    set_password_title: 'Choose a new password',
    set_password_description:
      'A strong password consists of at least 12 characters that are a combination of uppercase letters, lowercase letters, numbers and special characters(@, #, $, %, etc.)',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    account_verification: 'Account Verification\n账号验证',
    account_verification_message:
      'Please enter the one-time verification code sent to your registered email to access your account.\n请输入发送到阁下登记电邮的一次性验证码以进入阁下的账号。',
    account_verification_message_sms:
      'Please enter the one-time verification code sent to your registered mobile number {{phoneNumber}}.\n请输入发送到阁下登记手机号码{{phoneNumber}} 的一次性验证码。',
    send_verification_code: '發送驗證碼',
    resend_verification_code: '重新發送驗證碼',
    invalid_verification_code: '你輸入的驗證碼不符或已失效，請重新輸入。',
    confirm: '確認',
    request_new_code_in:
      'You may request for verification code or change account verification method after {{time}}\n阁下可于{{time}}后重新获取验证码或更改账号验证方式。',
  },
  relationship_manager: {
    client_management_team: '客户管理团队',
    client_director: '客户总监',
    contact_us: '联络我们',
    contact: '联络',
    office_address: '办公地址',
    email: '电邮',
    phone: '电话',
    contact_tricor: 'Contact Tricor',
  },
  home: {
    stock_quotation: '证券报价',
    last_updated: '最后更新',
    delayed_quote: '延迟报价',

    events: '活动',
    event_detail: '活動詳情',
    events_placeholder: 'No upcoming events after {{date}}.',
    ccass_holding: '中央结算系统持股记录',
    ownership_analytics: '持有人及股东分析',

    create_an_event: '建立活动',
    edit_an_event: '编辑活动',
    title: '标题',
    description: '详情',
    date: '日期',
    time: '时间',
    start_time: '开始时间',
    end_time: '结束时间',
    start: '开始时间',
    end: '结束时间',
    all_day: '全日',
    year: '年份',
    upcoming: '即将来临',
    past: '过往',
    upcoming_event_detail: '活动详情',
    hk_public_holiday: '香港公众假期',

    share_registration_and_issuer_services: '股份登记及发行人服务',
    registry_management: '保存及管理股东登记册',
    corporate_actions: '企业行动',
    shareholder_communications: '股东通讯',
    dividend_payment: '股息支付',
  },
  entity_management: {
    current_shareholders: '现任股东',
    list_of_entities: '公司名单',
    entity_list_placeholder: 'No entity',
    search_company_placeholder: '按公司名称搜索',
    select_company: '选择公司',

    my_requests: 'My Requests',
    my_requests_placeholder: 'There are no upcoming tasks',
    my_requests_submitted: 'Submitted',
    my_requests_completed: 'Completed',
    company_summary: '公司概要',

    upcoming_tasks: 'Upcoming Tasks',
    upcoming_tasks_placeholder: 'There are no upcoming tasks',

    recent_documents: '最近的文件',
    recent_documents_placeholder: '没有最近的文件。',
    search_recent_documents_placeholder: '按描述搜索',
    date: '日期',
    code: 'Code',
    task_title: 'Task Title',
    status: '公司现况',
    active: '存续',
    closed: '已关闭',
    closing: '即将关闭',
    category: '类别',
    description: '描述',
    bank__financial_statements_financial_statements: '财务报表',
    statutory_legal_statutory_forms__submission:
      '法令的 /法定的: 法定表格 / 呈递',
    statutory_legal_minutes_and_resolutions_director__officer:
      '法令的 /法定的: 会议记录及决议: 董事及高级人员',
    file_code: 'File Code',
    name: '名称',
    company_name: '公司名称',
    date_of_incorporation: '成立日期',
    place_of_incorporation: '成立地点',
    company_secretary: '公司秘书',
    registration_number: '登记号码',
    jurisdiction: '管辖权',
    british_virgin_islands: '(托尔托拉岛)英属维尔京群岛',
    cayman_islands: '凯曼群岛',
    china: '中国',
    hong_kong: '香港',
    labuan: '纳闽',
    registered_office: '注册办事处地址',
    incorpated_in: '于{{country}}成立',
    current_directors: '现任董事',
    select_dates: 'Select Dates',
    appointment_date: '委任日期',
    resignation_date: 'Resignation Date',
    download_list_report: 'Download List Report',
    // Create Request
    create_request: 'Create Request',
    type_of_request: 'Type of Request:',
    please_input_details_below: 'Please input details below:',

    submit: 'Submit',
    cancel: 'Cancel',
    change_of_directors: 'Change of Directors',
    directors_particulars_change: 'Director’s Particulars Change',
    request_for_statutory_review: 'Request for Statutory Review',
    transfer_of_shares: 'Transfer of Shares',

    resignation_of_director: 'Resignation of Director',
    alternate_to: 'Alternate to:',
    alternate_director: 'Alternate Director',
    name_in_chinese: 'Name in Chinese:',
    name_in_english: 'Name in English:',
    alias_in_chinese: 'Alias in Chinese:',
    alias_in_english: 'Alias in English:',
    name_of_resigning_director: 'Name of resigning director',
    reason_of_cessation: 'Reason of Cessation:',
    resignation_or_others: 'Resignation / Others',
    deceased: 'Deceased',
    name_of_director: 'Name of director:',
    name_of_director_en: 'Name of director in English:',
    name_of_director_zh: 'Name of director in Chinese:',
    phone_number: 'Phone Number:',
    email_address: 'Email Address:',
    residential_address: 'Residential Address:',
    effective_date_of_change: 'Effective date of the change:',
    address_line: 'Address line {{number}}',
    hk_id_card_number: 'Hong Kong Identity Card Number:',
    passport_issuing_country: 'Passport Issuing Country / Region:',
    passport_number: 'Passport Number:',
    updated_particulars:
      'Updated Particulars (Please complete item(s) with change(s) only)',
    supporting_documents_needed: 'Supporting Documents Needed',
    supporting_documents: 'Supporting Documents',
    certified_id_copy_if_changed:
      'Certified true copy of HKID card/ passport (if HKID/ passport has changed):',
    certified_id_copy: 'Certified true copy of HKID card/ passport:',
    proof_of_address_if_changed: 'Proof of Address (if address has changed):',
    proof_of_address: 'Proof of Address:',
    name_of_audit_firm_name: 'Name of Audit Firm:',
    name_of_contact_person: 'Name of Contact Person:',
    preferred_date_time: 'Preferred date time:',
    preferred_time: 'Preferred time:',
    years_of_compnay_records_to_be_reviewed:
      'Years of company records to be reviewed:',
    consideration_to_be_received_or_paid: 'Consideration to be received/ paid:',
    type_of_review: 'Type of Review:',

    obtain_ecopies: 'Obtain e-copies',
    onsite_visit: 'Onsite visit',

    transferor: 'Transferor',
    transferee: 'Transferee',
    name_of_transferor: 'Name of Transferor:',
    name_of_transferee: 'Name of Transferee:',
    transferor_address: 'Address of Transferor:',
    transferee_address: 'Address of Transferee:',
    occupation_of_transferor: 'Occupation of Transferor:',
    occupation_of_transferee: 'Occupation of Transferee:',
    number_of_shares_to_be_transferred: 'Number of Shares to be Transferred:',
    value_of_transfer: 'Value/ Considerations of Transfer:',
    effective_date_of_transfer: 'Effective Date of the Transfer:',
    sale_and_purchase_agreement: 'Sale and Purchase Agreement:',
    sale_and_purchase_agreement_if_any: 'Sale and Purchase Agreement (If Any):',
    latest_consolidated_statesment:
      'Latest Consolidated Audited Financial Statements of the Company:',
    latest_management_accounts: 'Latest Management Accounts of the Company:',

    registered_address: 'Registered Address:',
    directors: 'Directors',
    shareholders: '股东',

    re_notice_cod: 'Re: Notice of Change in Particulars of Company Secretary',
    re_notice_dpc: 'Re: Notice of Director’s Particulars Change',
    re_notice_rsr: 'Re: Notice of Request for Statutory Review',
    re_notice_ts: 'Re: Notice of Transfer of Shares',

    request_of_type: 'Request of {{type}}',

    submitted: 'Submitted',
    completed: 'Completed',
    rejected: 'Rejected',
    pending: 'Pending',
  },
  manage_company: {
    back: 'Back to list of companies',
    select_company: '选择公司',
    select_company_placeholder: 'No company',
    date: '日期',
    file_code: 'File Code',
    email: '电邮',
    name: 'Name',
    area_code: '国家/地区代码',
    phone_number: '手机号码',
    status: 'Status',
    registered_office: '注册办事处地址',

    access_rights_list: '权限设置清单',
    existing_users: 'Existing Users',
    existing_users_placeholder: 'No Existing Users',
    company_profile: 'Company Profile',

    create_access_right: 'Create Access Right',
    edit_access_right: '更改权限设置',

    select_users: 'Select Users',
    add_user: 'Add User',
    remove_user: 'Delete Account',
    remove_user_message: 'Are you sure you want to remove user {{mail}}?',
  },
  manage_rom: {
    select_company: '选择公司',
    select_company_placeholder: 'No company',
    back: 'Back to list of ROM companies',
    select_user: 'Select User',
    add_user: 'Add User',
  },
  manage_user: {
    users_data_download: 'Users Data Download',
    back: 'Back to list of users',
    select_user: 'Select User',
    select_user_placeholder: 'No users',
    name: '名称',
    email: '电邮',
    status: '状态',
    profile: 'User Profile',
    register_of_members: '成员登记册',
    entity_management: '管理我的公司',
    contact_point: '联络人',
    create_user: '创建使用者帐户',
    user_info: '账户资料',
    access_rights: '权限设置',
    last_login: '上次登录日期',
    created_at: '创建日期',

    delete_user: 'Delete Account',
    delete_user_message:
      'Are you sure you want to delete account {{mail}}?\nThis cannot be undone.',

    staffs: 'Staffs',
    staffs_placeholder: 'No staff',
    rom_placeholder: 'No company',
    entity_management_placeholder: 'No company',
    relationship_manager: 'Relationship Manager',
    management_team: 'Management Team',
    select_new_relationship_manager: 'Select New Relationship Manager',
    please_select_rm: 'Please Select Relationship Manager',
    primary: 'Primary',
    set_as_primary: 'Set as Primary',
    remove: 'Remove',

    unlock: 'Unlock',
    resend: 'Resend',
    invitationsent: '已邀请',
    active: '已激活',
    locked: 'Locked',
    reset_password: 'Reset Password',

    error_mobile_format: '请输入正确格式的手机号码',
    error_email_already_registered: '此邮箱已注册',
  },
  manage_staff: {
    back: 'Back to list of staffs',
    select_staff: 'Select Staff',
    select_staff_placeholder: 'No staff',
    create_staff: 'Create Staff',
    name: 'Name',
    email: 'Email',
    status: 'Status',
    role: 'Role',
    last_login: 'Last Login',
    created_at: 'Created At',

    add_client: 'Add Client',
    delete_user: 'Delete Account',
    delete_user_message:
      'Are you sure you want to delete account {{mail}}?\nThis cannot be undone.',

    unlock: 'Unlock',
    resend: 'Resend',
    invitationsent: 'Invited',
    active: 'Active',
    locked: 'Locked',
    reset_password: 'Reset Password',

    staff_profile: 'Account Information',
    existing_clients: 'Existing Clients',
    existing_clients_placeholder: 'No Existing Clients',
    edit_role: 'Edit Role',
  },
  manage_request: {
    manage_request: 'Manage Request',
    request_type: 'Request Type',
    date: 'Date',
    company: 'Company',
    task_title: 'Task Title',
    status: 'Status',
  },
  manage_thought_leadership: {
    manage_thought_leadership: 'Manage Tricor Inside Newsletter',
    created_at: 'Created At',
    title: 'Title',
    description: 'Description',
    image: 'Image',
    weblink: 'Link',
    create_article: 'Create Article',
    edit_article: 'Edit Article',
    delete_article: 'Delete Article',
    delete_article_message:
      'Are you sure you want to delete this article?\nThis cannot be undone.',
  },
  name_card: {
    office_address: 'Office Address',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
  },
  register_of_members: {
    select_company: '选择公司',
    search_company_placeholder: '按公司名称搜索',
    issue_summary: '发行总览',
    holders_list_report: '持有人清单报告',
    holder_list: '持有人清单',
    holder_list_placeholder: '没有搜索结果',
    holder_list_search_placeholder: '搜寻包括持有人姓名，帐户号码，证书编号',
    details: '详情',
    issued_share_movement_history: '已发行证券变动记录',
    share_movement_history_placeholder: '没有搜索结果',
    share_movement_history_search_placeholder: '按类别搜寻',
    start_date: '开始日期',
    end_date: '结束日期',
    transaction_amount: '交易数量',
    transaction_unit_or_shares: '交易股份/单位',
    member_analysis: '成员分析',
    analysis: '的分析报告',
    no_of_holders: '持有人数量',
    issued_capital: '已发行股份/单位',
    par_value: '面值',
    trading_counter: '交易柜台',
    ecorporate_communication: '电子公司通讯',
    registered_holders: '电子公司通讯：\n登记持有人',
    hkscc_non_registered_holders: '电子公司通讯：\n香港中央结算非登记持有人',
    select_no_of_shareholders: '持有人数量',
    request_for_service: '联络我们以启用服务',
    sort: 'Sort',

    type_of_report: 'Type of Report',
    full_list: 'Full List',
    top_holder_list: 'Top Holder List',
    number_of_holders: '持有人数量',
    number_of_registered_holders: '登记持有人数量',
    all: '全部',
    top: '首',
    last: 'Last',
    sort_by: '排序方式',
    sort_order: 'Sort Order',
    asc: 'Ascending',
    desc: 'Descending',
    holders: '名持有人',
    fields: 'Fields',
    account_number: '帐户号码',
    download_holders_report: '下载持有人清单',
    download_report: '下载报告',
    holding_balance: 'Holding Balance',
    folio_no: '帐户号码',
    hdr_name: '持有人姓名',
    hdr_name_1: '持有人姓名',

    class_of_securities: '证券类别:',
    ordinary_shares: '普通股',
    preference_shares: 'Preference Shares',
    warrants: 'Warrants',
    dual_currency_securities: 'Dual Currency Securities',

    symbol: 'Symbol',
    account_no: '帐户号码',
    cert_no: '证书编号',
    no_of_certs: 'Number of Certificates',
    certificates_detail: '证书详情',
    no_shares_units: '股份/单位数量',
    cert_value: 'Value',
    holder_name: '姓名',
    name: '姓名',
    address: '地址',
    balance: '结存',
    country_area: '国家/地区',
    security_class: 'Security Class',
    means_of_communication: '接收公司通訊的方式',
    access_through_others: '其他',
    access_through_website: '经网上阅览',
    access_through_physical_copy: '收取印刷本',
    special: '特殊处理',
    new_holders: '新登记持有人',
    physical_copy_in_english_and_chinese: '中文及英文印刷本',
    physical_copy_in_english: '英文印刷本',
    physical_copy_in_chinese: '中文印刷本',
    notification_by_mail: '邮寄通知函',
    notification_by_email: '电邮通知函',
    dividend_election_instruction: '永久选择收取现金股息/以新股代替现金股息',
    no_instruction: '没有指示',
    permanent_election_for_scrip: '选择永久收取代息股份',
    permanent_election_for_cash: '选择永久收取现金股息',
    autopay_instruction: '收取现金股息的方式',
    currency_for_cash_dividend: '永久选择收取现金股息货币',
    currency_election: '永久选择收取现金股息货币',
    automatic_payment: '自动转帐',
    cheque: '支票',
    registered_holders_label: 'Registered Holders (“RHs”)',
    holder_information: '持有人资料',
    holder_details: '持有人詳情',
    preferences: '持有人选择',

    date_of_event: '发生日期',
    nature: '类别',
    issued_securities_balance: '已发行证券结存',
    select_category: '选择类别',
    overseas_holders_analysis: '地域分析报告',
    total_holders: '持有人总数',
    percentage_of_holders: '持有人百分比',
    total_holdings: '总计持有',
    percentage_of_issued_securities: '已发行证券百分比',
    payment_instruction: '股息支付指示',
    currency: 'Currency',

    means_of_receiving_corporate_communications: '接收公司通讯方式的分析报告',
    others: 'Others',
    number_of_registered_holders: '登记持有人数量',
    email_notification_on_publication_of_corporate_communications: '电邮通知函',
    physical_otification_on_publication_of_corporate_communications:
      '印刷本通知函',
    physical_copy_in_english_and_chinese_html: '中文及英文',
    physical_copy_in_english_html: '英文',
    physical_copy_in_chinese_html: '中文',
    automatic_payment_html: '<span class=red>自动转帐</span>',
    cheque_html: '<span class=red>支票</span>',
    to_receive_dividend_in_scrip_html: '新股份',
    to_receive_dividend_in_cash_html: '现金',
    no_instruction_given_html: '<span class=red>没有指示</span>',
    no_instruction_html: '<span class=red>沒有指示</span>',
    total: '总数',

    not_applicable: '不适用',
    has_corp_comm: '已采用',

    range_of_holdings_analysis: '持股量范围分析报告',
    range_of_holdings: '持股量范围',
    above: '超过',
    to: '至',
    from: '由',
    generate_range_of_holdings_report: '生成持股量范围报告',

    error_input_value_should_be_larger_than: '输入之数值需大于 {{value}}',
  },
  thought_leadership: {
    featured_articles: '精选文章',
  },
  ccass_holding: {
    holder_list_placeholder: '没有搜索结果。请选择其他日期。 ',
    shareholding_by_holder_types_percent: '各类持有人之持股量百分比',
    top_10_daily_holding_changes_in_ccass:
      '中央结算系统{{count}}大即日持股量变动(最后工作日）',
    top_10_holders_in_ccass: '中央结算系统{{count}}大持有人',
    participant_name: '参与者名称',
    last_holding_date: '最后持股日期',
    shareholding: '持股量',
    change: '变动',
    percent_holding: '持股量百分比',
    percent_change: '变动百分比',
    percent_holdings_change: '持股量变动百分比',

    ccass_participant_information: '中央结算系统参与者资料',
    participant_details: '参与者详情',
    participant_id: '参与者编号',
    ccass_participant_name: '参与者名称',
    address: '地址',
    percent_of_share: '占已发行股份/权证/单位百分比',
    southbound_holding: '港股通持股纪录',
    select_last_holding_date: '选择最后持股日期',
    data_not_available: '您选择的日期没有资料，请选择其他日期',
    date_is_holiday: '日期必须为工作日。',
  },
  reports: {
    request_new_report: '索取新报告',
    scheduled: '恒常报告',
    on_demand: '按需报告',
    transfer_journal: '转让日志',
    top_holders_list: '主要持股人清单',
    holders_list: '持有人清单',
    coming_soon: '快将推行',
    oversea_holders_list: '海外持有人名单',
    allotment_report: '配发报告',
    buyback_report: '证券注销报告',
    other_report: '其他报告',
    select_year: '选择年份',
    historical_on_demand_report: '过往的按需报告',
    file_name: '档案名称',
    date: '日期',
    date_or_period: '日期/时期',
    all: '全部',
    not_available: '不适用',
    please_select_required_report: '请选择所需报告',
  },
  boardfolio: {
    boardfolio_intro: '数字化如何令董事会会议更高效？',
    what_is_boardfolio: '什么是 BOARDFOLIO？',
    what_is_boardfolio_description:
      'Boardfolio是卓佳先进的董事会门户软件解决方案，可令举行董事会会议变得更轻松，并实现董事会与管理层之间的实时无缝协作。它提供安全的中央数据库，存储与董事会会议相关的所有材料，为董事会 成员和董秘团队提供管理上的便利。 ',
    advanced_corporate_governance: '先进的公司治理',
    advanced_corporate_governance_description:
      'Boardfolio帮助企业达到公司治理、合规性和安全协作的标准，并可以将依赖人手及纸本文件的董事会会议流程无缝转移至安全的数字平台。先进的文档安全性和数据加密功能协助高级管理人员可以作出快 速而全面的决定。 ',
    seamless_meeting_management: '无缝点对点会议管理',
    seamless_meeting_management_description:
      'Broadfolio不受地理限制，能支持多种设备、细分登入权限和实时数据共享，旨在解决董事会会议管理中的众多问题。会议解决方案包括董事会会议信息集的创建、议程建立、出席记录、会议记录发布和工 作分配管理。董秘团队可以把科技应用于会议各个阶段（会议前，会议中和会议后），从而节省大量时间和成本。',
    what_are_the_benfits: 'BOARDFOLIO 有何优势？',
    security_and_access_control: '安全和登入权限控制',
    security_and_access_control_description:
      '<ul><li>先进的数据加密功能</li><li>加强保密和隐私性</li><li>自定登入权限</li><li>精细的文档层面控制（查看/下载/注释）</li></ul>',
    seamless_collaboration: '无缝协作',
    seamless_collaboration_description:
      ' <ul><li>利用云技术实时访问董事会材料</li><li>促进委员会合作的虚拟工作组</li><li>评论和讨论工具</li><li>轻松建立特殊项目/团队</li></ul>',
    enhanced_productivity_and_efficiency: '提高生产力和效率',
    enhanced_productivity_and_efficiency_description:
      '<ul><li>仪表板提供任务摘要和快速访问链接</li><li>全面的任务和项目追踪</li><li>文档版本自动更新</li><li>轻松创建董事会信息集</li><li>同时管理多个项目</li></ul>',
    adminstrative_ease: '行政便利',
    adminstrative_ease_description:
      '<ul><li>组织会议、建立议程及董事会信息集</li><li>快速制作会议记录和记录出席情况</li><li>即时发布会议记录，分配任务并存档会议</li></ul>',
    how_can_tricor_help: 'BOARDFOLIO 如何无缝协作？',
    how_can_tricor_help_description:
      '通过Boardfolio，卓佳为您提供采用最新技术的先进解决方案，令您在管理敏感的董事会信息时更加轻松。 您可以依靠我们高度安全的托管解决方案，这些解决方案有助于确保您的数据受到保护并可随时存取。\n\n我们聘请独立的专家进行频繁的安全审核，以确保流程的完整性和最佳实践。  Boardfolio完全符合GDPR要求，并获得Verasafe隐私印章认证，让您全面接连第三方数据请求，在各个层面及传输过程中的数据加密功能令您安心。 \n\n作为您的伙伴，卓佳助您增强竞争力并创造业务价值。 我们提供客户指导、专属的客户经理和迅速的客户支持服。 Boardfolio可令举行董事会会议变得更轻松，与我们合作将使您的董事会流程管理更上一层楼。',
  },
  eagm: {
    hybrid_meeting: '混合会议',
    what_is_hybrid_meeting: '如何利用科技来提升对股东大会的管理？',
    what_is_hybrid_meeting_description:
      '混合会议结合了传统实体会议和虚拟会议，是一个综合的会议平台。混合会议通过先进的技术，让成员可以选择亲自或线上参与会议，提供更高效的会议方式，同时有效管理沟通和投票事宜。虚拟会议（线上）和混合会议（线上和实体）解决了实体会议的挑战，对提高成员出席率十分重要。这种会议方式具有高度的可扩展性，可节省大量成本并减少行政工作，也可提高成员的参与度。融会（SPOT）容许成员进行线上投票，透过实时播放功能观看会议以及作出线上提问，就如亲身参与实体会议，拥有安全的在线投票功能和观看即时结果是融会的两大优势。由于世界正面临越来越多的环境和公共卫生挑战，加上经常实施出行限制，因此融会能在这种意料之外的情况下发挥重要作用，使会议能够按照计划透过我们的安全系统顺利进行。',
    hybrid_meeting_in_a_snapshot: '混合会议简介',
    hybrid_meeting_what_are_the_benfits: '混合会议有何优势？',
    member_engagement: '成员参与度',
    member_engagement_description:
      '<ul><li>未能亲自参加会议的成员能选择线上出席</li><li>透过不同设备和平台随时登入</li><li>与亲身参与会议享有相同的权利和好处</li></ul>',
    real_time_collaboration: '提供即时协作',
    real_time_collaboration_description:
      '<ul><li>数字化工具提升协作体验</li><li>方便主席与成员分享提问</li><li>多源实时播放和内容共享</li><li>进行即时在线投票和提交投票</li><li>享用主席介面</li></ul>',
    transparency_and_security: '增加透明度与安全性',
    transparency_and_security_description:
      '<ul><li>即时发布投票结果</li><li>直接互动，董事会能即时回答提问</li><li>增强投票和报告準确性</li><li>安全登入</li><li>使用云端设备及加密技术</li></ul>',
    saving_and_efficiency: '成本节省和高效',
    saving_and_efficiency_decscription:
      '<ul><li>电子投票显著能节省成员交通时间</li><li>虚拟会议达到节省成本</li><li>拥有高度扩展性，参与人数不限</li><li>透过虚拟参与提高效率</li></ul>',
    spot_is_ultimate_solution: '融会是混合会议的最佳解决方案',
    spot_is_ultimate_solution_description:
      '卓佳是会议管理和投票监督服务的市场领导者。我们确保上市发行人完全符合所有相关监管要求，是您值得信赖的合作夥伴。融会是我们在过去20多年从服务数以千计客户股东大会上获得的经验而结集的心血结晶。我们为您管理整个会议流程，从审视所有会议文档、会议通知、在线会议用户指南、会议过程、主席讲话文稿，以至在会议前测试系统及与主席排演流程。\n\n卓佳的高度可扩展科技在安全的专用网络上运行，存储和传输所有机密数据均使用行业标準的加密系统进行加密。此外，卓佳亦提供ISO 27001认證的云端技术和基础设备，以确保可靠的服务和自动故障转移机制，支持系统的高可用性。',

    eproxy_intro: '电子代理系统',
    what_is_eproxy: '如何通过安全的电子代理系统增强投资者关系？',
    what_is_eproxy_description:
      '为了确保良好的公司治理，必须让尽可能多的股东参与重大决策。 但是，由于各种原因，很难保证所有董事会成员和股东出席。 在全球范围内，当电子会议（混合或虚拟）和电子代理功能到位时，公司的参与度将有所增加。电子代理系统通过为股东提供结构化和安全的在线平台来处理电子代理人委任和投票指示。',
    what_are_the_benfits: '对企业的价值',
    streamlining_management_of_proxies: '简化代理管理',
    streamlining_management_of_proxies_description:
      '<ul><li>可立即修改代理人任命和投票指示的实时信息</li><li>全面的委托摘要，避免大股东或其他个人或机构投资者偶尔遗漏投票</li></ul>',
    facilitating_investor_relations: '促进投资者关系',
    facilitating_investor_relations_description:
      '<ul><li>最新的投票指导信息，用于必要的投资者关系和代理人招募政策的微调</li><li>与股东进行电子沟通以推动参与</li></ul>',
    enhancing_corporate_governance: '加强公司管治',
    enhancing_corporate_governance_description:
      '<ul><li>增加了股东在重大决策的参与度</li><li>通过为股东提供一个电子和直观的代理交付系统来提升公司形象</li></ul>',
    enjoying_security_and_efficiency: '享受安全和效率',
    enjoying_security_and_efficiency_description:
      '<ul><li>加密的服务器网络，可将股东提交的所有信息进行保密</li><li>以安全有效的方式提交的电子代理人任命/指示</li></ul>',
    how_can_tricor_help: '卓佳如何提供帮助？',
    how_can_tricor_help_description:
      '卓佳作为一站式服务商，提供具有凝聚力的电子会议和电子代理解决方案，以确保大会的无缝举行和主持，以及成功的现场、在线投票以及结果验证。 我们的专业团队还可以帮助您审查和修改公司章程，以使电子股东大会和代理成为可能。',
    key_features_of_eproxy: '电子代理系统的主要功能',
    key_features_1: '股东可以每天查看有权持有的股份',
    key_features_2:
      '在提交截止日期前的任何时间（24/7）进行输入，只需一键点击即可方便股东',
    key_features_3:
      '提供多种输入功能，包括指定单个/多个代理（带或不带投票指令）、在指定截止日期之前取消和修改代理人委任/投票指令',
    key_features_4:
      '股东可以下载与成功提交的代理人任命/投票说明相关的收据进行记录',
    key_features_5: '方便中央结算及交收系统以电子方式提交参与者的代理任命/指示',

    stock_code: '股份代号',
    company: '公司/主办机构',
    meeting_type: '会议类别',
    meeting_time: '会议时间',
    search_placeholder: '搜尋',
    welcome_to_spot: '欢迎登入融会（卓佳电子会议系统）',
  },
  eproxy: {
    eproxy_intro: '电子代理系统',
    what_is_eproxy: '如何通过安全的电子代理系统增强投资者关系？',
    what_is_eproxy_description:
      '为了确保良好的公司治理，必须让尽可能多的股东参与重大决策。 但是，由于各种原因，很难保证所有董事会成员和股东出席。 在全球范围内，当电子会议（混合或虚拟）和电子代理功能到位时，公司的参与度将有所增加。电子代理系统通过为股东提供结构化和安全的在线平台来处理电子代理人委任和投票指示。',
    what_are_the_benfits: '对企业的价值',
    streamlining_management_of_proxies: '简化代理管理',
    streamlining_management_of_proxies_description:
      '<ul><li>可立即修改代理人任命和投票指示的实时信息</li><li>全面的委托摘要，避免大股东或其他个人或机构投资者偶尔遗漏投票</li></ul>',
    facilitating_investor_relations: '促进投资者关系',
    facilitating_investor_relations_description:
      '<ul><li>最新的投票指导信息，用于必要的投资者关系和代理人招募政策的微调</li><li>与股东进行电子沟通以推动参与</li></ul>',
    enhancing_corporate_governance: '加强公司管治',
    enhancing_corporate_governance_description:
      '<ul><li>增加了股东在重大决策的参与度</li><li>通过为股东提供一个电子和直观的代理交付系统来提升公司形象</li></ul>',
    enjoying_security_and_efficiency: '享受安全和效率',
    enjoying_security_and_efficiency_description:
      '<ul><li>加密的服务器网络，可将股东提交的所有信息进行保密</li><li>以安全有效的方式提交的电子代理人任命/指示</li></ul>',
    how_can_tricor_help: '卓佳如何提供帮助？',
    how_can_tricor_help_description:
      '卓佳作为一站式服务商，提供具有凝聚力的电子会议和电子代理解决方案，以确保大会的无缝举行和主持，以及成功的现场、在线投票以及结果验证。 我们的专业团队还可以帮助您审查和修改公司章程，以使电子股东大会和代理成为可能。',
    key_features_of_eproxy: '电子代理系统的主要功能',
    key_features_1: '股东可以每天查看有权持有的股份',
    key_features_2:
      '在提交截止日期前的任何时间（24/7）进行输入，只需一键点击即可方便股东',
    key_features_3:
      '提供多种输入功能，包括指定单个/多个代理（带或不带投票指令）、在指定截止日期之前取消和修改代理人委任/投票指令',
    key_features_4:
      '股东可以下载与成功提交的代理人任命/投票说明相关的收据进行记录',
    key_features_5: '方便中央结算及交收系统以电子方式提交参与者的代理任命/指示',
  },
  currency: {
    singapore_dollars: '新加坡元',
    japanese_yen: '日元',
    korean: '韩元',
    hong_kong_dollars: '港元',
    united_states_dollars: '美元',
    australia: '澳大利亚元',
    chinese_rmb: '人民币',
    norway: '挪威克朗',
    european: '欧元',
    malaysian: '马来西亚林吉特',
    canada: '加拿大元',
    british: '英镑',
    chinese_yuan: '人民币',
  },
  country: {
    bermuda_or_cayman_islands: '',
    'bermuda_or_hong_kong_re-domiciled': '',
    bermuda_liquidation: '',
    'bvi_or_cayman_islands_re-domiciled': '',
    cayman_islands_or_bermuda: '',
    'cayman_islands_or_bermuda_re-domiciled': '',
    'cayman_islands_or_bermuda_re-domiciled_liquidation': '',
    'cayman_islands_or_hong_kong_re-domiciled': '',
    cayman_islands_liquidation: '',
    'singapore_or_hong_kong_re-domiciled': '',
    albania: '阿尔巴尼亚',
    algeria: '阿尔及利亚',
    angola: '安哥拉',
    'anguilla,_b.w.i.': '安圭拉',
    argentina: '阿根廷',
    armenia: '亞美尼亞共和國',
    '(z)_australia': '澳大利亚',
    australia: '澳大利亚',
    austria: '奥地利',
    bahamas: '巴哈马',
    bahrain: '巴林',
    bangladesh: '孟加拉',
    barbados: '巴巴多斯',
    belgium: '比利时',
    belize: '伯利兹',
    bermuda: '百慕大',
    bhutan: '不丹',
    bolivia: '玻利维亚',
    botswana: '博茨瓦纳',
    brazil: '巴西',
    british_isles: '不列颠群岛',
    '(z)_british_virgin_islands': '(托尔托拉岛)英属维尔京群岛',
    british_virgin_islands: '(托尔托拉岛)英属维尔京群岛',
    british_west_indies: '西印度群岛联邦',
    brunei: '文莱',
    bulgaria: '保加利亚',
    burkina_faso: '布基纳法索',
    burma: '缅甸',
    burundi: '布隆迪',
    cambodia: '柬埔寨',
    canada: '加拿大',
    cayman_islands: '凯曼群岛',
    central_african_republic: '中非共和国',
    '(z)_channel_islands': '海峡群岛',
    channel_islands: '海峡群岛',
    chile: '智利',
    '(z)_china': '中国',
    '*china_guangdong_province': '中国广东省',
    china: '中国',
    colombia: '哥伦比亚',
    congo: '刚果',
    cook_islands: '库克群岛',
    costa_rica: '哥斯达黎加',
    croatia: '克罗地亚',
    cuba: '古巴',
    curacao: '库拉索岛',
    cyprus: '塞浦路斯',
    czech_republic: '捷克',
    democratic_yemen: '也门',
    denmark: '丹麦',
    djibouti: '吉布提',
    dominican_republic: '多米尼加共和国',
    ecuador: '厄瓜多尔',
    egypt: '埃及',
    eire: '爱尔兰',
    el_salvador: '萨尔瓦多',
    equatorial_guinea: '赤道畿内亚',
    ethiopia: '埃塞俄比亚',
    fiji: '斐济',
    finland: '芬兰',
    '(z)_france': '法国',
    france: '法国',
    gabon: '加蓬',
    gambia: '冈比亚',
    german_democratic_republic: '德意志民主共和国',
    '(z)_germany': '德国',
    germany: '德国',
    ghana: '加纳',
    gibraltar: '直布罗陀',
    gilbert_islands: '吉尔伯特群岛',
    greece: '希腊',
    grenada: '格林纳达',
    guam: '关岛',
    guatemala: '危地马拉',
    guernsey: '根西岛',
    guinea: '几内亚',
    'guinea-bissau': '几内亚比绍',
    guyana: '圭亚那',
    haiti: '海地',
    honduras: '洪都拉斯',
    '(z)_hong_kong': '香港',
    'hk-special_delivery_(z1)': '香港',
    'hk-special_delivery_(z10)': '香港',
    'hk-special_delivery_(z11)': '香港',
    'hk-special_delivery_(z12)': '香港',
    'hk-special_delivery_(z13)': '香港',
    'hk-special_delivery_(z14)': '香港',
    'hk-special_delivery_(z15)': '香港',
    'hk-special_delivery_(z1b)': '香港',
    'hk-special_delivery_(z2)': '香港',
    'hk-special_delivery_(z3)': '香港',
    'hk-special_delivery_(z4)': '香港',
    'hk-special_delivery_(z5)': '香港',
    'hk-special_delivery_(z6)': '香港',
    'hk-special_delivery_(z7)': '香港',
    'hk-special_delivery_(z8)': '香港',
    'hk-special_delivery_(z9)': '香港',
    hong_kong: '香港',
    'hong_kong_(c)': '香港',
    hungary: '匈牙利',
    iceland: '冰岛',
    '(z)_india': '印度',
    india: '印度',
    indonesia: '印度尼西亚',
    iran: '伊朗伊斯兰共和国',
    iraq: '伊拉克',
    '(z)_ireland': '爱尔兰',
    ireland: '爱尔兰',
    'ireland_(irl)': '爱尔兰',
    '(z)_isle_of_man': '马恩岛',
    isle_of_man: '马恩岛',
    israel: '以色列',
    italy: '意大利',
    ivory_coast: '科特迪瓦',
    jamaica: '牙买加',
    japan: '日本',
    '(z)_jersey': '泽西',
    jersey: '泽西',
    '(z)_jordan': '约旦',
    jordan: '约旦',
    kenya: '肯尼亚',
    kingdom_of_cambodia: '柬埔寨',
    korea: '韩国',
    kowloon: '九龙',
    kuwait: '科威特',
    laos: '老挝',
    lebanon: '黎巴嫩',
    lesotho: '莱索托',
    liberia: '利比里亚',
    libya: '利比亚',
    liechtenstein: '列支敦士登',
    luxembourg: '卢森堡',
    macau: '澳门',
    madagascar: '马达加斯加',
    malawi: '马拉维',
    '(z)_malaysia': '马来西亚',
    malaysia: '马来西亚',
    maldives: '马尔代夫',
    mali: '马里',
    '(z)_malta': '马尔他',
    malta: '马尔他',
    marshall_islands: '马绍尔群岛',
    mauritania: '毛里塔尼亚',
    mauritius: '毛里求斯',
    mexico: '墨西哥',
    monaco: '摩洛哥',
    mongolia: '蒙古',
    montserrat: '蒙塞拉特岛',
    morocco: '摩洛哥',
    mozambigue: '莫桑比克',
    namibia: '纳米比亚',
    naura: '瑙鲁',
    nepal: '尼泊尔',
    netherlands: '荷兰',
    netherlands_antilles: '荷属安的列斯',
    '(z)_new_zealand': '新西兰',
    new_zealand: '新西兰',
    nicaragua: '尼加拉瓜',
    niger: '尼日尔',
    '(z)_nigeria': '尼日利亚',
    nigeria: '尼日利亚',
    niue: '纽埃',
    north_korea: '朝鲜',
    '(z)_northern_ireland': '北爱尔兰',
    northern_ireland: '北爱尔兰',
    norway: '挪威',
    oman: '阿曼',
    pakistan: '巴基斯坦',
    panama: '巴拿马',
    papua_new_guinea: '巴布亚新几内亚',
    paraguay: '巴拉圭',
    peru: '秘鲁',
    '(z)_philippines': '菲律宾',
    philippines: '菲律宾',
    poland: '波兰',
    '(z)_portugal': '葡萄牙',
    portugal: '葡萄牙',
    qatar: '卡塔尔',
    republic_of_south_africa: '南非',
    romania: '罗马尼亚',
    russia: '俄罗斯',
    rwanda: '卢旺达',
    samoa: '萨摩亚',
    sao_tome_and_principe: '圣多美和普林西比',
    saudi_arabia: '沙特阿拉伯',
    scotland: '苏格兰',
    senegal: '塞内加尔',
    '(z)_seychelles': '塞舌尔',
    seychelles: '塞舌尔',
    sierra_leone: '塞拉利昂',
    '(z)_singapore': '新加坡',
    singapore: '新加坡',
    slovakia: '斯洛伐克',
    solomon_islands: '所罗门群岛',
    somalia: '索马里',
    south_africa: '南非',
    '(z)_spain': '西班牙',
    spain: '西班牙',
    sri_lanka: '斯里兰卡',
    sudan: '苏丹',
    suriname: '苏里南',
    sweden: '瑞典',
    switzerland: '瑞士',
    'switzerland_(che)': '瑞士',
    syria: '叙利亚',
    taiwan: '台湾',
    thailand: '泰国',
    togo: '多哥 ',
    tonga: '汤加',
    trinidad_and_tobago: '特立尼达和多巴哥',
    tunisia: '突尼斯',
    '(z)_turkey': '土耳其',
    turkey: '土耳其',
    uganda: '乌干达',
    ukraine: '乌克兰',
    united_arab_emirates: '阿拉伯联合酋长国',
    '(z)_united_kingdom': '英国',
    united_kingdom: '英国',
    united_republic_of_cameroon: '喀麦隆',
    united_repulic_of_tanzania: '坦桑尼亚',
    '(z)_united_states_of_america': '美国',
    united_states_of_america: '美国',
    unknown_overseas_address: '未知的海外地址',
    uruguay: '乌拉圭',
    'usa-special_delivery': '美国特别送货',
    vanuatu: '瓦努阿图',
    vatican: '梵蒂冈',
    venezuela: '委內瑞拉 ',
    vietnam: '越南',
    west_africa: '西非',
    yemen: '也门',
    yugoslavia: '南斯拉夫',
    zaire: '扎伊尔',
    zambia: '赞比亚',
    zimbabwe: '津巴布韦',
  },
};
