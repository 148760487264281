/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Tabs,
  Tab,
  Container,
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import TabPanel, { a11yProps } from '../../../components/TabPanel';
import { CompanyInstrument } from '../../../models/CompanyInstrument';
import RomService from '../../../api/rom';
import { CompanyUser } from '../../../models/CompanyUser';
import { RootState } from '../../../store';
import { selectUserInfo } from '../../auth/duck/selector';
import HeaderGroup from '../../../components/HeaderGroup';
import { selectLang } from '../../settings/duck/selector';
import SelectCompanyDialog from '../registerOfMembers/SelectCompanyDialog';
import { ListedCompany } from '../../../models/ListedCompany';
import ScheduledReports from './ScheduledReports';
import OnDemand from './OnDemand';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
    },
    headerContainer: {
      backgroundColor: '#fff',
      padding: '0 16px',
    },
    contentContainer: {
      padding: '28px 32px',
    },
    company: {
      verticalAlign: 'middle',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
      marginBottom: 8,
    },
    indicator: {
      display: 'none',
    },
  }),
);

const TABS = [{ label: 'scheduled' }, { label: 'on_demand' }];

interface Props {
  user: CompanyUser | null;

  company?: ListedCompany;

  setCompany: (company: ListedCompany) => void;

  canSelectCompany: boolean;

  setCanSelectCompany: (enable: boolean) => void;
}

const Reports: React.FC<Props> = ({
  user,
  company,
  setCompany,
  canSelectCompany,
  setCanSelectCompany,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('reports');
  const [value, setValue] = React.useState(0);
  const [selected, setSelected] = React.useState<CompanyInstrument>();
  const lang = useSelector(selectLang);

  React.useEffect(() => {
    const tabNames = ['scheduled', 'on-demand'];
    ReactGA.pageview(`/reports/${tabNames[value]}`);
  }, [value]);

  React.useEffect(() => {
    if (!company && user) {
      RomService.getUserRom(user.id, 1, 1).then((res) => {
        setCompany(res.data.data[0]);
        setCanSelectCompany(res.data.totalPage > 1);
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (company?.instruments && company.instruments.length > 0) {
      setSelected(company.instruments[0]);
    }
  }, [company]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <span className={classes.company}>
            {lang === 'en'
              ? company?.cmpEngName
              : company?.cmpChiName || company?.cmpEngName}
            {selected?.insCode ? `(${selected?.insCode})` : ''}
          </span>
          {canSelectCompany && (
            <SelectCompanyDialog
              selectCompany={(item: ListedCompany) => {
                setCompany(item);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
            classes={{ indicator: classes.indicator }}
          >
            {TABS.map((item, index) => (
              <Tab
                {...a11yProps(index)}
                value={index}
                label={t(item.label)}
                disableFocusRipple
                disableTouchRipple
                disableRipple
                className={index === value ? 'filetab selected' : 'filetab'}
                key={item.label}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <TabPanel value={value} index={0}>
          <ScheduledReports
            company={company}
            companyType={company?.companyType}
            selectedInsturment={selected}
            onSelectInsturment={(data: CompanyInstrument) => setSelected(data)}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OnDemand
            company={company}
            selectedInsturment={selected}
            onSelectInsturment={(data: CompanyInstrument) => setSelected(data)}
          />
        </TabPanel>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

export default connect(mapStateToProps)(Reports);
