/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Drawer,
  Tooltip,
  useMediaQuery,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { drawerLogo } from '../assets';
import { Route } from '../models/Route';
import { Staff } from '../models/Staff';
import { CompanyUser } from '../models/CompanyUser';
import Feedback from './Feedback';
import { selectLang } from '../pages/settings/duck/selector';

const drawerWidth = 250;
const H_PADDING = 22;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    logo: {
      height: 88.9,
      width: 200,
      marginLeft: 4,
      marginTop: 20,
      marginBottom: 20,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      background: '#fff',
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7) + 1,
    },
    company: {
      display: 'flex',
      flexDirection: 'row',
      padding: `10px ${H_PADDING}px`,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      fontSize: '1.2rem',
      alignItems: 'center',
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
    },
    staffColumn: {
      flex: 1,
      flexDirection: 'column',
      marginLeft: 24,
    },
    staffAvatar: {
      height: 40,
      width: 40,
      borderRadius: 20,
    },
    staffName: {
      fontSize: '1.2rem',
      color: '#ffffff',
      fontWeight: 'normal',
      marginTop: 4,
    },
    staffRole: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#ffffff',
      textTransform: 'capitalize',
    },
    listItem: {
      padding: `12px ${H_PADDING}px`,
      '& span': { color: '#838383' },
      '& path': { fill: '#838383', opacity: 1 },
    },
    indicator: {
      position: 'absolute',
      left: 0,
      width: 4,
      height: '100%',
      backgroundColor: theme.palette.text.hint,
    },
    listItemSelected: {
      backgroundColor: 'transparent',
      '& span': { color: '#e84637' },
      '& path': { fill: '#e84637', opacity: 1 },
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: '#fff',
      },
      '& .MuiListItem-root.Mui-selected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    listItemIcon: {
      minWidth: 36,
      '& svg': { height: 18, width: 18 },
    },
    listItemTextRoot: {
      flex: 'unset',
      marginRight: 8,
    },
    listItemText: {
      color: theme.palette.text.secondary,
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.28,
    },
    chevronContainer: {
      justifyContent: 'flex-end',
    },
    chevronOpen: {
      minWidth: 24,
    },
    chevronClose: {
      minWidth: 54,
    },
    content: {
      flexGrow: 1,
      padding: '50px 64px 0 64px',
    },
    logout: {
      color: '#fff',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.28,
    },
    tooltip: {
      fontSize: '1.2rem',
    },
    sectionTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#fff',
      fontSize: '1.1rem',
      padding: '12px 40px 8px 40px',
    },
    hline: {
      flex: 1,
      backgroundColor: '#d9d9d9',
      height: 1,
      marginLeft: 8,
    },
    select: {
      margin: `0 ${H_PADDING}px`,
      '& :focus': {
        backgroundColor: '#fff',
        borderRadius: 4,
      },
      '& .MuiInput-underline:after': {
        display: 'none',
      },
    },
  }),
);

export interface DrawerSection {
  title?: string;
  data: Route[];
}

interface Props {
  routes: DrawerSection[];
  user?: CompanyUser | null;
  staff?: Staff | null;
  open: boolean;
  toggleDrawer: () => void;
}

const CustomDrawer: React.FC<Props> = ({
  user,
  routes,
  open,
  toggleDrawer,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('tabbar');
  const history = useHistory();
  const location = useLocation();
  const matched = useMediaQuery('(min-width:920px)');
  const lang = useSelector(selectLang);

  return (
    <Drawer
      variant={matched ? 'permanent' : 'temporary'}
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
      onClose={toggleDrawer}
    >
      <img className={classes.logo} src={drawerLogo} alt="tricor logo" />
      <List>
        {routes
          .map(({ title, data }, index) => {
            const sectionRoutes = data.reduce(
              (
                acc: JSX.Element[],
                { path, translateKey, icon: Icon, iconRight, iconRightOff },
              ) => {
                const label = t(translateKey);
                const selected =
                  path === '/'
                    ? location.pathname === '/'
                    : location.pathname.includes(path);
                return [
                  ...acc,
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={label}
                    placement="right"
                    open={open ? false : undefined}
                    key={`${translateKey}::${open ? 'on' : 'off'}`}
                  >
                    <ListItem
                      disableRipple
                      button
                      onClick={() => {
                        if (!matched) toggleDrawer();
                        history.push(path);
                      }}
                      selected={selected}
                      classes={{
                        root: classes.listItem,
                        selected: classes.listItemSelected,
                      }}
                    >
                      {location.pathname === path && (
                        <div className={classes.indicator} />
                      )}
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <Icon />
                      </ListItemIcon>
                      {!(path === '/board-portal' && lang === 'en') &&
                        !(translateKey === 'eagm' && lang === 'en') && (
                          <ListItemText
                            classes={{
                              root: classes.listItemTextRoot,
                              primary: classes.listItemText,
                            }}
                            primary={label}
                          />
                        )}
                      {iconRight && iconRightOff && (
                        <img
                          src={selected ? iconRight : iconRightOff}
                          alt="spot"
                          style={{ height: 13 }}
                        />
                      )}
                    </ListItem>
                  </Tooltip>,
                ];
              },
              title && open && index !== 0
                ? [
                    <div className={classes.sectionTitleContainer} key={title}>
                      <div className={classes.hline} />
                    </div>,
                  ]
                : [],
            );
            return sectionRoutes;
          })
          .flat()}
      </List>
      <div className="f1" />
      {user && <Feedback />}
      <ListItem
        disableRipple
        button
        onClick={toggleDrawer}
        className={clsx(classes.listItem, {
          [classes.chevronContainer]: open,
        })}
      >
        <ListItemIcon
          classes={{
            root: clsx(classes.listItemIcon, {
              [classes.chevronOpen]: open,
              [classes.chevronClose]: !open,
            }),
          }}
        >
          {open ? (
            <ChevronLeftIcon htmlColor="#fff" />
          ) : (
            <ChevronRightIcon htmlColor="#fff" />
          )}
        </ListItemIcon>
      </ListItem>
    </Drawer>
  );
};

export default CustomDrawer;
