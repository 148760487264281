const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = `${process.env.REACT_APP_OKTA_BASE_URI}/oauth2/default`;
const BASENAME = window.location.origin;
const REDIRECT_URI = `${BASENAME}/login/callback`;
const SCOPES = ['openid', 'profile', 'email', 'offline_access'];
// const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

export default {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: SCOPES,
    pkce: true,
    // disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
};