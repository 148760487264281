/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import snakeCase from 'lodash.snakecase';
import DownloadIcon from '@material-ui/icons/GetApp';
import { CompanyInstrument } from '../../../../models/CompanyInstrument';
import RomService from '../../../../api/rom';
import { selectLang } from '../../../settings/duck/selector';
import { saveAsFile } from '../../../../utils';
import { PieChart } from '../../../../components';
import RangeOfHoldingsReport from './RangeOfHoldingsReport';

interface Props {
  instrument?: CompanyInstrument;
}

interface AnalysisData {
  columns: string[];
  data: any[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      display: 'flex',
    },
    paper: {
      flex: 1,
      padding: theme.spacing(2),
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
      minHeight: 200,
      overflow: 'auto',
    },
    title: {
      color: '#262626',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      margin: '16px 0 16px 16px',
      alignSelf: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    header: {
      color: '#262626',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      borderTop: '1px solid #d9d9d9',
      borderBottomWidth: 0,
    },
    cell: {
      color: '#262626',
      fontSize: '1.4rem',
      borderBottomWidth: 0,
    },
    buttonDownload: {
      backgroundColor: '#e84637',
      '&:hover': {
        backgroundColor: '#e84637',
        opacity: 0.8,
      },
    },

    pieChartContainer: {
      borderTop: '1px solid #d9d9d9',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 400,
      justifyContent: 'center',
      alignItems: 'center',
    },
    lengedSection: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: '#262626',
    },
    lengendRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '4px 0',
    },
    legendIndicator: {
      display: 'inline-block',
      height: 20,
      width: 20,
      borderRadius: 10,
      marginRight: 4,
    },
    legendText: {
      fontSize: '1.2rem',
      color: '#262626',
      marginRight: 8,
    },
  }),
);

const TabMemberAnalysis: React.FC<Props> = ({ instrument }) => {
  const { t } = useTranslation('register_of_members');
  const classes = useStyles();
  const lang = useSelector(selectLang);
  const [data, setData] = React.useState<{ [category: string]: AnalysisData }>(
    {},
  );
  const [rangeOfHoldings, setRangeOfHoldings] = React.useState<any>();

  React.useEffect(() => {
    if (!instrument) return;
    RomService.getMemberAnalysis(instrument.insOid).then((res) => setData(res));
    RomService.getRangeOfHoldings(instrument.insOid).then((res) =>
      setRangeOfHoldings(res.data),
    );
  }, [instrument]);

  const onClickDownload = React.useCallback(
    (category: string) => {
      if (!instrument) return;

      RomService.getAnalysisReport(instrument.insOid, snakeCase(category), lang)
        .then((res) => {
          saveAsFile(
            new Blob([res.data]),
            `${snakeCase(category)}_${instrument.cmpEngName}.xlsx`,
          );
        })
        .catch((e) => {
          alert('Fail to download file');
        });
    },
    [instrument, lang],
  );

  const renderTable = (category: string) => {
    const title = `${t(snakeCase(category))}${
      category === 'overseasHoldersAnalysis' ? '' : `${t('analysis')}`
    }`;

    let md = 6;
    if (category === 'overseasHoldersAnalysis') md = 8;
    else if (category === 'meansOfCommunication') md = 4;
    else if (
      category === 'autopayInstruction' ||
      category === 'dividendElectionInstruction'
    )
      md = 12;

    return (
      <Grid
        item
        sm={12}
        md={md as any}
        className={classes.gridItem}
        style={{ marginBottom: category === 'autopayInstruction' ? 24 : 0 }}
      >
        {data[category] && (
          <Paper className={classes.paper}>
            <div className={classes.title}>
              {title}
              <IconButton
                aria-label="download report"
                className={classes.buttonDownload}
                onClick={() => onClickDownload(category)}
              >
                <DownloadIcon htmlColor="#fff" />
              </IconButton>
            </div>
            {category === 'meansOfCommunication' ? (
              <div>
                <div className={classes.pieChartContainer}>
                  <PieChart
                    type="doughnut"
                    displayLengend={false}
                    data={data[category]?.data?.map((item) => item[1])}
                    labels={data[category]?.data?.map((item) => t(item[0]))}
                    backgroundColor={data[category]?.data?.map(
                      (item) => item[2],
                    )}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      fontSize: '1.6rem',
                      textAlign: 'center',
                    }}
                  >
                    <div>{t('total')}</div>
                    <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>
                      {data[category].data
                        ?.reduce((acc, values) => acc + values[1], 0)
                        ?.toLocaleString()}
                    </div>
                  </div>
                </div>
                <div className={classes.lengedSection}>
                  {t('access_through_website')}
                </div>
                <div className={classes.lengendRow}>
                  {data[category].data.slice(2, 4).map((item) => (
                    <>
                      <div
                        className={classes.legendIndicator}
                        style={{
                          background: item[2],
                        }}
                      />
                      <span className={classes.legendText}>{t(item[0])}</span>
                    </>
                  ))}
                </div>
                <div className={classes.lengedSection}>
                  {t('access_through_physical_copy')}
                </div>
                <div className={classes.lengendRow}>
                  {data[category].data.slice(4).map((item) => (
                    <>
                      <div
                        className={classes.legendIndicator}
                        style={{
                          background: item[2],
                        }}
                      />
                      <span className={classes.legendText}>{t(item[0])}</span>
                    </>
                  ))}
                </div>
                <div className={classes.lengedSection}>
                  {t('access_through_others')}
                </div>
                <div className={classes.lengendRow}>
                  {data[category].data.slice(0, 2).map((item) => (
                    <>
                      <div
                        className={classes.legendIndicator}
                        style={{
                          background: item[2],
                        }}
                      />
                      <span className={classes.legendText}>{t(item[0])}</span>
                    </>
                  ))}
                </div>
              </div>
            ) : (
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    {data[category].columns.map((headerText, index) => (
                      <TableCell
                        key={`${headerText}::${index}`}
                        className={classes.header}
                        id={headerText}
                        align={index === 0 ? 'left' : 'right'}
                        style={{
                          borderRight:
                            index === 0 ? '1px solid #d9d9d9' : undefined,
                        }}
                      >
                        {t(headerText)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data[category].data.map((row: string[]) => (
                    <TableRow>
                      {row.map((item: any, index: number) => {
                        let value = '';
                        switch (typeof item) {
                          case 'string':
                            value = t(item);
                            break;
                          case 'number':
                            value = item.toLocaleString(undefined);
                            break;
                          case 'object':
                            value = item[lang];
                            break;
                          default:
                            value = '';
                        }

                        return (
                          <TableCell
                            className={classes.cell}
                            align={index === 0 ? 'left' : 'right'}
                            key={`${item}::${index}`}
                            style={{
                              borderRight:
                                index === 0 ? '1px solid #d9d9d9' : undefined,
                            }}
                          >
                            <div dangerouslySetInnerHTML={{ __html: value }} />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                  <TableRow>
                    {data[category].columns.map((_, index) => (
                      <TableCell
                        className={classes.cell}
                        align={index === 0 ? 'left' : 'right'}
                        style={{
                          borderRight:
                            index === 0 ? '1px solid #d9d9d9' : undefined,
                          fontWeight: 'bold',
                        }}
                      >
                        {index === 0
                          ? t('total')
                          : data[category].data
                              ?.reduce(
                                (acc, values) =>
                                  acc + parseFloat(values[index]),
                                0,
                              )
                              ?.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                              })}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Paper>
        )}
      </Grid>
    );
  };

  const onClickDownloadRangeOfHoldingsReport = () => {
    if (!instrument) return;

    RomService.getRangeOfHoldingsReport(instrument?.insOid, lang, [
      { from: 1, to: 10000 },
      { from: 10001, to: 100000 },
      { from: 100001, to: 1000000 },
      { from: 1000001, to: 10000000 },
      { from: 10000001, to: 100000000 },
      { from: 100000001, to: 1000000000 },
      { from: 1000000001, to: 10000000000 },
      { from: 10000000001, to: 100000000000 },
      { from: 100000000001, to: 1000000000000 },
      { from: 1000000000001 },
    ])
      .then((res) => {
        saveAsFile(new Blob([res.data]), 'range_of_holdings_insOid.xlsx');
      })
      .catch((e) => {
        alert('Fail to download file');
      });
  };

  const renderRangeOfHoldings = () => {
    if (!rangeOfHoldings) return null;

    return (
      <Grid item sm={12} md={6} className={classes.gridItem}>
        <Paper className={classes.paper}>
          <div className={classes.title}>
            {t('range_of_holdings_analysis')}
            <div style={{ flex: 1 }} />
            <RangeOfHoldingsReport insOid={instrument?.insOid} />
            <IconButton
              aria-label="download report"
              className={classes.buttonDownload}
              onClick={onClickDownloadRangeOfHoldingsReport}
            >
              <DownloadIcon htmlColor="#fff" />
            </IconButton>
          </div>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.header}
                  align="center"
                  style={{ borderRight: '1px solid #d9d9d9' }}
                  colSpan={4}
                >
                  {t('range_of_holdings')}
                </TableCell>
                <TableCell className={classes.header} align="right">
                  {t('number_of_registered_holders')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rangeOfHoldings!.ranges.map((item: any) => (
                <TableRow>
                  <TableCell className={classes.cell}>
                    {!item.to && t('above')}
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                    {(item.to ? item.from : item.from - 1).toLocaleString()}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {item.to && t('to')}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    align="right"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    {item.to?.toLocaleString()}
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                    {item.frequency.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  className={classes.cell}
                  colSpan={4}
                  align="right"
                  style={{
                    borderRight: '1px solid #d9d9d9',
                    fontWeight: 'bold',
                  }}
                >
                  {t('total')}
                </TableCell>
                <TableCell
                  className={classes.cell}
                  colSpan={4}
                  align="right"
                  style={{ fontWeight: 'bold' }}
                >
                  {rangeOfHoldings.total_frequency.toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    );
  };
  return (
    <div>
      <Grid container direction="row" alignItems="stretch" spacing={3}>
        {renderTable('overseasHoldersAnalysis')}
        {renderTable('meansOfCommunication')}
        {renderRangeOfHoldings()}
        <Grid
          container
          item
          sm={12}
          md={6}
          direction="column"
          justify="center"
          alignItems="stretch"
          style={{ flexWrap: 'nowrap' }}
        >
          {renderTable('autopayInstruction')}
          {renderTable('dividendElectionInstruction')}
        </Grid>
        <Grid item xs={12} sm={6} />
        {renderTable('currencyElection')}
      </Grid>
    </div>
  );
};

export default TabMemberAnalysis;
