import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { getApplicationAccessToken } from "./duck/authDuck";
import { OktaTokens } from "../../services/okta/interface";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store";
import Error from "./Error";
import LoginContainer from "./LoginContainer";
import { CircularProgress } from "@material-ui/core";

let handledRedirect = false;

const OktaLoginCallback: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const [callbackError, setCallbackError] = React.useState(null);
    const dispatch = useAppDispatch();
    const history = useHistory();

    React.useEffect(() => {
        // OKTA-635977: Prevents multiple calls of handleLoginRedirect() in React18 StrictMode
        // Top-level variable solution follows: https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
        if (!handledRedirect) {
            oktaAuth.token.parseFromUrl()
                .then((response) => {
                    var tokens = response.tokens;
                    oktaAuth.tokenManager.setTokens(tokens);
                    const oktaTokens: OktaTokens = {
                        accessToken: tokens.accessToken?.accessToken,
                        idToken: tokens.idToken?.idToken,
                        refreshToken: tokens.refreshToken?.refreshToken,
                        expiresIn: tokens.accessToken?.expiresAt,
                        scope: tokens.accessToken?.scopes.join(' '),
                    };
                    dispatch(getApplicationAccessToken(oktaTokens))
                        .then(() => {
                            history.replace('/');
                        })
                })
                .catch(e => {
                    setCallbackError(e);
                })

            /* oktaAuth.handleLoginRedirect()
                .then(() => {
                    if (authState?.accessToken?.accessToken && authState.idToken?.idToken && authState.refreshToken?.refreshToken) {
                        const oktaTokens: OktaTokens = {
                            accessToken: authState.accessToken?.accessToken,
                            idToken: authState.idToken?.idToken,
                            refreshToken: authState.refreshToken?.refreshToken,
                            expiresIn: authState.accessToken?.expiresAt,
                            scope: authState.accessToken?.scopes.join(' '),
                        };
                        dispatch(getApplicationAccessToken(oktaTokens))
                            .catch((error) => {
                                alert("Unable to login, please contact technical assistance.");
                            })
                            .finally(() => {
                                // history.replace('/');
                            });

                    }
                })
                .catch(e => {
                    setCallbackError(e);
                }) */

            handledRedirect = true;
        }
    }, [oktaAuth]);

    const authError = authState?.error;
    const displayError = callbackError || authError;
    if (displayError) {
        return <Error error={displayError} />;
    }

    return (
        <LoginContainer>
            <CircularProgress />
        </LoginContainer>
    );
};

export default OktaLoginCallback;