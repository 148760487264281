/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button } from '@material-ui/core';
import { Column } from 'react-table';
import { useParams } from 'react-router-dom';
import SelectColumnFilter, {
  ColumnProps,
} from '../../../../components/SelectCloumnFilter';
import Entity from '../../../../models/Entity';
import PaginationTable from '../../../../components/PaginationTable';
import AdminService from '../../../../api/admin';
import { EntityResponse } from '../../../../models/EntityResponse';
import { UserReducerContext } from './duck';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    paddingBottom: 32,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
  },
}));

const COLUMNS: Array<Column<Entity>> = [
  {
    Header: 'manage_company:file_code',
    accessor: 'fileCode',
    disableFilters: true,
    Cell: ({ value, row: { original } }) => {
      if (original.applicationStatus === 'pendingUnassign')
        return (
          <div style={{ textDecorationLine: 'line-through' }}>{value}</div>
        );
      return value || '-';
    },
  },
  {
    Header: 'manage_company:name',
    accessor: 'name',
    width: 300,
    disableFilters: true,
    Cell: ({ value, row: { original } }) => {
      if (original.applicationStatus === 'pendingUnassign')
        return (
          <div style={{ textDecorationLine: 'line-through' }}>{value}</div>
        );
      return value || '-';
    },
  },
  {
    Header: 'manage_company:status',
    accessor: 'status',
    filter: 'includes',
    Filter: (props: ColumnProps) => (
      <SelectColumnFilter
        {...props}
        customOptions={[
          { baseString: 'Active', en: 'Active', zh: 'Active', cn: 'Active' },
          {
            baseString: 'Pending',
            en: 'Pending',
            zh: 'Pending',
            cn: 'Pending',
          },
          {
            baseString: 'Closing',
            en: 'Closing',
            zh: 'Closing',
            cn: 'Closing',
          },
          { baseString: 'Closed', en: 'Closed', zh: 'Closed', cn: 'Closed' },
          {
            baseString: 'Marked for Deletion',
            en: 'Marked for Deletion',
            zh: 'Marked for Deletion',
            cn: 'Marked for Deletion',
          },
        ]}
      />
    ),
    Cell: ({ value, row: { original } }) => {
      if (original.applicationStatus === 'pendingUnassign')
        return (
          <div style={{ textDecorationLine: 'line-through' }}>{value}</div>
        );
      return value || '-';
    },
  },
  {
    Header: 'entity_management:date_of_incorporation',
    accessor: 'incorpDateString',
    disableFilters: true,
    Cell: ({ value, row: { original } }) => {
      if (original.applicationStatus === 'pendingUnassign')
        return (
          <div style={{ textDecorationLine: 'line-through' }}>{value}</div>
        );
      return value || '-';
    },
  },

  {
    Header: 'manage_applications:approval_status',
    accessor: 'applicationStatus',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => {
      if (value === 'pendingAssign' || value === 'pendingUnassign')
        return 'Pending';
      return 'Assigned';
    },
  },
];

const TabEntityManagement: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    state: { user },
    dispatch,
  } = React.useContext(UserReducerContext);
  const { id } = useParams();
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getApplicationEntities(
        id,
        pageSize,
        pageIndex,
        query,
        sortBy?.id === 'incorpDateString'
          ? { ...sortBy, id: 'incorporateDate' }
          : sortBy,
        filters?.[0]?.id,
        filters?.[0]?.value,
      ).then(({ data: { data, totalPage } }) => {
        if (fetchId === fetchIdRef.current) {
          setEntities(
            data.map((item: EntityResponse) => {
              let status = 'assigned';
              if (user?.pendingAssignedEmCodes?.includes(item.fileCode)) {
                status = 'pendingAssign';
              } else if (
                user?.pendingUnassignedEmCodes?.includes(item.fileCode)
              ) {
                status = 'pendingUnassign';
              }

              return new Entity({ ...item, applicationStatus: status });
            }),
          );
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [user?.emCodes],
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={15}
        columns={COLUMNS}
        data={entities}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name, relationship manager"
        placeholder={t('manage_user:entity_management_placeholder')}
      />
    </div>
  );
};

export default TabEntityManagement;
