/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import GpsNotFixedRoundedIcon from '@material-ui/icons/GpsNotFixedRounded';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles, IconButton } from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Entity from '../../../models/Entity';
import PaginationTable from '../../../components/PaginationTable';
import AdminService from '../../../api/admin';
import { selectIsClientChecker } from '../../auth/duck/selector';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '& tr': {
      cursor: 'pointer',
    },
  },
  funcionAccess: {
    height: 50,
    width: '100%',
    background: 'white',
    marginBottom: 5,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 'bold',
    color: '#262626',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SelectCompany: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [entities, setEntities] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const isClientChecker = useSelector(selectIsClientChecker);

  const COLUMNS = React.useMemo<Array<Column<any>>>(() => {
    if (isClientChecker) {
      return [
        {
          Header: 'manage_user:rom_stock_codes',
          accessor: 'insCode',
          disableFilters: true,
        },
        {
          Header: 'manage_user:hk_ticker',
          accessor: 'tickerNo',
          disableFilters: true,
        },
        {
          Header: 'entity_management:company_name',
          accessor: 'companyName',
          disableFilters: true,
        },
      ];
    }

    return [
      {
        Header: 'manage_user:rom_stock_codes',
        accessor: 'insCode',
        disableFilters: true,
        maxWidth: 200,
      },
      {
        Header: 'manage_user:hk_ticker',
        accessor: 'tickerNo',
        disableFilters: true,
        maxWidth: 200,
      },
      {
        Header: 'entity_management:company_name',
        accessor: 'companyName',
        disableFilters: true,
        minWidth: 600,
      },
      {
        Header: 'tabbar:entity_management',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        accessor: 'entityManagement',
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:register_of_members',
        maxWidth: 100,
        accessor: 'registerOfMember',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:ccass_holding',
        accessor: 'ccassHolding',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:reports',
        accessor: 'reports',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:shareholder_analytics',
        accessor: 'ownershipAnalysis',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:market_intelligence',
        accessor: 'marketIntelligence',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:industry_analyst_report',
        accessor: 'industryAnalysis',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:eagm',
        accessor: 'eAGM',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:board_portal',
        accessor: 'boardPortal',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },

      {
        Header: 'tabbar:invoice_management',
        accessor: 'invoiceManagement',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:thought_leadership',
        accessor: 'thoughtLeadership',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'manage_company_access:maker_checker_function',
        accessor: 'makerCheckerFunc',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'manage_company_access:acc_number_limitation',
        accessor: 'acctNoLimitation',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
      },
    ];
  }, []);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getCompanyAccessList(
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setEntities(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const onClickRow = (item: any) => {
    if (item.id) {
      history.push(`/admin/company_access/${item.id}`);
    } else if (item.tickerNo) {
      history.push(
        `/admin/company_access/${item.id}?tickerNo=${item.tickerNo}`,
      );
    } else {
      history.push(`/admin/company_access/${item.id}?romOid=${item.romOid}`);
    }
  };

  const renderActions = (item: any) => (
    <div className="table-row-actions">
      <IconButton
        aria-label="edit"
        onClick={() => {
          if (item.id) {
            history.push(`/admin/company_access/${item.id}`);
          } else if (item.tickerNo) {
            history.push(
              `/admin/company_access/${item.id}?tickerNo=${item.tickerNo}`,
            );
          } else {
            history.push(
              `/admin/company_access/${item.id}?romOid=${item.romOid}`,
            );
          }
        }}
      >
        <EditRoundedIcon htmlColor="#a5a5a5" />
      </IconButton>
    </div>
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={20}
        columns={COLUMNS}
        data={entities}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name, relationship manager"
        placeholder={t('manage_company:select_company_placeholder')}
        onClickRow={onClickRow}
        renderActions={renderActions}
        renderHeaderLeft={
          <>
            <GpsNotFixedRoundedIcon className="section-icon" />
            <span className="section-title">
              {t('manage_company:select_company')}
            </span>
          </>
        }
      />
    </div>
  );
};

export default SelectCompany;
