import React from 'react';
import {
  IconButton,
  makeStyles,
  Theme,
  ClickAwayListener,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import LanguageIcon from '@material-ui/icons/Language';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import { useTranslation } from 'react-i18next';
import RmContact from './RmContact';
import { AppDispatch, useAppDispatch } from '../store';
import { logout as ActionLogout } from '../pages/auth/duck/authDuck';
import { selectLang } from '../pages/settings/duck/selector';
import { changeLang } from '../pages/settings/duck';
import { info } from '../assets';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
  },
  buttonLang: {
    marginLeft: 6,
    marginRight: 6,
  },
  buttonInfo: {
    marginLeft: 12,
    marginRight: 6,
  },
  buttonLogout: {
    marginLeft: 6,
    marginRight: 12,
  },
  arrow: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#b4b4b4',
    padding: 0,
  },
  langSelected: {
    color: '#e84637',
  },
  langList: {
    padding: 0,
  },
}));

interface Props {
  isAdmin?: boolean;
  logout: () => void;
}

const HeaderGroup = ({ isAdmin = false, logout }: Props) => {
  const classes = useStyles();
  const lang = useSelector(selectLang);
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);

  const onClickTooltip = () => {
    setOpen((state) => !state);
  };

  const onClickInfo = () => {
    const link = document.createElement('a');
    link.href =
      'https://support.tricorglobal.com/en/support/solutions/150000214588';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.container}>
      {!isAdmin && (
        <>
          <RmContact />
          <IconButton
            color="primary"
            aria-label="information"
            component="span"
            className={classes.buttonInfo}
            onClick={onClickInfo}
          >
            <img src={info} alt="information" />
          </IconButton>
        </>
      )}
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <Tooltip
            interactive
            placement="bottom-start"
            classes={{ tooltip: classes.tooltip }}
            PopperProps={{ disablePortal: true }}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <List className={classes.langList}>
                {[
                  { key: 'en', label: 'English' },
                  { key: 'zh', label: '繁體中文' },
                  { key: 'cn', label: '简体中文' },
                ].map((item) => (
                  <ListItem
                    key={item.key}
                    button
                    onClick={() => {
                      i18n.changeLanguage(item.key);
                      dispatch(changeLang(item.key as any));
                      setOpen(false);
                    }}
                  >
                    <ListItemText
                      primary={item.label}
                      className={
                        lang === item.key ? classes.langSelected : undefined
                      }
                    />
                  </ListItem>
                ))}
              </List>
            }
          >
            <IconButton
              color="primary"
              aria-label="language"
              component="span"
              className={classes.buttonLang}
              onClick={onClickTooltip}
            >
              <LanguageIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>

      <IconButton
        color="primary"
        aria-label="logout"
        component="span"
        className={classes.buttonLogout}
        onClick={logout}
      >
        <PowerSettingsNewRoundedIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: () => dispatch(ActionLogout()),
});

export default connect(null, mapDispatchToProps)(HeaderGroup);
