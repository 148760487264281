import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  makeStyles,
  Theme,
  Button,
  Typography,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import AdminService from '../../../api/admin';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRemove: {
    backgroundColor: '#fd101b',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelRemove: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
  buttonReject: {
    backgroundColor: '#fff',
    color: '#fd101b',
    borderColor: '#fd101b',
    fontWeight: 600,
    textTransform: 'none',
    marginLeft: 12,
  },
  input: {
    width: '100%',
    minWidth: 300,
  },
  notes: {
    fontSize: 12,
    lineHeight: 1.17,
    color: '#838383',
    marginBottom: 7,
  },
}));

interface Props {
  disabled?: boolean;
  onRejected: () => void;

  applicationIds?: number[];

  showNote?: boolean;

  onRejectError: (e: any) => void;
}

const RejectCompanyDialog: React.FC<Props> = ({
  disabled,
  onRejected,
  onRejectError,
  applicationIds,
  showNote,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_applications');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reason, setReason] = React.useState('');

  const handleClickOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReason('');
  };

  const handleClickReject = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (!applicationIds || applicationIds.length === 0) {
      return;
    }

    setLoading(true);

    AdminService.rejectCompanyApplications(applicationIds, reason)
      .then(() => {
        onRejected();
      })
      .catch((e) => {
        setLoading(false);
        onRejectError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        disabled={loading || disabled}
        variant="outlined"
        className={classes.buttonReject}
        onClick={handleClickOpen}
      >
        {t('manage_applications:button_reject')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="reject-application-dialog-title"
        open={open}
      >
        <DialogTitle id="reject-application-dialog-title" onClose={handleClose}>
          {t('reject_reason')}
        </DialogTitle>
        <DialogContent>
          {showNote && (
            <Typography className={classes.notes}>
              {t('reject_note')}
            </Typography>
          )}
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={8}
            className={classes.input}
            value={reason}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setReason(e.target.value);
            }}
          />
        </DialogContent>
        <div className={classes.bottomContainer}>
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRemove,
              label: classes.labelRemove,
            }}
            onClick={handleClickReject}
            disabled={loading}
          >
            {t('common:submit')}
          </Button>
          <Button
            variant="outlined"
            classes={{ label: classes.labelCancel }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
            }}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default RejectCompanyDialog;
