/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button, Dialog } from '@material-ui/core';
import { Column } from 'react-table';
import GroupIcon from '@material-ui/icons/GroupRounded';
import { useSelector } from 'react-redux';
import PaginationTable from '../../../../components/PaginationTable';
import AdminService from '../../../../api/admin';
import { UserReducerContext } from '../duck';
import { Staff } from '../../../../models/Staff';
import { selectIsChecker } from '../../../auth/duck/selector';
import { DialogContent, DialogTitle } from '../../../../components/Dialog';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

const TabClientChecker: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    state: { user },
    dispatch,
  } = React.useContext(UserReducerContext);
  const [data, setData] = React.useState<Staff[]>([]);
  const [assignedCheckers, setAssignedChecker] = React.useState<Staff[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const isChecker = useSelector(selectIsChecker);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  const COLUMNS: Array<Column<Staff>> = React.useMemo(
    () => [
      {
        Header: 'manage_user:name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'manage_user:email',
        accessor: 'username',
        disableFilters: true,
      },
      {
        Header: 'manage_user:status',
        accessor: 'status',
        disableFilters: true,
        Cell: ({ value }) =>
          value ? t(`manage_user:${value.toLowerCase()}`) : '-',
      },
      {
        Header: 'manage_user:user_type',
        accessor: 'role',
        disableFilters: true,
        Cell: ({ value }) =>
          value === 'TricorSuperAdmin'
            ? 'Tricor Super Admin'
            : value === 'TricorMaker'
            ? 'Tricor Maker'
            : value === 'TricorChecker'
            ? 'Tricor Checker'
            : '-',
      },
    ],
    [t],
  );

  // React.useEffect(() => {
  //   if (user?.id) {
  //     AdminService.getUserAssignedChecker(user.id).then((res) => {
  //       setAssignedChecker((res as any).data);
  //     });
  //   }
  // }, [user?.id]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      if (!user?.id) return;
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getUserAssignedChecker(
        user?.id,
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [user?.romOids, user?.id],
  );

  const getUserAssignedChecker = React.useCallback(() => {
    if (user) {
      AdminService.getUserAssignedChecker(user.id).then((res) => {
        setAssignedChecker(res.data);
      });
    }
  }, [user?.id]);

  const renderActions = (item: Staff) => {
    const assigned =
      assignedCheckers.findIndex((checker) => checker.id === item.id) > -1;

    return (
      <div className="table-row-actions">
        <Button
          disabled={isChecker}
          aria-label="assign"
          variant={isChecker ? 'text' : 'contained'}
          className={
            assigned && !isChecker ? classes.buttonOn : classes.buttonOff
          }
          onClick={() => {
            if (!user) return;
            setLoading(true);
            if (assigned) {
              AdminService.unassignClientChecker(user.id, item.id)
                .then(() => getUserAssignedChecker())
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              AdminService.assignClientChecker(user.id, item.id)
                .then(() => getUserAssignedChecker())
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          }}
        >
          {assigned ? 'Unassign' : 'Assign'}
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <PaginationTable
        key={assignedCheckers.length}
        defaultPageSize={15}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name"
        placeholder={t('manage_user:rom_placeholder')}
        // renderActions={renderActions}
        renderHeaderLeft={
          <>
            <GroupIcon className="section-icon" />
            <span className="section-title">
              {t('manage_user:tricor_checker')}
            </span>
          </>
        }
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TabClientChecker;
