import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Card,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import {
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSaturday,
  isSunday,
  isValid,
  startOfMonth,
  sub,
} from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import LearnMoreDialog from '../../../components/LearnMoreDialog';
import HeaderGroup from '../../../components/HeaderGroup';
import { ListedCompany } from '../../../models/ListedCompany';
import SelectCompanyDialog from '../registerOfMembers/SelectCompanyDialog';
import { selectUserInfo } from '../../auth/duck/selector';
import RomService from '../../../api/rom';
import { selectLang } from '../../settings/duck/selector';
import { CompanyInstrument } from '../../../models/CompanyInstrument';
import 'chartjs-plugin-datalabels';
import { HorizontalBar, PieChart } from '../../../components';
import CcassService from '../../../api/ccass';
import CalendarService from '../../../api/calendar';
import PaginationTable from '../../../components/PaginationTable';
import ChartSouthbound from './ChartSouthbound';
import HolderDetailModal from './HolderDetailModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
      '& .react-table .tr': {
        padding: 0,
        boxShadow: 'unset',
        margin: '0 0 4px',
      },
      '& canvas': {
        width: '100% !important',
        height: 'auto',
      },
    },
    headerContainer: {
      backgroundColor: '#fff',
      padding: '0 16px',
    },
    contentContainer: {
      padding: '28px 32px',
    },
    company: {
      verticalAlign: 'middle',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
      marginBottom: 8,
    },
    separator: {
      border: 'solid 1px #d9d9d9',
    },
    card: {
      padding: 20,
    },
    dateLabel: {
      display: 'inline-flex',
      fontSize: 14,
      color: '#262626',
      margin: '6px 8px 0 0 ',
    },
    dateInput: {
      backgroundColor: '#fff',
      paddingTop: 4,
      '& input': {
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '6px !important',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
      '& legend': {
        display: 'none',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(0px, -4px) scale(0.75)',
      },
      '& .Mui-error': {
        fontSize: 10,
      },
    },
    dateInput2: {
      paddingTop: 4,
      '& input': {
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '6px !important',
        zIndex: 99,
        backgroundColor: 'transparent',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
      '& legend': {
        display: 'none',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(0px, -4px) scale(0.75)',
      },
      '& .Mui-error': {
        fontSize: 10,
      },
      '& .MuiInputAdornment-positionEnd': {
        zIndex: 99,
      },
      '& fieldset': {
        zIndex: 1,
        backgroundColor: '#fff',
      },
    },
    menuItem: {
      backgroundColor: '#838383 !important',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#838383 !important',
      },
    },
    selected: {
      backgroundColor: '#838383',
      fontSize: 14,
      fontWeight: 'bold',
      color: 'red',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buttonConfirmDate: {
      height: 25,
      borderRadius: 24,
      minWidth: 0,
      marginLeft: 4,
      backgroundColor: '#e84637',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      padding: '0 16px',
      color: '#fff',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#fd101b',
        opacity: 0.8,
      },
    },
    dateTo: {
      fontSize: '1.4rem',
      color: '#262626',
      margin: '8px',
      display: 'inline-block',
    },
    dateError: {
      maxWidth: 170,
      fontSize: 10,
      color: '#f44336',
    },
    placeholder: {
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  company?: ListedCompany;

  setCompany: (company: ListedCompany) => void;

  canSelectCompany: boolean;

  setCanSelectCompany: (enable: boolean) => void;
}

interface StockQuote extends CompanyInstrument {
  regularMarketChange: number;
  regularMarketChangePercent: number;
  regularMarketPrice: number;
  symbol: string;
  timestamp: string;
}

const CcassHolding: React.FC<Props> = ({
  company,
  setCompany,
  canSelectCompany,
  setCanSelectCompany,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('ccass_holding');
  const lang = useSelector(selectLang);
  const user = useSelector(selectUserInfo);
  const [targetDate, setTargetDate] = React.useState<Date>(
    sub(new Date(), { days: 1 }),
  );
  const [topNHolders, setTopNHolders] = React.useState<any[]>([]);
  const [holdingsByType, setHoldingsByType] = React.useState<any[]>([]);
  const [southboundHoldings, setSouthboundHoldings] = React.useState<any[]>([]);
  const [dailyTopChange, setDailyTopChange] = React.useState<any[]>([]);
  const [dailyChangeHolder, setDailyChangeHolder] = React.useState<any>();
  const [topHoldersThreshold, setTopHoldersThreshold] = React.useState([]);
  const [thresholdDailyHoldings, setThresholdDailyHoldings] = React.useState<
    number
  >();
  const [thresholdHolders, setThresholdHolders] = React.useState<number>();
  const [expanded, setExpanded] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date>(
    sub(new Date(), { days: 61 }),
  );
  const [endDate, setEndDate] = React.useState<Date>(
    sub(new Date(), { days: 1 }),
  );
  const [unit, setUnit] = React.useState<'day' | 'month'>('day');
  const [dateRangeError, setDateRangeError] = React.useState(false);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [holiday, setHoliday] = React.useState<any[]>([]);
  const instrument = company?.instruments?.[0];

  React.useEffect(() => {
    if (company) {
      CalendarService.getEvents(company.cmpOid, undefined, undefined, {
        startTime: '2020-01-01',
      }).then((res) => {
        setHoliday(res.data.filter((item) => item.isPublicHoliday));
      });
    }
  }, [company]);

  React.useEffect(() => {
    CcassService.getTopNHoldersThreshold().then((res) => {
      if (res.length > 0) {
        setTopHoldersThreshold(res);
        setThresholdDailyHoldings(res[0]);
        setThresholdHolders(res[0]);
      }
    });
  }, []);

  React.useEffect(() => {
    if (!company && user) {
      RomService.getUserRom(user.id, 1, 1).then((res) => {
        setCompany(res.data.data[0]);
        setCanSelectCompany(res.data.totalPage > 1);
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (
      isBefore(targetDate, sub(new Date(), { days: 365 })) ||
      isAfter(targetDate, sub(new Date(), { days: 1 })) ||
      isSunday(targetDate) ||
      isSaturday(targetDate) ||
      holiday.length === 0 ||
      holiday.some((item) => isSameDay(new Date(item.startTime), targetDate))
    ) {
      setHoldingsByType([]);
    } else if (company?.instruments?.[0]) {
      CcassService.getShareHoldingByHolders(
        company?.instruments?.[0].insOid,
        company?.instruments?.[0].insCode,
        company?.companyType,
        format(targetDate, 'yyyy-MM-dd'),
      ).then((res) => {
        if (res && res.data) setHoldingsByType(res.data);
      });
    }
  }, [company, targetDate, holiday]);

  React.useEffect(() => {
    if (
      isBefore(targetDate, sub(new Date(), { days: 365 })) ||
      isAfter(targetDate, sub(new Date(), { days: 1 })) ||
      isSunday(targetDate) ||
      isSaturday(targetDate) ||
      holiday.length === 0 ||
      holiday.some((item) => isSameDay(new Date(item.startTime), targetDate))
    ) {
      setTopNHolders([]);
    } else if (company?.instruments?.[0] && thresholdHolders) {
      CcassService.getTopNHolders(
        company?.instruments?.[0].insOid,
        company?.instruments?.[0].insCode,
        company?.companyType,
        format(targetDate, 'yyyy-MM-dd'),
        thresholdHolders,
      ).then((res) => {
        if (res && res.data) setTopNHolders(res.data);
      });
    }
  }, [company, thresholdHolders, targetDate, holiday]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      if (
        isBefore(targetDate, sub(new Date(), { days: 365 })) ||
        isAfter(targetDate, sub(new Date(), { days: 1 })) ||
        isSunday(targetDate) ||
        isSaturday(targetDate) ||
        holiday.length === 0 ||
        holiday.some((item) => isSameDay(new Date(item.startTime), targetDate))
      ) {
        setDailyTopChange([]);
      } else if (company?.instruments?.[0] && thresholdDailyHoldings) {
        const fetchId = ++fetchIdRef.current;

        CcassService.getTopNDailyChange(
          company?.instruments?.[0].insOid,
          company?.instruments?.[0].insCode,
          company?.companyType,
          format(targetDate, 'yyyy-MM-dd'),
          thresholdDailyHoldings,
        ).then((res) => {
          if (fetchId === fetchIdRef.current) {
            setDailyTopChange(res.slice((pageIndex - 1) * 10, pageIndex * 10));
          }
        });
      }
    },
    [company, thresholdDailyHoldings, targetDate, holiday],
  );

  React.useEffect(() => {
    if (company?.instruments?.[0]) {
      setLoading(true);
      CcassService.getSouthboundHolding(
        company?.instruments?.[0].insOid,
        company?.instruments?.[0].insCode,
        company?.companyType,
        format(startDate, 'yyyy-MM-dd'),
        format(endDate, 'yyyy-MM-dd'),
      )
        .then((res) => {
          if (res && res.data) {
            setUnit(
              differenceInDays(endDate, startDate) > 60 ? 'month' : 'day',
            );
            setSouthboundHoldings(
              differenceInDays(endDate, startDate) > 60
                ? res.data.reduce((acc: any, item: any) => {
                    if (acc.length === 0) {
                      return [
                        { ...item, date: startOfMonth(new Date(item.date)) },
                      ];
                    }

                    if (
                      isSameMonth(
                        new Date(acc[acc.length - 1].date),
                        new Date(item.date),
                      )
                    ) {
                      const lastItem = acc[acc.length - 1];
                      return [
                        ...acc.slice(0, acc.length - 1),
                        {
                          ...lastItem,
                          shareholdings:
                            lastItem.shareholdings + item.shareholdings,
                          percentage: lastItem.percentage + item.percentage,
                        },
                      ];
                    }

                    return [
                      ...acc,
                      { ...item, date: startOfMonth(new Date(item.date)) },
                    ];
                  }, [])
                : res.data,
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company]);

  React.useEffect(() => {
    // if (differenceInDays(endDate, startDate) > 365) {
    //   setDateRangeError(true);
    //   return;
    // }

    if (company?.instruments?.[0]) {
      setLoading(true);
      setUnit(differenceInDays(endDate, startDate) > 60 ? 'month' : 'day');
      CcassService.getSouthboundHolding(
        company?.instruments?.[0].insOid,
        company?.instruments?.[0].insCode,
        company?.companyType,
        format(startDate, 'yyyy-MM-dd'),
        format(endDate, 'yyyy-MM-dd'),
      )
        .then((res) => {
          if (res && res.data) {
            setSouthboundHoldings(res.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company, startDate, endDate]);

  const columns = React.useMemo<Array<Column<any>>>(() => {
    return [
      {
        Header: 'ccass_holding:participant_name',
        accessor: 'participantName',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ccass_holding:last_holding_date',
        accessor: 'lastHoldingDate',
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 120,
        Cell: ({ value }) =>
          value ? format(new Date(value), 'dd/MM/yyyy') : '-',
      },
      {
        Header: (
          <div style={{ flex: 1, textAlign: 'end' }}>
            {t('ccass_holding:shareholding')}
          </div>
        ),
        maxWidth: 150,
        accessor: 'shareholding',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>
            {value !== undefined ? value.toLocaleString() : '-'}
          </div>
        ),
      },
      {
        Header: (
          <div style={{ flex: 1, textAlign: 'end' }}>
            {t('ccass_holding:percent_holding')}
          </div>
        ),
        maxWidth: 100,
        accessor: 'holdingPercentage',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>
            {value !== undefined
              ? `${value.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}%`
              : '-'}
          </div>
        ),
      },
      {
        Header: (
          <div style={{ flex: 1, textAlign: 'end' }}>
            {t('ccass_holding:percent_holdings_change')}
          </div>
        ),
        maxWidth: 100,
        accessor: 'changeHoldingPercentage',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>
            {value !== undefined
              ? `${value.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}%`
              : '-'}
          </div>
        ),
      },
    ];
  }, [t]);
  console.log(
    targetDate,
    isSunday(targetDate),
    isSaturday(targetDate),
    holiday.some((item) => isSameDay(new Date(item.startTime), targetDate)),
  );

  return (
    <div className={classes.root}>
      <LearnMoreDialog
        open={dateRangeError}
        handleClose={() => {
          setDateRangeError(false);
        }}
        title="Warning"
        message="Please select your date range within this year (2021)."
      />
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <span className={classes.company}>
            {lang === 'en'
              ? company?.cmpEngName
              : company?.cmpChiName || company?.cmpEngName}
            {instrument?.insCode ? `(${instrument?.insCode})` : ''}
          </span>
          {canSelectCompany && (
            <SelectCompanyDialog
              selectCompany={(item: ListedCompany) => {
                setCompany(item);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <Grid container spacing={3} style={{ marginTop: 0 }}>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography className={classes.dateLabel}>
              {t('select_last_holding_date')}
            </Typography>
            <div>
              <KeyboardDatePicker
                autoOk
                disableFuture
                variant="inline"
                format="dd/MM/yyyy"
                value={targetDate}
                minDate={sub(new Date(), { days: 365 })}
                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                shouldDisableDate={(day) => {
                  if (!day) return false;

                  if (day.getDay() === 6 || day.getDay() === 0) {
                    return true;
                  }

                  return holiday.some((item) =>
                    isSameDay(new Date(item.startTime), day.getTime()),
                  );
                }}
                InputAdornmentProps={{ position: 'end' }}
                inputVariant="outlined"
                invalidDateMessage={t('common:please_enter_valid_date')}
                minDateMessage={t('common:date_must_within_a_year')}
                maxDateMessage={t('common:date_cannot_be_future')}
                onChange={(d, value) => {
                  if (!d || !value || value.includes('_')) return;
                  if (!isValid(d)) {
                    alert(t('common:please_enter_valid_date'));
                    return;
                  }

                  setTargetDate(d);
                }}
                // @ts-ignore
                classes={{ root: classes.dateInput2 }}
              />

              {isSunday(targetDate) ||
              isSaturday(targetDate) ||
              holiday.some((item) =>
                isSameDay(new Date(item.startTime), targetDate),
              ) ? (
                <Typography className={classes.dateError} component="div">
                  {t('date_is_holiday')}
                </Typography>
              ) : !loading &&
                !(
                  dailyTopChange?.[0] &&
                  isSameDay(
                    new Date(dailyTopChange?.[0].lastHoldingDate),
                    targetDate,
                  )
                ) ? (
                <Typography className={classes.dateError} component="div">
                  {t('data_not_available')}
                </Typography>
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: 'flex' }}
            direction="column"
          >
            <Card
              className={classes.card}
              style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
            >
              <div className={classes.company}>
                {t('shareholding_by_holder_types_percent')}
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PieChart
                  data={
                    dailyTopChange?.length === 0
                      ? []
                      : holdingsByType.map((item) => item.shareholding)
                  }
                  labels={
                    dailyTopChange?.length === 0
                      ? []
                      : holdingsByType.map(
                          (item) =>
                            item.holderTranslation?.[lang] || item.holderType,
                        )
                  }
                  datalabels={holdingsByType.map(
                    (item) =>
                      `${(item.percentage * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}%`,
                  )}
                />
                {dailyTopChange?.length === 0 && (
                  <Typography
                    className={classes.placeholder}
                    style={{
                      position: 'absolute',
                    }}
                  >
                    {t('holder_list_placeholder')}
                  </Typography>
                )}
              </div>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            style={{ display: 'flex' }}
            direction="column"
          >
            <Card className={classes.card} style={{ flex: 1 }}>
              <div className={classes.row}>
                <div className={classes.company}>
                  {t('top_10_daily_holding_changes_in_ccass', {
                    count: thresholdDailyHoldings,
                  })}
                </div>
                {thresholdDailyHoldings && (
                  <Select
                    disableUnderline
                    value={thresholdDailyHoldings}
                    defaultValue
                    onChange={(e: any) => {
                      setThresholdDailyHoldings(e.target.value);
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {topHoldersThreshold.map((item: any) => (
                      <MenuItem
                        key={item.toString()}
                        value={item.toString()}
                        classes={{
                          root: classes.menuItem,
                          selected: classes.selected,
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
              <PaginationTable
                key={`table-top-${thresholdDailyHoldings}`}
                disableSearch
                showIndex
                fetchData={fetchData}
                defaultPageSize={10}
                pageCount={(thresholdDailyHoldings || 10) / 10}
                columns={columns}
                data={dailyTopChange?.length === 0 ? [] : dailyTopChange}
                loading={false}
                onClickRow={(item) => {
                  if (item.participantId) setDailyChangeHolder(item);
                }}
                placeholder={
                  dailyTopChange.length === 0
                    ? t('holder_list_placeholder')
                    : undefined
                }
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              className={classes.card}
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div className={classes.row}>
                <div className={classes.company}>
                  {t('top_10_holders_in_ccass', {
                    count: thresholdHolders,
                  })}
                </div>
                {thresholdHolders && (
                  <Select
                    disableUnderline
                    value={thresholdHolders}
                    defaultValue
                    onChange={(e: any) => {
                      setThresholdHolders(e.target.value);
                      if (e.target.value === '10') {
                        setExpanded(false);
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {topHoldersThreshold.map((item: any) => (
                      <MenuItem
                        key={item.toString()}
                        value={item.toString()}
                        classes={{
                          root: classes.menuItem,
                          selected: classes.selected,
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
              <HorizontalBar
                key={`top-${thresholdHolders}-holders-${
                  expanded ? 'expand' : 'collapse'
                }-table`}
                height={expanded ? 200 : 100}
                labels={
                  dailyTopChange?.length === 0
                    ? []
                    : expanded
                    ? topNHolders.map((item) => item.participantName)
                    : topNHolders
                        .slice(0, 10)
                        .map((item) => item.participantName)
                }
                data={
                  dailyTopChange?.length === 0
                    ? []
                    : expanded
                    ? topNHolders.map((item) => item.shareholding)
                    : topNHolders.slice(0, 10).map((item) => item.shareholding)
                }
                datalabels={topNHolders.map(
                  (item) => `${(item.percentage * 100).toFixed(2)}%`,
                )}
              />
              {!expanded && thresholdHolders && thresholdHolders > 10 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    color="primary"
                    aria-label="expand"
                    size="medium"
                    onClick={() => setExpanded(true)}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </div>
              )}
              {dailyTopChange?.length === 0 && (
                <Typography
                  style={{
                    position: 'absolute',
                    alignSelf: 'center',
                  }}
                  className={classes.placeholder}
                >
                  {t('holder_list_placeholder')}
                </Typography>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <div
                className={classes.row}
                style={{ marginBottom: 6, alignItems: 'flex-start' }}
              >
                <div className={classes.company} style={{ flex: 1 }}>
                  {t('southbound_holding')}
                </div>
                <div className={classes.dateTo}>{t('common:from')}</div>
                <div>
                  <KeyboardDatePicker
                    autoOk
                    disableFuture
                    variant="inline"
                    format="dd/MM/yyyy"
                    value={startDate}
                    minDate={sub(new Date(), { days: 365 })}
                    InputAdornmentProps={{ position: 'end' }}
                    inputVariant="outlined"
                    invalidDateMessage={t('common:please_enter_valid_date')}
                    minDateMessage={t('common:date_must_within_a_year')}
                    onChange={(date, value) => {
                      if (!date || !value || value.includes('_')) return;

                      if (!isValid(date)) {
                        alert(t('common:please_enter_valid_date'));
                        return;
                      }

                      const start = date.getTime();
                      if (endDate.getTime() < start) {
                        alert(t('common:start_date_should_before_end_date'));
                      } else if (start > Date.now()) {
                        alert(t('common:date_cannot_be_future'));
                      } else {
                        setStartDate(date);
                      }
                      //  else if (
                      //   isBefore(
                      //     start,
                      //     new Date('Apr 01 2021 00:00:00 GMT+0800'),
                      //   )
                      // ) {
                      //   alert(t('common:start_must_after_minimal_date'));
                      // }
                    }}
                    // @ts-ignore
                    classes={{ root: classes.dateInput }}
                  />
                  {!loading &&
                    southboundHoldings?.[0] &&
                    !isSameDay(
                      new Date(southboundHoldings[0].date),
                      startDate,
                    ) && (
                      <Typography className={classes.dateError} component="div">
                        {t('data_not_available')}
                      </Typography>
                    )}
                </div>
                <div className={classes.dateTo}>{t('common:to')}</div>
                <div>
                  <KeyboardDatePicker
                    autoOk
                    disableFuture
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={endDate}
                    minDate={startDate}
                    maxDate={sub(new Date(), { days: 1 })}
                    invalidDateMessage={t('common:please_enter_valid_date')}
                    maxDateMessage={t('common:date_cannot_be_future')}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={(date, value) => {
                      if (!date || !value || value.includes('_')) return;

                      if (!isValid(date)) {
                        alert(t('common:please_enter_valid_date'));
                        return;
                      }

                      const end = date.getTime();

                      if (startDate.getTime() > end) {
                        alert(t('common:end_date_should_after_start_date'));
                      } else {
                        setEndDate(date);
                      }
                    }}
                    // @ts-ignore
                    classes={{ root: classes.dateInput }}
                  />
                  {!loading &&
                    southboundHoldings.length > 0 &&
                    southboundHoldings?.[southboundHoldings.length - 1] &&
                    !isSameDay(
                      new Date(
                        southboundHoldings?.[
                          southboundHoldings.length - 1
                        ].date,
                      ),
                      endDate,
                    ) && (
                      <Typography className={classes.dateError} component="div">
                        {t('data_not_available')}
                      </Typography>
                    )}
                </div>
              </div>
              {isAfter(startDate, sub(new Date(), { days: 366 })) &&
              isBefore(endDate, sub(new Date(), { days: 1 })) &&
              southboundHoldings &&
              southboundHoldings.length > 0 ? (
                <ChartSouthbound
                  unit={unit}
                  data={southboundHoldings.map((item) => ({
                    t: new Date(item.date),
                    y: item.shareholdings,
                  }))}
                  subdata={southboundHoldings.map((item) => ({
                    t: new Date(item.date),
                    y: (item.percentage * 100).toFixed(3),
                  }))}
                />
              ) : (
                <div
                  style={{
                    height: 400,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{t('holder_list_placeholder')}</Typography>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <HolderDetailModal
        holder={dailyChangeHolder}
        onClose={() => setDailyChangeHolder(undefined)}
      />
    </div>
  );
};

export default CcassHolding;
