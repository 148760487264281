import React from 'react';
import { makeStyles, Theme, Button, TextField } from '@material-ui/core';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import AdminService from '../../../../api/admin';
import PaginationTable from '../../../../components/PaginationTable';

interface Props {
  csCompanies: any[];
  onChange: (data: any[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tickerInput: {
    borderRadius: 4,
    border: 'solid 1px #d9d9d9',
    fontSize: '1.4rem',
    minWidth: 300,
  },
  buttonRemove: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  dialogMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

const COLUMNS: Array<Column<any>> = [
  {
    Header: 'manage_user:hk_ticker',
    accessor: 'tickerNo',
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 250,
  },
  {
    Header: 'manage_user:name',
    accessor: 'entityName',
    disableFilters: true,
    disableSortBy: true,
  },
];

const TabHKTicker: React.FC<Props> = ({ csCompanies, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_user');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [ticker, setTicker] = React.useState('');

  const addTicker = () => {
    setLoading(true);
    AdminService.checkTicker('HK', ticker)
      .then((res) => {
        // if (!res.data.tickerExistsInCCASS) {
        //   setError(
        //     'The stock ticker added is not currently on our CCASS database. We will add the stock to our database and the data will appear in 2-3 days',
        //   );
        // }
        setTicker('');
        onChange([
          ...csCompanies.filter(
            (item) =>
              !(
                item.tickerNo === res.tickerNo &&
                item.tickerRegion === res.tickerRegion
              ),
          ),
          res,
        ]);
      })
      .catch(() => {
        setError('Invalid ticker');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderActions = (item: any) => {
    return (
      <div className="table-row-actions">
        <Button
          aria-label="remove"
          variant="contained"
          className={classes.buttonRemove}
          onClick={() => {
            onChange(
              csCompanies.filter(
                (csCompany) =>
                  !(
                    item.tickerNo === csCompany.tickerNo &&
                    item.tickerRegion === csCompany.tickerRegion
                  ),
              ),
            );
          }}
        >
          Remove
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <TextField
          className={classes.tickerInput}
          placeholder={t('manage_user:insert_hk_ticker')}
          value={ticker}
          onChange={(event) => setTicker(event.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="button-create"
          style={{ marginLeft: 12, borderRadius: 25 }}
          disabled={ticker.length === 0}
          onClick={addTicker}
        >
          {t('manage_user:add')}
        </Button>
      </div>
      {error && <div className={classes.dialogMessage}>{error}</div>}
      <PaginationTable
        defaultPageSize={5}
        columns={COLUMNS}
        data={csCompanies}
        loading={loading}
        renderActions={renderActions}
        disableSearch
        hidePaginationControl
      />
    </div>
  );
};

export default TabHKTicker;
