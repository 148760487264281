/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid, List, ListItem, Tabs, Tab } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import ThoughtLeadershipService from '../../../api/thoughtLeadership';
import { Article } from '../../../models/Article';
import { industryAnalystReport } from '../../../assets';
import TabPanel, { a11yProps } from '../../../components/TabPanel';
import HeaderGroup from '../../../components/HeaderGroup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0 16px',
    },
    listItem: {
      width: '100%',
      padding: 20,
      borderRadius: 2,
      border: 'solid 0.5px #b9b8b8',
      '& img': {
        height: 120,
        width: 160,
        marginRight: 24,
      },
      marginBottom: 8,
      cursor: 'pointer',
    },
    itemTitle: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: '#262626',
    },
    itemDesc: {
      whiteSpace: 'break-spaces',
      fontSize: '1.2rem',
      color: '#575757',
    },
    tabs: {},
    indicator: {
      display: 'none',
    },
    tab: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      backgroundColor: '#f3f3f3',
      border: '1px solid #e7e7e7',
      color: '#ababab',
      marginRight: 4,
      borderTop: '4px solid #e7e7e7',
      maxWidth: 280,
    },
    tabSelected: {
      borderBottomWidth: 0,
      backgroundColor: '#fff',
      color: '#000',
      borderTop: '4px solid #fd0f1b',
    },
    tabBorder: {
      width: '100%',
      height: 1,
      position: 'absolute',
      background: '#e7e7e7',
      bottom: 0,
    },
    wrapper: {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0px !important',
        marginRight: 6,
        height: '1em',
        width: '1em',
        '& path': {
          fill: '#f50057',
          opacity: 1,
        },
      },
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    pagination: {
      margin: '12px 0',
      alignSelf: 'center',
      '& .MuiPaginationItem-root': {
        fontSize: '1rem',
        fontWeight: 'bold',
        borderRadius: 4,
        '&.Mui-selected': {
          backgroundColor: '#262626',
          color: '#fff',
        },
      },
    },
  }),
);

const TABS = [{ label: 'featured_articles', icon: industryAnalystReport }];

const ThoughtLeadership: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('thought_leadership');
  const [value, setValue] = React.useState(0);
  const [articles, setArticles] = React.useState<Article[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageIndex, setPageIndex] = React.useState(1);

  React.useEffect(() => {
    ThoughtLeadershipService.getThoughtLeadership(3, pageIndex).then((res) => {
      setArticles(res.data);
      setPageCount(res.totalPage);
    });
  }, [pageIndex]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={7} />
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={value}
            // onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
            classes={{ root: classes.tabs, indicator: classes.indicator }}
          >
            <div className={classes.tabBorder} />
            {TABS.map((item, index) => (
              <Tab
                {...a11yProps(index)}
                icon={<item.icon />}
                value={index}
                label={t(item.label)}
                disableFocusRipple
                disableTouchRipple
                disableRipple
                classes={{
                  root: clsx(classes.tab, {
                    [classes.tabSelected]: index === value,
                  }),
                  wrapper: classes.wrapper,
                }}
                key={item.label}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <List aria-label="contacts">
                    {articles.map((item) => (
                      <ListItem
                        className={classes.listItem}
                        onClick={() => window.open(item.webLink, '_blank')}
                      >
                        <img src={item.imageUrl} alt={item.title} />
                        <div style={{ minHeight: 120 }}>
                          <div className={classes.itemTitle}>{item.title}</div>
                          <div className={classes.itemDesc}>
                            {item.description}
                          </div>
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.paginationContainer}>
        <Pagination
          count={pageCount}
          defaultPage={1}
          page={pageIndex}
          showFirstButton
          showLastButton
          onChange={(e, val) => setPageIndex(val)}
          size="small"
          className={classes.pagination}
        />
      </Grid>
    </div>
  );
};

export default ThoughtLeadership;
