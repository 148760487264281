import React from 'react';
import GpsNotFixedRoundedIcon from '@material-ui/icons/GpsNotFixedRounded';
import { useTranslation } from 'react-i18next';
import { makeStyles, IconButton, Button, Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PublishIcon from '@material-ui/icons/Publish';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import PaginationTable from '../../../components/PaginationTable';
import CreateStaffDialog from './createStaff/CreateStaffDialog';
import { Staff } from '../../../models/Staff';
import AdminService from '../../../api/admin';
import { selectIsChecker } from '../../auth/duck/selector';
import { useAppDispatch } from '../../../store';
import { makerRequestSuccess } from '../../auth/duck/authDuck';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '& tr': {
      cursor: 'pointer',
    },
    '& .react-table .tr > div:nth-child(n+5)': {
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      '& span': {
        textAlign: 'center',
      },
      '& div:nth-child(1)': {
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
      },
    },
  },
  funcionAccess: {
    height: 50,
    width: '100%',
    background: 'white',
    marginBottom: 5,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 'bold',
    color: '#262626',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

const SelectRomCompany: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const lang = useSelector(selectLang);

  const [data, setData] = React.useState<Staff[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [createUserCount, setCreateUserCount] = React.useState(0);
  const [error, setError] = React.useState('');

  const isChecker = useSelector(selectIsChecker);
  const fetchIdRef = React.useRef(0);
  const inputFileRef = React.useRef<HTMLInputElement | null>();
  const [fileInputId, setFileInputId] = React.useState(0);

  const COLUMNS: Array<Column<Staff>> = React.useMemo(
    () => [
      {
        Header: 'manage_staff:email',
        accessor: 'username',
        disableFilters: true,
      },
      {
        Header: 'manage_staff:name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'manage_staff:dept',
        accessor: 'dept',
        disableFilters: true,
        maxWidth: 80,
      },
      {
        Header: 'manage_staff:acc_type',
        accessor: 'role',
        disableFilters: true,
        maxWidth: 100,
        Cell: ({ value }: any) =>
          value === 'TricorSuperAdmin'
            ? 'Super Admin'
            : value === 'TricorMaker'
            ? 'Maker'
            : value === 'TricorChecker'
            ? 'Checker'
            : '-',
      },
      {
        Header: 'tabbar:entity_management',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.entityManagement ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          ),
      },
      {
        Header: 'tabbar:register_of_members',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.registerOfMember ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          ),
      },
      {
        Header: 'tabbar:ccass_holding',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.ccassHolding ? <DoneIcon /> : <CloseIcon />,
      },
      {
        Header: 'tabbar:reports',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.reports ? <DoneIcon /> : <CloseIcon />,
      },
      {
        Header: 'tabbar:shareholder_analytics',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.ownershipAnalysis ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          ),
      },
      {
        Header: 'tabbar:market_intelligence',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.marketIntelligence ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          ),
      },
      {
        Header: 'tabbar:industry_analyst_report',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.industryAnalysis ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          ),
      },
      {
        Header: 'tabbar:eagm',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.eAGM ? <DoneIcon /> : <CloseIcon />,
      },
      {
        Header: 'tabbar:board_portal',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.boardPortal ? <DoneIcon /> : <CloseIcon />,
      },
      {
        Header: 'tabbar:invoice_management',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.invoiceManagement ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          ),
      },
      {
        Header: 'tabbar:thought_leadership',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: any) =>
          original?.accessRights?.thoughtLeadership ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          ),
      },
      {
        Header: 'manage_user:status',
        accessor: 'status',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) =>
          value ? t(`manage_user:${value.toLowerCase()}`) : '-',
      },
    ],
    [t],
  );

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getStaffs(pageSize, pageIndex, query, sortBy).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data.data);
          setPageCount(res.data.totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const onClickRow = (item: Staff) => {
    if (item.id) {
      history.push(`/admin/manage_staff/${item.id}`);
    }
  };

  const renderActions = (item: Staff) => (
    <div className="table-row-actions">
      <IconButton
        aria-label="edit"
        onClick={() => {
          history.push(`/admin/manage_staff/${item.id}`);
        }}
      >
        <EditRoundedIcon htmlColor="#a5a5a5" />
      </IconButton>
    </div>
  );

  const refreshUserList = () => {
    setCreateUserCount((state) => state + 1);
    dispatch(makerRequestSuccess());
  };

  const onClickImport = () => {
    if (inputFileRef.current) {
      inputFileRef.current?.click();
    }
  };

  const onFileChange = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    AdminService.importClient(file)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        let errorMsgKey = 'message';
        if (lang === 'zh') errorMsgKey = 'messageZH';
        else if (lang === 'cn') errorMsgKey = 'messageCN';
        setError(e.response.data[errorMsgKey]);
      })
      .finally(() => {
        setFileInputId((state) => state + 1);
      });
  };

  return (
    <div className={classes.container}>
      <input
        type="file"
        id="file"
        key={`file-input-${fileInputId}`}
        ref={(ref) => {
          inputFileRef.current = ref;
        }}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      <PaginationTable
        key={createUserCount}
        defaultPageSize={20}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name"
        placeholder={t('manage_staff:select_staff_placeholder')}
        onClickRow={onClickRow}
        renderActions={!isChecker ? renderActions : undefined}
        renderHeaderLeft={
          <>
            <GpsNotFixedRoundedIcon className="section-icon" />
            <span className="section-title f1">
              {t('manage_staff:select_staff')}
            </span>
          </>
        }
        renderHeaderRight={
          !isChecker ? (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="button-create"
                style={{ marginRight: 8 }}
                onClick={onClickImport}
                endIcon={<PublishIcon />}
              >
                {t('manage_user:import')}
              </Button>
              <CreateStaffDialog onCreateSuccess={refreshUserList} />
            </>
          ) : undefined
        }
        extraHeader={
          <div className={classes.funcionAccess}>Function Access</div>
        }
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectRomCompany;
