import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Button,
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NotesIcon from '@material-ui/icons/Notes';
import { format } from 'date-fns';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
  selectEntities,
  selectCurrentEntityId,
  selectCurrentEntity,
} from './duck/selector';
import {
  setEntity as ActionSetEntity,
  getEntityDetail as ActionGetEntityDetail,
} from './duck/entityDuck';
import { RootState, AppDispatch } from '../../../store';
import { selectUserInfo } from '../../auth/duck/selector';
import EntityList from './EntityList';
import Entity from '../../../models/Entity';
import CardCompanyProfile from '../../../components/CardCompanyProfile';
import { CompanyUser } from '../../../models/CompanyUser';
import EntityService from '../../../api/entity';
import { saveAsFile } from '../../../utils';
import { selectLang } from '../../settings/duck/selector';

interface Props {
  user: CompanyUser | null;
  entities: Entity[];
  entity?: Entity;
  currentEntityId?: string | null;
  getEntityDetail: (entity: Entity) => void;
  setEntity: (entity: Entity) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      overflowX: 'auto',
    },
    summaryContainer: {
      '& div:nth-child(2)': {
        height: 'unset',
        maxHeight: 'unset',
        flex: 1,
      },
      '& .section-container': {
        padding: 0,
        marginBottom: 12,
        height: 36,
        alignItems: 'center',
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
      padding: '20px 30px',
      marginBottom: 2,
      marginTop: 18,
      borderRadius: 4,
      minHeight: 120,
      overflowY: 'auto',
    },
    currentDirector: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#262626',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 0,
    },
    label: {
      fontSize: '1rem',
      color: '#575757',
      fontWeight: 'bold',
    },
    value: {
      fontSize: '1rem',
      color: '#707070',
    },
    button: {
      padding: 4,
      minWidth: 0,
      borderRadius: 24,
      '&:hover': {
        background: 'red',
      },
    },
    buttonIcon: {
      marginRight: 0,
      marginLeft: 0,
    },
  }),
);

export const TabListOfEntities: React.FC<Props> = ({
  user,
  entities,
  entity,
  currentEntityId,
  getEntityDetail,
  setEntity,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('entity_management');
  const lang = useSelector(selectLang);

  React.useEffect(() => {
    if (!currentEntityId && entities[0]) {
      setEntity(entities[0]);
    }
  }, [currentEntityId, entities]);

  React.useEffect(() => {
    if (currentEntityId) {
      const selected = entities.find((item) => item.id === currentEntityId);
      if (selected) getEntityDetail(selected);
    }
  }, [currentEntityId]);

  const downloadSummary = () => {
    if (currentEntityId)
      EntityService.getSummaryReport(currentEntityId, lang)
        .then((res) => {
          saveAsFile(
            new Blob([res.data]),
            `${entity?.name}-summary-report.xlsx`,
          );
        })
        .catch((e) => {
          alert('Fail to download file');
        });
  };

  const downloadShareholders = () => {
    if (currentEntityId)
      EntityService.getShareholdersReport(currentEntityId, lang)
        .then((res) => {
          saveAsFile(
            new Blob([res.data]),
            `${entity?.name}-shareholders-report.xlsx`,
          );
        })
        .catch((e) => {
          alert('Fail to download file');
        });
  };

  const downloadDirectors = () => {
    if (currentEntityId)
      EntityService.getDirectorsReport(currentEntityId, lang)
        .then((res) => {
          saveAsFile(
            new Blob([res.data]),
            `${entity?.name}-directors-report.xlsx`,
          );
        })
        .catch((e) => {
          alert('Fail to download file');
        });
  };

  return (
    <Grid container spacing={3} direction="row" alignItems="stretch">
      <Grid item xs={12} md={6} lg={8} className={classes.listContainer}>
        <EntityList
          user={user}
          currentEntityId={currentEntityId}
          onClickEntity={setEntity}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        direction="column"
        style={{ display: 'flex', maxHeight: 620 }}
      >
        <Grid item className={classes.summaryContainer} direction="column">
          <div className="section-container">
            <NotesIcon className="section-icon" />
            <span className="section-title">{t('company_summary')}</span>
          </div>
          <CardCompanyProfile
            name={entity?.name}
            address={entity?.address}
            incorpDate={entity?.incorpDateString}
            incorpPlace={
              entity?.jurisdiction
                ? t(entity!.jurisdiction.replace(/\s/g, '_').toLowerCase())
                : ''
            }
            incorpNumber={entity?.incorpNumber}
            companySecretary={entity?.secretaries?.reduce(
              (acc, secretary, index) =>
                `${acc}${index > 0 ? '\n' : ''}${secretary.comSecName}`,
              '',
            )}
            // onClickDownload={downloadSummary}
          />
        </Grid>
        <div className={classes.card}>
          <div className={classes.row} style={{ marginBottom: 8 }}>
            <div className={classes.currentDirector}>
              {t('current_directors')}
            </div>
            {/* <Button
              variant="contained"
              startIcon={<DownloadIcon htmlColor="#fff" fontSize="small" />}
              classes={{
                contained: classes.button,
                startIcon: classes.buttonIcon,
              }}
              onClick={downloadDirectors}
            /> */}
          </div>
          <div className={classes.row}>
            <div className={classes.label}>{`${t('name')}:`}</div>
            <div className={classes.label}>{`${t('appointment_date')}:`}</div>
          </div>
          {entity?.directors?.map((director, index) => (
            <div
              className={classes.row}
              key={`${entity.fileCode}::${director.directorFileCode}::${index}`}
            >
              <div className={classes.value}>{director.directorName}</div>
              <div className={classes.value}>
                {format(new Date(director.dateAppoint), 'yyyy-MM-dd')}
              </div>
            </div>
          ))}
        </div>
        <div className={classes.card}>
          <div className={classes.row} style={{ marginBottom: 8 }}>
            <div className={classes.currentDirector}>
              {t('current_shareholders')}
            </div>
            {/* <Button
              variant="contained"
              startIcon={<DownloadIcon htmlColor="#fff" fontSize="small" />}
              classes={{
                contained: classes.button,
                startIcon: classes.buttonIcon,
              }}
              onClick={downloadShareholders}
            /> */}
          </div>
          {entity?.shareholders?.map((holder, index) => (
            <div
              className={classes.row}
              key={`${entity.fileCode}::${holder.shareHolderCode}::${index}`}
            >
              <div className={classes.value}>{holder.shareHolderName}</div>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),

  entities: selectEntities(state),

  currentEntityId: selectCurrentEntityId(state),

  entity: selectCurrentEntity(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setEntity: (entity: Entity) =>
    dispatch(ActionSetEntity({ entityId: entity.id })),

  getEntityDetail: (entity: Entity) => {
    dispatch(ActionGetEntityDetail({ entity }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabListOfEntities);
