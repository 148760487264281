/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button, Dialog } from '@material-ui/core';
import { Column } from 'react-table';
import { useSelector } from 'react-redux';
import PaginationTable from '../../../../components/PaginationTable';
import AdminService from '../../../../api/admin';
import RomService from '../../../../api/rom';
import { UserReducerContext } from '../duck';
import { ListedCompany } from '../../../../models/ListedCompany';
import { Staff } from '../../../../models/Staff';
import { selectIsChecker, selectStaffInfo } from '../../../auth/duck/selector';
import { useAppDispatch } from '../../../../store';
import { makerRequestSuccess } from '../../../auth/duck/authDuck';
import { DialogContent, DialogTitle } from '../../../../components/Dialog';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

const COLUMNS: Array<Column<ListedCompany>> = [
  {
    Header: 'register_of_members:stock_code',
    accessor: 'instruments',
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 200,
    Cell: ({ value }) => value.find((item) => item.insCode)?.insCode || '',
  },
  {
    Header: 'register_of_members:name',
    accessor: 'cmpEngName',
    disableFilters: true,
    Cell: ({ row: { original } }) =>
      `${original.cmpEngName} ${original.cmpChiName}`,
  },
];

const SelectRomCompany: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const {
    state: { user },
    dispatch,
  } = React.useContext(UserReducerContext);
  const [data, setData] = React.useState<ListedCompany[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const isChecker = useSelector(selectIsChecker);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      RomService.getCompanies(
        pageSize,
        pageIndex,
        query,
        sortBy,
        user?.id,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [user?.romOids, user?.id],
  );

  const getUser = React.useCallback(() => {
    if (user) {
      AdminService.getStaff(user.id).then((res) => {
        dispatch({ type: 'SET_USER', payload: { user: res.data } });
      });
    }
  }, []);

  const renderActions = (item: ListedCompany) => {
    const assigned =
      (user?.romOids || []).findIndex((cmpOid) => item.cmpOid === cmpOid) > -1;

    return (
      <div className="table-row-actions">
        <Button
          aria-label="assign"
          variant={isChecker ? 'text' : 'contained'}
          className={
            assigned && !isChecker ? classes.buttonOn : classes.buttonOff
          }
          disabled={isChecker}
          onClick={() => {
            if (!user) return;
            if (assigned) {
              RomService.removeUser(user.id, item.cmpOid)
                .then(() => {
                  getUser();
                  appDispatch(makerRequestSuccess());
                })
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                });
            } else {
              RomService.addUser(user.id, item.cmpOid)
                .then(() => {
                  getUser();
                  appDispatch(makerRequestSuccess());
                })
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                });
            }
          }}
        >
          {assigned ? 'Unassign' : 'Assign'}
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={15}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by stock code or name"
        placeholder={t('manage_user:rom_placeholder')}
        renderActions={renderActions}
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectRomCompany;
