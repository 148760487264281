import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import config from './config';
import { setOktaAccessToken } from '../../api';


/* okta-auth-js implemented, no longer required
const applicationOrigin = window.location.origin;

const oktaEndpoint = {
    token_endpoint: `${config.issuer}/v1/token`,
    authorization_endpoint: `${config.issuer}/v1/authorize`,
    end_session_endpoint: `${config.issuer}/v1/logout`,
}

const applicationEndpoint = {
    signInRedirectUri: `${applicationOrigin}/login/callback`,
    postLogoutRedirectUri: applicationOrigin
}

const getOktaTokens = (code: string, state: string, codeVerifier: string) => {
    const axiosRequestConfig: AxiosRequestConfig = {
        headers: {
            'accept': 'application/json',
            'cache-control': 'no-cache',
            'content-type': 'application/x-www-form-urlencoded',
        },
    };
    const queryParameters = convertObjectToUriSearchParams({
        grant_type: 'authorization_code',
        client_id: config.clientId,
        redirect_uri: applicationEndpoint.signInRedirectUri,
        code: code,
        code_verifier: codeVerifier,
    });
    return axios.post(oktaEndpoint.token_endpoint, queryParameters, axiosRequestConfig);
}

const refreshOktaTokens = (oktaRefreshToken: string) => {
    const data = {
        grant_type: 'refresh_token',
        redirect_uri: applicationEndpoint.signInRedirectUri,
        scope: config.scopes.join(' '),
        refresh_token: oktaRefreshToken,
        client_id: config.clientId,
    };
    const queryParameters = convertObjectToUriSearchParams(data);
    return axios.post(oktaEndpoint.token_endpoint, queryParameters);
}

const generateOktaSingleLogoutUrl = (oktaIDToken: string): string => {
    const data = {
        id_token_hint: oktaIDToken,
        post_logout_redirect_uri: applicationEndpoint.postLogoutRedirectUri,
        // state: generateState(),
    };
    const queryParameters = convertObjectToUriSearchParams(data);
    return `${oktaEndpoint.end_session_endpoint}?${queryParameters}`;
}

const generateOktaRedirectLoginUrl = (state: string, codeVerifier: string): string => {
    const codeChallenge = generateCodeChallenge(codeVerifier);
    const data = {
        client_id: config.clientId,
        response_type: 'code',
        scope: config.scopes.join(' '),
        redirect_uri: applicationEndpoint.signInRedirectUri,
        state: state,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
    };
    const queryParameters = convertObjectToUriSearchParams(data);
    return `${oktaEndpoint.authorization_endpoint}?${queryParameters}`;
}

const generateCodeVerifier = (): string => {
    const acceptedAlphanumeric = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    const length = 43;

    let codeVerifier = "";
    for (let i = 0; i < length; i++) {
        codeVerifier += acceptedAlphanumeric[Math.floor(Math.random() * acceptedAlphanumeric.length)];
    }

    return codeVerifier;
}

const generateCodeChallenge = (code_verifier: string): string => {
    const hash = crypto.createHash('sha256');
    hash.update(code_verifier);
    return base64EncodingToBase64UrlEncoding(hash.digest('base64'));
};

const generateState = (): string => {
    const randomBytes = crypto.randomBytes(16); // Generate 16 random bytes
    return randomBytes.toString('hex'); // Convert to hexadecimal string
}

const base64EncodingToBase64UrlEncoding = (base64String: string): string => {
    // Remove padding
    base64String = base64String.replace(/=+$/, '');
    // Replace '+' with '-'
    base64String = base64String.replace(/\+/g, '-');
    // Replace '/' with '_'
    base64String = base64String.replace(/\//g, '_');
    return base64String;
}

const convertObjectToUriSearchParams = (object: Record<string, any>) => {
    let queryString = '';
    let objectKeys = Object.keys(object);
    for (let i = 0; i < objectKeys.length; i++) {
        let key = encodeURIComponent(objectKeys[i]);
        let value = encodeURIComponent(object[key]);
        queryString += key + '=' + value;
        if (i !== objectKeys.length) {
            queryString += '&';
        }
    }
    return queryString;
} 
*/

const transformAuthState = async (oktaAuth: OktaAuth, authState: AuthState) => {
    if (!authState.isAuthenticated) {
        return authState;
    }
    if(authState.accessToken){
        setOktaAccessToken(authState.accessToken.accessToken);
    }
    return authState;
}

//TODO: add axios as httpRequestClient
//for reference https://github.com/okta/okta-auth-js/blob/a41899b7effb7a1a6a4361d58008169c3410cf61/README.md#httprequestclient
const oktaAuth = new OktaAuth({
    ...config,
    /* devMode: true,
    tokenManager: {
        expireEarlySeconds: 1 * 60 // 1 minute
    }, */
    transformAuthState: transformAuthState
});

const OktaService = () => {
    return {
        oktaAuth: oktaAuth,
    }
}

export default OktaService