/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import { ListedCompany } from '../../../models/ListedCompany';
import RomService from '../../../api/rom';
import { RootState } from '../../../store';
import { selectUserInfo } from '../../auth/duck/selector';
import { CompanyUser } from '../../../models/CompanyUser';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minHeight: 480,
    minWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90%',
    },
  },
  content: {
    padding: '0 8px',
  },
  list: {},
  listItem: {
    '&:hover': {
      backgroundColor: '#ffedeb',
    },
  },
  primaryText: {
    fontSize: '1.4rem',
    color: '#000',
  },
  secondaryText: {
    fontSize: '1.2rem',
    color: '#707070',
  },
  buttonSelect: {
    height: 33,
    borderRadius: 24,
    minWidth: 128,
    marginLeft: 4,
    backgroundColor: '#e84637',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '0 16px',
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
  },
  pagination: {
    margin: '12px 0',
    alignSelf: 'center',
    '& .MuiPaginationItem-root': {
      fontSize: '1rem',
      fontWeight: 'bold',
      borderRadius: 20,
      '&.Mui-selected': {
        backgroundColor: '#e84637',
        color: '#fff',
      },
    },
  },
}));

interface Props {
  user: CompanyUser | null;

  selectCompany: (company: ListedCompany) => void;
}

const SelectCompanyDialog: React.FC<Props> = ({ user, selectCompany }) => {
  const classes = useStyles();
  const { t } = useTranslation('register_of_members');
  const [data, setData] = React.useState<ListedCompany[]>([]);
  const [open, setOpen] = React.useState(false);

  const [pageCount, setPageCount] = React.useState(0);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const fetchIdRef = React.useRef(0);

  React.useEffect(() => {
    if (open && user) {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      RomService.getUserRom(user.id, 10, pageIndex, query)
        .then((res) => {
          if (fetchId === fetchIdRef.current) {
            setData(res.data.data);
            setPageCount(res.data.totalPage);
            setLoading(false);
          }
        })
        .catch(() => {
          setData([]);
          setPageCount(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, user, query, pageIndex]);

  React.useEffect(() => {
    setPageIndex(1);
  }, [query]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onExited = () => {
    setQuery('');
    setPageCount(0);
    setPageIndex(1);
    setData([]);
  };

  return (
    <>
      <Button
        variant="contained"
        className={classes.buttonSelect}
        onClick={handleClickOpen}
        disableElevation
      >
        {t('select_company')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="select-rom-company-dialog-title"
        open={open}
        classes={{ paper: classes.dialog }}
        onExited={onExited}
      >
        <DialogTitle id="select-rom-company-dialog-title" onClose={handleClose}>
          {t('select_company')}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            placeholder={t('search_company_placeholder')}
            className="search-input"
            onChange={(event) => setQuery(event.target.value)}
          />
          <List className={classes.list}>
            {data.map((company) => (
              <ListItem
                button
                key={company.cmpOid}
                onClick={() => {
                  selectCompany(company);
                  handleClose();
                }}
                classes={{ button: classes.listItem }}
              >
                <ListItemText
                  primary={`${company.cmpEngName} ${company.cmpChiName || ''}`}
                  classes={{
                    primary: classes.primaryText,
                    secondary: classes.secondaryText,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <Pagination
          count={pageCount}
          defaultPage={1}
          page={pageIndex}
          showFirstButton
          showLastButton
          onChange={(e, value) => setPageIndex(value)}
          size="small"
          className={classes.pagination}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

export default connect(mapStateToProps)(SelectCompanyDialog);
