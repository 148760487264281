import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { IconButton, makeStyles, Theme, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import { CompanyUser } from '../../../models/CompanyUser';
import ThoughtLeadershipService from '../../../api/thoughtLeadership';
import { Article } from '../../../models/Article';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRemove: {
    backgroundColor: '#fd101b',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelRemove: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

interface Props {
  article: Article;

  small?: boolean;

  onDeleteSuccess?: () => void;
}

const DeleteArticleDialog: React.FC<Props> = ({
  article,
  onDeleteSuccess,
  small,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_thought_leadership');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    ThoughtLeadershipService.deleteArticle(article.id)
      .then(() => {
        handleClose();
        if (onDeleteSuccess) onDeleteSuccess();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {small ? (
        <IconButton aria-label="clear" onClick={handleClickOpen}>
          <ClearRoundedIcon htmlColor="#a5a5a5" />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          endIcon={<DeleteIcon />}
          className="button-create"
          onClick={handleClickOpen}
        >
          {t('delete_article')}
        </Button>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="delete-user-dialog-title"
        open={open}
      >
        <DialogTitle id="delete-user-dialog-title" onClose={handleClose}>
          {t('delete_article')}
        </DialogTitle>
        <DialogContent>
          <div className={classes.message}>{t('delete_article_message')}</div>
        </DialogContent>
        <div className={classes.bottomContainer}>
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRemove,
              label: classes.labelRemove,
            }}
            onClick={handleClickDelete}
            disabled={loading}
          >
            {t('common:delete')}
          </Button>
          <Button
            variant="outlined"
            classes={{ label: classes.labelCancel }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
            }}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteArticleDialog;
