import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

const selectAuth = (state: RootState) => state.auth;

export const selctAccessToken = createSelector(
  selectAuth,
  (state) => state.token,
);

export const selectStaffInfo = createSelector(
  selectAuth,
  (state) => state.staff,
);

export const selectUserInfo = createSelector(
  selectAuth,
  (state) => state.companyUser,
);

export const selectIsClient = createSelector(
  selectStaffInfo,
  selectUserInfo,
  (staff, user) => {
    switch (staff?.role || user?.role) {
      case 'ClientChecker':
      case 'ClientMaker':
        return true;
      default:
        return false;
    }
  },
);

export const selectIsClientChecker = createSelector(
  selectStaffInfo,
  selectUserInfo,
  (staff, user) => {
    switch (staff?.role || user?.role) {
      case 'ClientChecker':
        return true;
      default:
        return false;
    }
  },
);

export const selectAdminAccessRight = createSelector(
  selectStaffInfo,
  selectUserInfo,
  (staff, user) => {
    const accessRights = {
      manageStaff: true,
      manageClient: true,
      manageCompanyAccess: true,
      manageApplication: true,
      manageReports: true,
      manageFeedback: true,
      manageThoughtleadership: true,
      userPortal: true,
    };
    switch (staff?.role || user?.role) {
      case 'TricorSuperAdmin':
        return accessRights;
      case 'TricorMaker':
        return {
          ...accessRights,
          manageReports: false,
          manageFeedback: false,
          manageThoughtleadership: false,
        };
      case 'TricorChecker':
        return {
          ...accessRights,
          manageReports: false,
          manageFeedback: false,
          manageThoughtleadership: false,
        };
      case 'ClientChecker':
        return {
          ...accessRights,
          manageStaff: false,
          manageReports: false,
          manageFeedback: false,
          manageThoughtleadership: false,
        };
      case 'ClientMaker':
        return {
          ...accessRights,
          manageCompanyAccess: false,
          manageStaff: false,
          manageReports: false,
          manageFeedback: false,
          manageThoughtleadership: false,
        };
      default:
        return accessRights;
    }
  },
);

export const selectIsAuthorized = createSelector(
  selctAccessToken,
  selectStaffInfo,
  selectUserInfo,
  (token, staff, user) => {
    if (!token) return false;

    if (staff) {
      return staff.role === 'Staff' ? 'staff' : 'admin';
    }

    if (user?.role === 'ClientChecker' || user?.role === 'ClientMaker')
      return user.role;

    return 'user';
  },
);

export const selectIsChecker = createSelector(
  selectStaffInfo,
  selectUserInfo,
  (staff, user) =>
    staff?.role?.endsWith('Checker') || user?.role?.endsWith('Checker'),
);

export const selectIsMaker = createSelector(
  selectStaffInfo,
  selectUserInfo,
  (staff, user) =>
    staff?.role?.endsWith('Maker') || user?.role?.endsWith('Maker'),
);

export const selectNeedOTP = createSelector(selectAuth, (data) => data.needOTP);

export const selectRelationManagers = createSelector(
  selectUserInfo,
  (user) => user?.contactPoints,
);

export const selectPrimaryManager = createSelector(
  selectRelationManagers,
  (staffs) => staffs?.find((item) => item.isPrimary)?.staff,
);

export const selectManagementTeam = createSelector(
  selectRelationManagers,
  (staffs) =>
    staffs?.filter((item) => !item.isPrimary).map((item) => item.staff) || [],
);

export const selectUserAccessRights = createSelector(selectUserInfo, (user) => {
  if (user?.accessRights) {
    return {
      ...user.accessRights,
      home: true,
      entityManagement:
        user.emCodes.length > 0 ? user.accessRights.entityManagement : false,
      registerOfMember:
        user.romOids.length > 0 ? user.accessRights.registerOfMember : false,
      adminPortal:
        user?.role === 'ClientChecker' ||
        user?.role === 'ClientMaker' ||
        user?.role?.startsWith('Tricor'),
    };
  }

  return undefined;
});

export const selectTncAccepted = createSelector(
  selectUserInfo,
  selectStaffInfo,
  (user, staff) => (staff ? true : user?.acceptedTermsAndConditions),
);

export const selectMakerSuccess = createSelector(
  selectAuth,
  (auth) => auth.makerRequestSuccess,
);