/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button } from '@material-ui/core';
import { Column } from 'react-table';
import PaginationTable from '../../../../components/PaginationTable';
import RomService from '../../../../api/rom';
import { ListedCompany } from '../../../../models/ListedCompany';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
  },
}));

const COLUMNS: Array<Column<ListedCompany>> = [
  {
    Header: 'register_of_members:stock_code',
    accessor: 'instruments',
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 200,
    Cell: ({ value }) => value.find((item) => item.insCode)?.insCode || '',
  },
  {
    Header: 'register_of_members:name',
    accessor: 'cmpEngName',
    disableFilters: true,
    Cell: ({ row: { original } }) =>
      `${original.cmpEngName} ${original.cmpChiName}`,
  },
];

interface Props {
  romOids: string[];

  onChange: (ids: string[]) => void;
}

const SelectRomCompany: React.FC<Props> = ({ romOids, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [data, setData] = React.useState<ListedCompany[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      RomService.getCompanies(pageSize, pageIndex, query, sortBy).then(
        (res) => {
          if (fetchId === fetchIdRef.current) {
            setData(res.data);
            setPageCount(res.totalPage);
            setLoading(false);
          }
        },
      );
    },
    [],
  );

  const renderActions = (item: ListedCompany) => {
    const assigned = romOids.findIndex((cmpOid) => item.cmpOid === cmpOid) > -1;

    return (
      <div className="table-row-actions">
        <Button
          aria-label="assign"
          variant="contained"
          className={assigned ? classes.buttonOn : classes.buttonOff}
          onClick={() => {
            if (assigned) {
              onChange(romOids.filter((id) => id !== item.cmpOid));
            } else {
              onChange([...romOids, item.cmpOid]);
            }
          }}
        >
          {assigned ? 'Unassign' : 'Assign'}
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={10}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by stock code or name"
        placeholder={t('manage_user:rom_placeholder')}
        renderActions={renderActions}
      />
    </div>
  );
};

export default SelectRomCompany;
