import React from 'react';
import { Button, makeStyles, Theme, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import AccessRightForm from './AccessRightForm';
import { CreateCompanyUserValues } from '../../../models/CreateCompanyUser';
import { CompanyUser } from '../../../models/CompanyUser';
import AdminService from '../../../api/admin';
import { selectLang } from '../../settings/duck/selector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonSubmit: {
    backgroundColor: '#fd101b',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelSubmit: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

interface Props {
  isUser?: boolean;
  hideAccessRight?: boolean;
  user: CompanyUser;

  onSuccess: () => void;

  initialValues: CreateCompanyUserValues;
}

const EditAccessRight: React.FC<Props> = ({
  hideAccessRight,
  isUser,
  user,
  initialValues,
  onSuccess,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [createSuccess, setCreateSuccess] = React.useState(false);

  const lang = useSelector(selectLang);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = (values: CreateCompanyUserValues) => {
    const errors: Partial<CreateCompanyUserValues> = {};

    /* update user flow, bypass phone no validation on submit form
    if (values.phoneNo.length > 0) {
      if (values.phoneNo && !/^[0-9]*$/.test(values.phoneNo))
        errors.phoneNo = 'Invalid number';

      if (values.phoneNo && !values.areaCode)
        errors.areaCode = t('error:required');

      if (values.areaCode === '852' && values.phoneNo.length !== 8) {
        errors.phoneNo = t('manage_user:error_mobile_format');
      }
    }
    */

    return errors;
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        endIcon={<EditRoundedIcon style={{ fontSize: 14, marginLeft: 4 }} />}
        className="button-create"
        onClick={handleClickOpen}
      >
        {t('common:edit')}
      </Button>

      <Dialog
        onClose={() => setCreateSuccess(false)}
        aria-labelledby="create-user-success-dialog-title"
        open={createSuccess}
      >
        <DialogTitle
          id="create-user-success-dialog-title"
          onClose={() => setCreateSuccess(false)}
        >
          <div style={{ minWidth: 250 }}>Update user successfully</div>
        </DialogTitle>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="edit-access-right-dialog-title"
        open={open}
      >
        <DialogTitle id="edit-access-right-dialog-title" onClose={handleClose}>
          {t('manage_company:edit_access_right')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const {
                email,
                name,
                phoneNo,
                areaCode,
                role,
                dept,
                ...others
              } = values;

              AdminService.updateProfile(user.id.toString(), {
                name,
                email,
                areaCode,
                phoneNo,
                role,
                dept,
                accessRight: { ...others },
              })
                .then((res) => {
                  onSuccess();
                  handleClose();
                  setCreateSuccess(true);
                })
                .catch((e) => {
                  if (e.response?.data?.errorCode === 11009) {
                    setErrors({ name: 'Another Update Request is Pending' });
                  } else if (
                    e.response?.data?.message?.includes('Invalid Phone No')
                  ) {
                    setErrors({
                      phoneNo: t('manage_user:error_mobile_format'),
                    });
                  } else {
                    let errorMsgKey = 'message';
                    if (lang === 'zh') errorMsgKey = 'messageZH';
                    else if (lang === 'cn') errorMsgKey = 'messageCN';
                    setErrors({ name: e.response.data[errorMsgKey] });
                  }
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <>
                <div>
                  <AccessRightForm
                    accessRightOnly
                    hideAccessRight={false}
                    // hideAccessRight={hideAccessRight}
                    isUser={isUser}
                  />
                </div>
                <div className={classes.bottomContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.buttonSubmit,
                      label: classes.labelSubmit,
                    }}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    {t('common:submit')}
                  </Button>
                  <Button
                    variant="outlined"
                    classes={{ label: classes.labelCancel }}
                    onClick={handleClose}
                    disabled={isSubmitting}
                  >
                    {t('common:cancel')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditAccessRight;
