/* eslint-disable import/no-cycle */
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = token;
};

export const clearToken = () => {
  delete instance.defaults.headers.common.Authorization;
};

export const setOktaAccessToken = (oktaAccessToken: string | undefined) => {
  instance.defaults.headers.common['X-Access-Token'] = oktaAccessToken;
}

export const clearOktaAccessToken = () => {
  delete instance.defaults.headers.common['X-Access-Token'];
}

export default instance;