/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme, Radio, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Field, FormikHelpers } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import snakeCase from 'lodash.snakecase';
import { useSelector } from 'react-redux';
import { DialogTitle, DialogContent } from '../../../../components/Dialog';
import RomService from '../../../../api/rom';
import { CompanyInstrument } from '../../../../models/CompanyInstrument';
import { saveAsFile } from '../../../../utils';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: '#838383',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 8,
  },
  checkboxGroup: {
    '& .MuiFormControlLabel-label': { fontSize: '1.2rem' },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  radio: {
    color: '#707070',
  },
  radioChecked: {
    '&.Mui-checked': {
      color: '#707070',
    },
    '&.Mui-checked:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  countInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonDownload: {
    borderRadius: 24,
    marginLeft: 16,
    backgroundColor: '#e84637',
    fontSize: '1.2rem',
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
  },
  separator: {
    margin: '18px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonSubmit: {
    backgroundColor: '#fd101b',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelSubmit: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

interface FormValues {
  type: string;
  sortBy: string;
  topHolderCount: number;
  lastHolderCount: number;
}

const initialValues = {
  type: 'all',
  sortBy: 'Balance',
  topHolderCount: 50,
  lastHolderCount: 50,
};

interface Props {
  instrument?: CompanyInstrument;
}

const DownloadHolderReportDialog: React.FC<Props> = ({ instrument }) => {
  const classes = useStyles();
  const { t } = useTranslation('register_of_members');
  const [open, setOpen] = React.useState(false);
  const lang = useSelector(selectLang);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = React.useCallback(
    (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      if (instrument) {
        const params: any = { lang };

        if (values.type === 'all') params.topN = 'all';
        else if (values.type === 'top') params.topN = values.topHolderCount;
        else if (values.type === 'last') params.topN = values.lastHolderCount;
        params.sortOrder = values.type === 'top' ? 'desc' : 'asc';
        params.sortBy = values.sortBy;
        if (params.sortBy === 'Balance') params.sortOrder = 'desc';

        RomService.getHoldersReport(instrument.insOid, params)
          .then((res) => {
            saveAsFile(
              new Blob([res.data]),
              `report-${instrument.cmpName.en}.xlsx`,
            );
          })
          .catch((e) => {
            alert('Fail to download file');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [instrument, lang],
  );

  return (
    <div>
      <Button
        disabled={!instrument}
        variant="contained"
        className={classes.buttonDownload}
        onClick={handleClickOpen}
      >
        {t('download_holders_report')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="download-holder-report-dialog-title"
        open={open}
      >
        <DialogTitle
          id="download-holder-report-dialog-title"
          onClose={handleClose}
        >
          {t('holders_list_report')}
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, submitForm, isSubmitting, setFieldValue }) => (
              <>
                <div className={classes.checkboxGroup}>
                  <div className={classes.label}>{t('number_of_holders')}</div>
                  <Field
                    component={RadioGroup}
                    name="type"
                    style={{ flexDirection: 'row' }}
                  >
                    {['all', 'top'].map((field) => (
                      <FormControlLabel
                        key={`type::${field}`}
                        value={field}
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.radioChecked,
                            }}
                            disableRipple
                            disabled={isSubmitting}
                            onChange={(e) => {
                              if (e.target.value !== 'all')
                                setFieldValue('sortBy', 'Balance');
                            }}
                          />
                        }
                        label={
                          field === 'all' ? (
                            t(field)
                          ) : (
                            <div className={classes.countInput}>
                              {t(field)}
                              <Field
                                component={TextField}
                                name={`${field}HolderCount`}
                                type="number"
                                inputProps={{ disabled: values.type !== field }}
                                style={{ margin: '0 8px', width: 80 }}
                                onBlur={(e: any) => {
                                  const count = parseInt(e.target.value, 10);
                                  if (isNaN(count) || count <= 0) {
                                    setFieldValue(e.target.name, 50);
                                  }
                                }}
                              />
                              {t('holders')}
                            </div>
                          )
                        }
                        disabled={isSubmitting}
                      />
                    ))}
                  </Field>
                </div>

                <div className={classes.checkboxGroup}>
                  <div className={classes.label}>{t('sort_by')}</div>
                  <Field
                    component={RadioGroup}
                    name="sortBy"
                    style={{ flexDirection: 'row' }}
                  >
                    {['Balance', 'FolioNo', 'HdrName1'].map((field, index) => (
                      <FormControlLabel
                        key={`sort_by::${field}`}
                        value={field}
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.radioChecked,
                            }}
                            disableRipple
                            disabled={isSubmitting}
                          />
                        }
                        label={t(snakeCase(field))}
                        disabled={values.type !== 'all' || isSubmitting}
                      />
                    ))}
                  </Field>
                </div>

                <div className={classes.bottomContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.buttonSubmit,
                      label: classes.labelSubmit,
                    }}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    {t('common:download')}
                  </Button>
                  <Button
                    variant="outlined"
                    classes={{ label: classes.labelCancel }}
                    onClick={handleClose}
                    disabled={isSubmitting}
                  >
                    {t('common:cancel')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DownloadHolderReportDialog;
