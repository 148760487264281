/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button, Dialog } from '@material-ui/core';
import { Column } from 'react-table';
import SelectColumnFilter, {
  ColumnProps,
} from '../../../components/SelectCloumnFilter';
import Entity from '../../../models/Entity';
import PaginationTable from '../../../components/PaginationTable';
import AdminService from '../../../api/admin';
import EntityService from '../../../api/entity';
import { EntityResponse } from '../../../models/EntityResponse';
import { UserReducerContext } from './duck';
import { useSelector } from 'react-redux';
import { selectIsChecker, selectStaffInfo } from '../../auth/duck/selector';
import { useAppDispatch } from '../../../store';
import { makerRequestSuccess } from '../../auth/duck/authDuck';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

const COLUMNS: Array<Column<Entity>> = [
  {
    Header: 'manage_company:file_code',
    accessor: 'fileCode',
    disableFilters: true,
  },
  {
    Header: 'manage_company:name',
    accessor: 'name',
    width: 300,
    disableFilters: true,
  },
  {
    Header: 'manage_company:status',
    accessor: 'status',
    filter: 'includes',
    Filter: (props: ColumnProps) => (
      <SelectColumnFilter
        {...props}
        customOptions={[
          { baseString: 'Active', en: 'Active', zh: 'Active', cn: 'Active' },
          {
            baseString: 'Pending',
            en: 'Pending',
            zh: 'Pending',
            cn: 'Pending',
          },
          {
            baseString: 'Closing',
            en: 'Closing',
            zh: 'Closing',
            cn: 'Closing',
          },
          { baseString: 'Closed', en: 'Closed', zh: 'Closed', cn: 'Closed' },
          {
            baseString: 'Marked for Deletion',
            en: 'Marked for Deletion',
            zh: 'Marked for Deletion',
            cn: 'Marked for Deletion',
          },
        ]}
      />
    ),
  },
  {
    Header: 'entity_management:date_of_incorporation',
    accessor: 'incorpDateString',
    disableFilters: true,
  },
];

const TabEntityManagement: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const {
    state: { user },
    dispatch,
  } = React.useContext(UserReducerContext);
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const isChecker = useSelector(selectIsChecker);
  const staffInfo = useSelector(selectStaffInfo);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      EntityService.getEntities(
        pageSize,
        pageIndex,
        query,
        sortBy?.id === 'incorpDateString'
          ? { ...sortBy, id: 'incorporateDate' }
          : sortBy,
        filters?.[0]?.id,
        filters?.[0]?.value,
        user?.id,
      ).then(({ data: { data, totalPage } }) => {
        if (fetchId === fetchIdRef.current) {
          setEntities(data.map((item: EntityResponse) => new Entity(item)));
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [user?.emCodes],
  );

  const getUser = React.useCallback(() => {
    if (user) {
      AdminService.getCompanyUser(user.id).then((res) => {
        dispatch({ type: 'SET_USER', payload: { user: res } });
      });
    }
  }, []);

  const renderActions = (item: Entity) => {
    const assigned = user?.emCodes?.includes(item.id);
    return (
      <div className="table-row-actions">
        <Button
          disabled={isChecker && Boolean(staffInfo)}
          aria-label="assign"
          variant={isChecker && Boolean(staffInfo) ? 'text' : 'contained'}
          className={
            assigned && !(isChecker && Boolean(staffInfo))
              ? classes.buttonOn
              : classes.buttonOff
          }
          onClick={() => {
            if (!user) return;
            if (assigned) {
              EntityService.removeUser(user.id, item.id)
                .then(() => {
                  getUser();
                  appDispatch(makerRequestSuccess());
                })
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                });
            } else {
              EntityService.addUser(user.id, item.id)
                .then(() => {
                  getUser();
                  appDispatch(makerRequestSuccess());
                })
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                });
            }
          }}
        >
          {assigned ? 'Unassign' : 'Assign'}
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={15}
        columns={COLUMNS}
        data={entities}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name, relationship manager"
        placeholder={t('manage_user:entity_management_placeholder')}
        renderActions={renderActions}
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TabEntityManagement;
