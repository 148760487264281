import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

const RedirectToOktaLogin: React.FC = ({
}) => {
  const { oktaAuth } = useOktaAuth();
  oktaAuth.signInWithRedirect();
  
  return null;
};

export default RedirectToOktaLogin;