/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ClickAwayListener,
  Tooltip,
  Select,
  MenuItem,
} from '@material-ui/core';
import { format, getYear } from 'date-fns';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector } from 'react-redux';
import ReportsService from '../../../api/reports';
import { CompanyInstrument } from '../../../models/CompanyInstrument';
import {
  HoldersListIcon,
  TopHoldersListIcon,
  TransferJournalIcon,
} from '../../../assets';
import { selectLang } from '../../settings/duck/selector';
import { ListedCompany } from '../../../models/ListedCompany';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    button: {
      width: 250,
      borderRadius: 4,
      background: '#fff',
      marginRight: 15,
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      '&:hover': {
        background: '#fff',
      },
    },
    buttonOn: {
      border: 'solid 2px #e84637',
    },
    buttonLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#b4b4b4',
    },
    buttonLabelOn: {
      color: '#e84637',
    },
    list: {
      width: 'fit-content',
    },
    listItem: {
      width: 250,
      padding: '24px 48px 24px 24px',
      background: 'white',
      '&:hover': {
        backgroundColor: '#ffedeb',
      },
    },
    listItemIcon: {
      height: 24,
      width: 24,
      minWidth: 36,
    },
    listItemText: {},
    listItemTextPrimary: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    classOfSecurities: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
    },
    buttonSelectClass: {
      background: '#fff',
      borderRadius: 4,
      border: 'solid 2px #e84637',
      height: 33,
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#e84637',
      textTransform: 'none',
    },
    tooltip: {
      margin: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      padding: 0,
    },
    listItemGutters: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    listItemClass: {
      fontSize: '1.6rem !important',
      fontWeight: 'bold',
      color: '#b4b4b4',
    },
    menuItem: {
      backgroundColor: '#838383 !important',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#838383 !important',
      },
    },
    selected: {
      backgroundColor: '#838383',
      fontSize: 14,
      fontWeight: 'bold',
      color: 'red',
    },
  }),
);

const currentYear = getYear(new Date());
const YEARS = [currentYear];

while (YEARS[YEARS.length - 1] > 2021) {
  YEARS.push(YEARS[YEARS.length - 1] - 1);
}

interface Props {
  company?: ListedCompany;

  companyType?: string;

  selectedInsturment?: CompanyInstrument;

  onSelectInsturment: (data: CompanyInstrument) => void;
}

const ScheduledReports: React.FC<Props> = ({
  company,
  companyType,
  selectedInsturment,
  onSelectInsturment,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('reports');

  const [open, setOpen] = React.useState(false);

  const [data, setReports] = React.useState<any[]>([]);
  const [category, setCategory] = React.useState('Top Holders List');
  const [year, setYear] = React.useState(currentYear.toString());
  const lang = useSelector(selectLang);

  React.useEffect(() => {
    if (selectedInsturment) {
      ReportsService.getScheduledReports(
        selectedInsturment.insOid,
        selectedInsturment.insCode,
        companyType || '',
        year,
      ).then((res) => {
        setReports(res.data);
      });
    }
  }, [selectedInsturment, year]);

  const TABS = [
    {
      category: 'Holders List',
      label: t('holders_list'),
      icon: HoldersListIcon,
    },
    {
      category: 'Transfer Journal',
      label: t('transfer_journal'),
      icon: TransferJournalIcon,
    },
    {
      category: 'Top Holders List',
      label: t('top_holders_list'),
      icon: TopHoldersListIcon,
    },
  ];

  return (
    <div>
      <div className={classes.row} style={{ marginBottom: 12 }}>
        <span className={classes.classOfSecurities}>{t('select_year')}</span>
        <Select
          disableUnderline
          value={year}
          defaultValue
          onChange={(e: any) => {
            setYear(e.target.value);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            getContentAnchorEl: null,
          }}
        >
          {YEARS.map((item) => (
            <MenuItem
              key={item.toString()}
              value={item.toString()}
              classes={{
                root: classes.menuItem,
                selected: classes.selected,
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <div style={{ flex: 1 }} />
        <span className={classes.classOfSecurities}>
          {t('register_of_members:class_of_securities')}
        </span>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <Tooltip
              interactive
              placement="bottom-start"
              classes={{ tooltip: classes.tooltip }}
              PopperProps={{ disablePortal: true }}
              onClose={() => setOpen(false)}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <List>
                  {company?.instruments
                    ?.filter(
                      (item) => item.insOid !== selectedInsturment?.insOid,
                    )
                    .map((item) => (
                      <ListItem
                        button
                        key={item.insOid}
                        onClick={() => {
                          setOpen(false);
                          onSelectInsturment(item);
                        }}
                        classes={{ gutters: classes.listItemGutters }}
                      >
                        <ListItemText
                          classes={{ primary: classes.listItemClass }}
                          primary={item?.mitName?.[lang]}
                        />
                      </ListItem>
                    ))}
                </List>
              }
            >
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                className={classes.buttonSelectClass}
              >
                {selectedInsturment?.mitName?.[lang] || ''}
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
      {TABS.map((item) => (
        <Button
          disableRipple
          variant="contained"
          classes={{
            root: clsx(classes.button, {
              [classes.buttonOn]: category === item.category,
            }),
            label: clsx(classes.buttonLabel, {
              [classes.buttonLabelOn]: category === item.category,
            }),
          }}
          onClick={() => setCategory(item.category)}
          startIcon={
            <item.icon
              fill={category === item.category ? '#e84637' : '#b4b4b4'}
            />
          }
        >
          {item.label}
        </Button>
      ))}
      <List
        className={classes.list}
        style={{
          marginLeft:
            265 * (TABS.findIndex((item) => item.category === category) || 0),
        }}
      >
        {data
          .filter((item) => item.category === category)
          .map((report) => (
            <ListItem
              button
              key={report.fileName}
              className={classes.listItem}
              onClick={() => {
                const link = document.createElement('a');
                link.href = report.downloadUrl;
                link.setAttribute('download', report.fileName);
                document.body.appendChild(link);
                link.click();
              }}
            >
              <ListItemIcon>
                <DescriptionIcon className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText
                primary={format(new Date(report.publishDate), 'MMMM yyyy', {
                  locale:
                    lang === 'en'
                      ? enLocale
                      : lang === 'zh'
                      ? zhLocale
                      : cnLocale,
                })}
                className={classes.listItemText}
                classes={{ primary: classes.listItemTextPrimary }}
              />
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default ScheduledReports;
