/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Container, Grid, Hidden, Paper, Box } from '@material-ui/core';
import { createStyles, Theme, makeStyles, styled } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import clsx from 'clsx';
import { logoDark, loginBg, login_new_background, issuerPortal } from '../../assets';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /*
    root: {
      flexGrow: 1,
      minHeight: '100vh',
      backgroundColor: '#f4f5f5',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    gridContainer: {
      height: '100vh',
      [theme.breakpoints.down('xs')]: {
        height: 'unset',
      },
    },
    grid: {
      display: 'flex',
      justifyContent: 'center',
    },
    square: {
      // width: '44vw',
      width: '100%',
      minHeight: '44vw',
      [theme.breakpoints.up('lg')]: {
        // width: '36vw',
        minHeight: '36vw',
      },
      [theme.breakpoints.down('xs')]: {
        // margin: '0 5% 0 5%',
        // width: '90vw',
        minHeight: '90vw',
      },
    },
    squareLeft: {
      float: 'right',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f4f5f5',
    },
    logo: {
      width: '30%',
      cursor: 'pointer',
    },
    issuerPortal: {
      width: '40%',
      cursor: 'pointer',
    },
    loginBg: {
      width: '100%',
    },
    title: {
      fontSize: '2rem',
      lineHeight: 1.2,
      color: '#454545',
      marginBottom: 20,
      [theme.breakpoints.up('lg')]: {
        fontSize: '3rem',
      },
    },
    formContainer: {
      width: '80%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      justifyContent: 'center',
    },
    forgetPassword: {
      alignSelf: 'flex-end',
      color: '#B2B2B2',
      textDecoration: 'unset',
      fontStyle: 'italic',
      fontSize: '1.4rem',
      marginTop: 8,
    },
    button: {
      width: '100%',
      marginTop: 25,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      borderRadius: 7,
      backgroundColor: theme.palette.text.hint,
      '&:hover': {
        backgroundColor: theme.palette.text.hint,
      },
    },
    buttonBack: {
      alignSelf: 'flex-start',
      '&:hover': {
        backgroundColor: 'unset',
      },
      '& svg': {
        height: 12,
        width: 12,
      },
    },
    back: {
      width: 'unset',
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      color: '#454545',
      marginBottom: 48,
      textTransform: 'capitalize',
    },
    */

    root: {
      position: 'relative',
      display: 'flex',
      minHeight: '100vh',
      width: '100vw',
      flexDirection: 'column',
      '@media (min-width: 768px)': {
        flexDirection: 'row',
      }
    },
    backgroundImageContainer: {
      position: 'relative',
      display: 'flex',
      height: '200px',
      width: '100%',
      flexShrink: 0,
      overflow: 'hidden',
      '@media (min-width: 768px)': {
        height: 'auto',
        width: '50%',
      }
    },
    backgroundImageWrapper: {
      backgroundColor: '#B6C3CE',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      position: 'absolute',
    },
    backgroundImagePosition: {
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    backgroundImage: {
      objectPosition: 'left',
      objectFit: 'cover',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      height: '100%',
      margin: 0,
      maxWidth: 'none',
      padding: 0,
      width: '100%',
      display: 'block',
      verticalAlign: 'middle',
      border: 0,
    },
    backgroundImageContentWrapper: {
      zIndex: 10,
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'start',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingInlineStart: '22.5px',
      '@media (min-width: 768px)': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingInlineStart: '40px',
      }
    },
    tricorLogo: {
      width: '115px',
    },
    backgroundImageTitle: {
      margin: 0,
      fontSize: '20px',
      lineHeight: 'calc(1em + 8px)',
      color: 'rgba(255,255,255,1)',
      fontWeight: 500,
      '@media (min-width: 768px)': {
        fontSize: '24px',
        lineHeight: 'calc(1em + 8px)',
        width: '100%',
        alignItems: 'center',
      }
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      '@media (min-width: 768px)': {
        overflowY: 'auto',
        width: '50%',
        height: 'auto',
      }
    },
    issuerPortalLogoContainer: {
      margin: '10px 22.5px 0',
      justifyContent: 'start',
      alignItems: 'center',
      flexDirection: 'row',
      height: '54px',
      display: 'flex',
      '@media (min-width: 768px)': {
        margin: '20px 24px 0',
      },
    },
    issuerPortalLogo: {
      width: '170px',
    },
    formContainer: {
      height: 'calc(100% - 85px)',
      margin: '0 22.5px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '@media (min-width: 768px)': {
        margin: '20px 24px 0',
      },
    }
  }),
);

interface Props {
  children: React.ReactNode;

  canBack?: boolean;

  toPath?: string;
}

const LoginContainer: React.FC<Props> = ({ children, canBack, toPath }) => {
  const { t } = useTranslation('login');
  const classes = useStyles();
  const history = useHistory();

  return (

    <Box className={classes.root}>
      <Box className={classes.backgroundImageContainer}>
        <Box className={classes.backgroundImageWrapper}>
          <Box className={classes.backgroundImagePosition}>
            <img className={classes.backgroundImage} src={login_new_background} />
          </Box>
        </Box>
        <Box className={classes.backgroundImageContentWrapper}>
          <img className={classes.tricorLogo} src={logoDark} />
          <h1 className={classes.backgroundImageTitle}>
            <span>{t('root_title')}</span>
          </h1>
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.issuerPortalLogoContainer}>
          <img src={issuerPortal} className={classes.issuerPortalLogo} alt="tricor logo" />
        </Box>
        <Box className={classes.formContainer}>
          {children}
        </Box>
      </Box>
    </Box>

    // <Container className={classes.root} disableGutters maxWidth={false}>
    //   <Grid
    //     container
    //     direction="row"
    //     // alignItems="center"
    //     justify="space-evenly"
    //     className={classes.gridContainer}
    //   // spacing={3}
    //   >
    //     <Grid item xs={12} sm={6} lg={5}>
    //       <div style={{backgroundImage:`url(${login_new_background})`}}>
    //         <Paper
    //           elevation={0}
    //           className={clsx(classes.square, classes.squareLeft)}
    //         >
    //           <img
    //             src={logoDark}
    //             className={classes.logo}
    //             alt="tricor logo"
    //             onClick={() => history.replace('/')}
    //           />
    //           <img
    //             src={issuerPortal}
    //             className={classes.issuerPortal}
    //             alt="tricor logo"
    //             onClick={() => history.replace('/')}
    //           />
    //           <div className="f1" />
    //           <img
    //             src={loginBg}
    //             className={classes.loginBg}
    //             alt="login background"
    //           />
    //           {/* <div className={classes.title}>{t('title')}</div> */}
    //         </Paper>
    //       </div>
    //     </Grid>
    //     <Grid item xs={12} sm={6} lg={5} className={classes.grid}>
    //       <Box className={classes.square}>
    //         <div className={classes.formContainer}>
    //           {canBack && (
    //             <Button
    //               disableFocusRipple
    //               disableRipple
    //               disableTouchRipple
    //               aria-label="back"
    //               onClick={() =>
    //                 toPath ? history.replace('/') : history.goBack()
    //               }
    //               startIcon={
    //                 <ArrowBackIosRoundedIcon
    //                   fontSize="small"
    //                   htmlColor="#454545"
    //                 />
    //               }
    //               classes={{ root: classes.buttonBack, label: classes.back }}
    //             >
    //               Go Back 返回
    //             </Button>
    //           )}
    //           {children}
    //         </div>
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </Container>
  );
};

export default LoginContainer;
