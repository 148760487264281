import React from 'react';
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Dialog,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import {
  format,
  isBefore,
  isSameDay,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns';

import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { DialogTitle, DialogContent } from '../../../../components/Dialog';
import { CalendarEvent } from '../../../../models/CalendarEvent';
import CalendarService from '../../../../api/calendar';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonAddEvent: {
      background: '#707070',
      padding: 4,
      '&:hover': {
        backgroundColor: '#707070',
        opacity: 0.8,
      },
    },
    dialog: {
      minWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        minWidth: '80%',
      },
    },
    content: {},
    formContainer: {
      flex: 1,
      overflow: 'auto',
    },
    timeLabel: {
      fontSize: '1.2rem',
      color: '#262626',
      alignSelf: 'center',
      minWidth: 100,
    },
    dateInput: {
      backgroundColor: '#fff',
      '& input': {
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-root': {
        marginTop: 4,
      },
      '& .MuiOutlinedInput-input': {
        padding: '6px !important',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
      '& legend': {
        display: 'none',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'unset',
        transition: 'unset',
      },
    },
    allDay: {
      marginLeft: 0,
      marginRight: 0,
      minWidth: 120,
    },
    bottomContainer: {
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    button: {
      height: 33,
      borderRadius: 24,
      minWidth: 128,
      padding: '0 16px',
      textTransform: 'capitalize',
    },
    buttonSubmit: {
      backgroundColor: '#e84637',
      marginRight: 16,
      '&:hover': {
        backgroundColor: '#fd101b',
        opacity: 0.8,
      },
    },
    buttonLabel: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: ' #fff',
    },
    buttonDelete: {
      borderColor: '#e84637',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
        opacity: 0.8,
      },
    },
    labelDelete: {
      color: '#e84637',
    },
  }),
);

interface Props {
  event?: CalendarEvent;

  onClose: () => void;

  refreshEvents: () => void;
}

interface FormValues extends Omit<CalendarEvent, 'title'> {
  startDate: Date;
  endDate: Date;
  title: string;
}

const EditEvent = ({ event, onClose, refreshEvents }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('home');
  const [open, setOpen] = React.useState(false);
  const lang = useSelector(selectLang);

  React.useEffect(() => {
    if (event) {
      setOpen(true);
    }
  }, [event]);

  const handleClose = () => setOpen(false);

  const validate = (values: FormValues) => {
    const errors: { [name: string]: any } = {};
    if (!values.title.trim()) errors.title = t('error:required');
    if (!values.description.trim()) errors.description = t('error:required');

    const [startHours, startMins] = values.startTime.split(':');
    const [endHours, endMins] = values.endTime.split(':');

    if (isBefore(values.endDate, values.startDate)) {
      errors.startDate = t('common:start_date_should_before_end_date');
    } else if (
      isSameDay(values.endDate, values.startDate) &&
      !values.isWholeDay
    ) {
      if (
        Number(endHours) < Number(startHours) ||
        (Number(endHours) === Number(startHours) &&
          Number(endMins) <= Number(startMins))
      ) {
        errors.endTime = t('common:end_time_should_be_after_start_time');
      }
    }

    return errors;
  };

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    if (!event) return;
    const {
      startDate,
      endDate,
      startTime,
      endTime,
      title,
      description,
      isWholeDay,
    } = values;
    const [startHours, startMins] = startTime.split(':');
    const [endHours, endMins] = endTime.split(':');

    CalendarService.updateEvent(event.id, {
      title,
      description,
      startTime: format(
        setHours(setMinutes(startDate, Number(startMins)), Number(startHours)),
        "yyyy-MM-dd'T'HH:mm",
      ),
      endTime: format(
        setHours(setMinutes(endDate, Number(endMins)), Number(endHours)),
        "yyyy-MM-dd'T'HH:mm",
      ),
      isWholeDay,
    })
      .then(() => {
        refreshEvents();
        actions.resetForm();
        setOpen(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const onClickDelete = () => {
    if (!event) return;

    CalendarService.deleteEvent(event.id).then(() => {
      refreshEvents();
      setOpen(false);
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      onExited={onClose}
      aria-labelledby="update-event-dialog-title"
      open={open}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="update-event-dialog-title" onClose={handleClose}>
        {t('edit_an_event')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {event && (
          <Formik
            initialValues={{
              ...event,
              title: event.title[lang],
              startDate: startOfDay(new Date(event.startTime)),
              endDate: startOfDay(new Date(event.endTime)),
              startTime: format(new Date(event.startTime), 'HH:mm'),
              endTime: format(new Date(event.endTime), 'HH:mm'),
            }}
            validate={validate}
            onSubmit={onSubmit}
            validateOnBlur
            initialTouched={{ endTime: true, startDate: true }}
          >
            {({ values, submitForm, isSubmitting, errors }) => (
              <>
                <div className={classes.formContainer}>
                  <Form autoComplete="off">
                    <Field
                      fullWidth
                      component={TextField}
                      name="title"
                      label={t('title')}
                    />
                    <div style={{ width: 20 }} />
                    <Field
                      fullWidth
                      component={TextField}
                      name="description"
                      label={t('description')}
                      multiline
                    />
                    <div className="row" style={{ margin: '12px 0' }}>
                      <div className={classes.timeLabel}>{t('start_time')}</div>
                      <Field
                        fullWidth
                        component={KeyboardDatePicker}
                        name="startDate"
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: 'end' }}
                        className={classes.dateInput}
                        maxDateMessage={t('common:date_must_before_2100')}
                        invalidDateMessage={t('common:please_enter_valid_date')}
                        minDateMessage={t('common:date_should_after_2021')}
                        minDate={new Date(2021, 0, 1)}
                      />
                      <div style={{ width: 20 }} />
                      <Field
                        fullWidth
                        component={TextField}
                        name="startTime"
                        type="time"
                        InputProps={{ disabled: values.isWholeDay }}
                      />
                      <Field
                        component={CheckboxWithLabel}
                        name="isWholeDay"
                        Label={{
                          label: t('all_day'),
                          classes: { root: classes.allDay },
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div className="row">
                      <div className={classes.timeLabel}>{t('end_time')}</div>
                      <Field
                        fullWidth
                        component={KeyboardDatePicker}
                        name="endDate"
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        minDate={values.startDate}
                        InputAdornmentProps={{ position: 'end' }}
                        className={classes.dateInput}
                        maxDateMessage={t('common:date_must_before_2100')}
                        minDateMessage={t(
                          'common:start_date_should_before_end_date',
                        )}
                        invalidDateMessage={t('common:please_enter_valid_date')}
                      />
                      <div style={{ width: 20 }} />
                      <Field
                        fullWidth
                        component={TextField}
                        name="endTime"
                        type="time"
                        InputProps={{ disabled: values.isWholeDay }}
                      />
                      <div className={classes.allDay} />
                    </div>
                    <div className={classes.bottomContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        classes={{
                          root: clsx(classes.button, classes.buttonSubmit),
                          label: classes.buttonLabel,
                        }}
                        disableElevation
                        onClick={submitForm}
                        disabled={
                          isSubmitting ||
                          Object.keys(errors).some(
                            (key: any) => (errors as any)[key],
                          )
                        }
                      >
                        {t('common:save')}
                      </Button>
                      <Button
                        variant="outlined"
                        classes={{
                          root: clsx(classes.button, classes.buttonDelete),
                          label: clsx(classes.buttonLabel, classes.labelDelete),
                        }}
                        disableElevation
                        onClick={onClickDelete}
                        disabled={isSubmitting}
                      >
                        {t('common:delete')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditEvent;
