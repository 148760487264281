import React from 'react';
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
  ClickAwayListener,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { useSelector } from 'react-redux';
import OnDemandReport from './OnDemandReport';
import { ListedCompany } from '../../../models/ListedCompany';
import { CompanyInstrument } from '../../../models/CompanyInstrument';
import { selectLang } from '../../settings/duck/selector';
import AuthService from '../../../api/auth';
import { selectUserInfo } from '../../auth/duck/selector';
import LearnMoreDialog from '../../../components/LearnMoreDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    buttons: {
      flex: 1,
      maxWidth: 265 * 3,
    },
    button: {
      width: 250,
      borderRadius: 4,
      background: '#fff',
      marginRight: 15,
      marginBottom: 30,
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      '&:hover': {
        background: '#fff',
      },
    },
    buttonLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#262626',
      textTransform: 'none',
    },
    buttonDownload: {
      padding: '6px 16px',
      borderRadius: 24,
      backgroundColor: '#e84637',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#e84637',
        opacity: 0.8,
      },
    },
    tooltip: {
      margin: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      padding: 0,
    },
    listItemGutters: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    listItemClass: {
      fontSize: '1.6rem !important',
      fontWeight: 'bold',
      color: '#b4b4b4',
    },
    buttonSelectClass: {
      background: '#fff',
      borderRadius: 4,
      border: 'solid 2px #e84637',
      height: 33,
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#e84637',
      textTransform: 'none',
    },
    classOfSecurities: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
    },
  }),
);

interface Props {
  company?: ListedCompany;

  selectedInsturment?: CompanyInstrument;

  onSelectInsturment: (data: CompanyInstrument) => void;
}

const OnDemand: React.FC<Props> = ({
  company,
  selectedInsturment,
  onSelectInsturment,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('reports');
  const [reportType, setReportType] = React.useState<string>();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const user = useSelector(selectUserInfo);
  const lang = useSelector(selectLang);

  if (reportType) {
    return (
      <OnDemandReport
        onPressBack={() => setReportType('')}
        reportType={reportType}
        company={company}
        instrument={selectedInsturment}
        onSelectInsturment={onSelectInsturment}
      />
    );
  }

  const TABS = [
    {
      category: 'Holders List',
      label: t('holders_list'),
    },
    {
      category: 'Top Holders List',
      label: t('top_holders_list'),
    },
    {
      category: 'Oversea Holders List',
      label: t('oversea_holders_list'),
    },
    {
      category: 'Transfer Journal',
      label: t('transfer_journal'),
    },
    {
      category: 'Allotment Report',
      label: t('allotment_report'),
    },
    {
      category: 'Buyback Report',
      label: t('buyback_report'),
    },
    {
      category: 'Other Report',
      label: t('other_report'),
    },
  ];

  return (
    <div>
      <LearnMoreDialog
        open={!!message}
        handleClose={() => {
          setMessage('');
        }}
        message={message}
      />
      <div className={classes.row} style={{ marginBottom: 12 }}>
        <span className={classes.classOfSecurities}>
          {t('register_of_members:class_of_securities')}
        </span>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <Tooltip
              interactive
              placement="bottom-start"
              classes={{ tooltip: classes.tooltip }}
              PopperProps={{ disablePortal: true }}
              onClose={() => setOpen(false)}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <List>
                  {company?.instruments
                    ?.filter(
                      (item) => item.insOid !== selectedInsturment?.insOid,
                    )
                    .map((item) => (
                      <ListItem
                        button
                        key={item.insOid}
                        onClick={() => {
                          setOpen(false);
                          onSelectInsturment(item);
                        }}
                        classes={{ gutters: classes.listItemGutters }}
                      >
                        <ListItemText
                          classes={{ primary: classes.listItemClass }}
                          primary={(item?.mitName as any)?.[lang]}
                        />
                      </ListItem>
                    ))}
                </List>
              }
            >
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                className={classes.buttonSelectClass}
              >
                {(selectedInsturment?.mitName as any)?.[lang] || ''}
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
      <div className={classes.container}>
        <div className={classes.buttons}>
          {TABS.map((item) => (
            <Button
              disableRipple
              variant="contained"
              classes={{
                root: classes.button,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                setReportType(item.category);
              }}
            >
              {item.label}
            </Button>
          ))}
        </div>
        <div>
          <Button
            className={classes.buttonDownload}
            endIcon={<MailOutlineRoundedIcon htmlColor="#fff" />}
            onClick={() => {
              if (user && company)
                AuthService.requestNewReport(user.id, company.cmpEngName).then(
                  (res) => {
                    setMessage(t('common:learn_more_request_new_report'));
                  },
                );
            }}
          >
            {t('request_new_report')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnDemand;
