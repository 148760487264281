import React from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import PaginationTable from '../../../components/PaginationTable';
import AdminService from '../../../api/admin';
import CancelIcon from '@material-ui/icons/Cancel';
import { Application } from '../../../models/Application';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 12,
    '& .react-table .td': {
      minHeight: 34,
      lineHeight: 'unset',
      height: 'unset',
      whiteSpace: 'break-spaces',
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleIcon: {
    '& g': {
      '& g': {
        '& path': {
          fill: '#000 !important',
        },
      },
    },
  },
  buttonAccept: {
    backgroundColor: '#fd101b',
    color: '#fff',
    fontWeight: 600,
    textTransform: 'none',
  },
  buttonReject: {
    backgroundColor: '#fff',
    color: '#fd101b',
    borderColor: '#fd101b',
    fontWeight: 600,
    textTransform: 'none',
  },
}));

const TabRejected: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('manage_applications');
  const history = useHistory();

  const [data, setData] = React.useState<Application[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getRejectedApplications(
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const COLUMNS = React.useMemo<Array<Column<Application>>>(
    () => [
      {
        Header: 'manage_applications:name_email',
        accessor: 'username',
        disableFilters: true,
        Cell: ({ row: { original } }: any) =>
          `${original.name || ''} (${original.username})`,
      },
      {
        Header: 'manage_applications:account_type',
        accessor: 'accountType',
        disableFilters: true,
      },
      {
        Header: 'manage_applications:user_type',
        accessor: 'role',
        disableFilters: true,
        Cell: ({ value }: any) =>
          value === 'ClientUser'
            ? 'Client User'
            : value === 'ClientMaker'
            ? 'Client Maker'
            : value === 'ClientChecker'
            ? 'Client Checker'
            : value === 'TricorSuperAdmin'
            ? 'Tricor Super Admin'
            : value === 'TricorMaker'
            ? 'Tricor Maker'
            : value === 'TricorChecker'
            ? 'Tricor Checker'
            : '-',
      },
      {
        Header: 'manage_applications:date',
        accessor: 'requestedAt',
        disableFilters: true,
        Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd'),
      },
      {
        Header: 'manage_applications:requested_by',
        accessor: 'requestedBy',
        disableFilters: true,
      },
    ],
    [t],
  );

  const onClickRow = (item: Application) => {
    if (item.id) {
      history.push(`/admin/manage_application/${item.id}`);
    }
  };

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={10}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by user name or email"
        placeholder={t('manage_user:select_user_placeholder')}
        onClickRow={onClickRow}
        renderHeaderLeft={
          <>
            <CancelIcon className={clsx('section-icon', classes.titleIcon)} />
            <span className="section-title">
              {t('manage_applications:rejected')}
            </span>
          </>
        }
      />
    </div>
  );
};

export default TabRejected;
