/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Button,
} from '@material-ui/core';
import HeaderGroup from '../../components/HeaderGroup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    image: {},
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    message: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      margin: '18px 0',
    },
    contactTricor: {
      fontSize: '1.2rem',
      color: '#262626',
    },
    button: {
      marginTop: 48,
      backgroundColor: '#fd101b',
      '&:hover': {
        backgroundColor: '#fd101b',
        opacity: 0.8,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    label: {
      textTransform: 'capitalize',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: ' #fff',
    },
  }),
);

interface Props {
  message: string;

  image: string;
}
export const ContactUs: React.FC<Props> = ({ message, image }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={false} md={6} lg={7} />
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
      </Grid>
      <div className={classes.content}>
        <img src={image} alt="contact us image" />
        <div className={classes.message}>{message}</div>
        <div className={classes.contactTricor}>
          Contact Tricor Services to find out more about this service.
        </div>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disableFocusRipple
          disableRipple
          classes={{
            root: classes.button,
            label: classes.label,
          }}
          href="mailto:info@hk.tricorglobal.com"
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
};

export default ContactUs;
