/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PaginationTable from '../../../../components/PaginationTable';
import { CompanyInstrument } from '../../../../models/CompanyInstrument';
import RomService from '../../../../api/rom';
import { ShareMovementHistory } from '../../../../models/ShareMovementHistory';
import { ColumnProps } from '../../../../components/SelectCloumnFilter';
import MultiLang from '../../../../models/MultiLang';
import SelectCloumnMultipleFilter from '../../../../components/SelectCloumnMultipleFilter';
import { saveAsFile } from '../../../../utils';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) => ({
  downloadButton: {
    marginRight: 8,
    borderRadius: 24,
    marginLeft: 16,
    backgroundColor: '#e84637',
    fontSize: '1.2rem',
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
  },
}));
interface Props {
  instrument?: CompanyInstrument;
}

const TabShareMovementHistory: React.FC<Props> = ({ instrument }) => {
  const { t } = useTranslation('register_of_members');
  const classes = useStyles();
  const [data, setData] = React.useState<ShareMovementHistory[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const lang = useSelector(selectLang);
  const [options, setOptions] = React.useState<{
    [field: string]: MultiLang[];
  }>({});
  const [tableParams, setTableParams] = React.useState<any>({});
  const [historyDateRange, setHistoryDateRange] = React.useState({
    startDate: '',
    endDate: '',
  });

  const fetchIdRef = React.useRef(0);

  const COLUMNS = React.useMemo<Array<Column<ShareMovementHistory>>>(() => {
    return [
      {
        Header: 'register_of_members:date_of_event',
        accessor: 'trx_Date',
        disableFilters: true,
        disableSortBy: true,
        width: 100,
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
      },
      {
        Header: 'register_of_members:nature',
        accessor: 'master_Para_Field',
        disableSortBy: true,
        disableFilters: !options.nature,
        Filter: (props: ColumnProps) => (
          <SelectCloumnMultipleFilter
            {...props}
            customOptions={options.nature}
          />
        ),
        Cell: ({ value }) => value[lang],
      },
      {
        Header: (
          <div style={{ flex: 1, textAlign: 'end' }}>
            {t('register_of_members:transaction_unit_or_shares')}
          </div>
        ),
        accessor: 'trx_Balance_Change',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
      },
      {
        Header: (
          <div style={{ flex: 1, textAlign: 'end' }}>
            {t('register_of_members:issued_securities_balance')}
          </div>
        ),
        accessor: 'issued_Balance',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
      },
    ];
  }, [options, lang, t]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, startDate, endDate, filters }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      if (instrument) {
        setHistoryDateRange({ startDate, endDate });
        RomService.getShareMovementHistoryFilterOptions(
          instrument.insOid,
          startDate,
          endDate,
        ).then((res) => {
          setOptions({ nature: res.data });
        });

        const filterObj = filters?.reduce(
          (acc: Map<string, any>, item: any) => ({
            ...acc,
            [item.id]: item.value,
          }),
          {},
        );
        setTableParams({ filterV3: filterObj });
        RomService.getShareMovementHistory(
          instrument?.insOid,
          pageSize,
          pageIndex,
          query,
          sortBy,
          startDate,
          endDate,
          filterObj,
        )
          .then((res) => {
            if (fetchId === fetchIdRef.current) {
              setData(res.data);
              setPageCount(res.totalPage);
            }
          })
          .catch((e) => {
            if (fetchId === fetchIdRef.current) {
              setData([]);
              setPageCount(0);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [instrument],
  );

  const onClickDownloadReport = () => {
    if (!instrument) return;
    RomService.getShareMovementHistoryReport(
      instrument.insOid,
      historyDateRange.startDate,
      historyDateRange.endDate,
      { ...tableParams, lang },
    )
      .then((res) => {
        saveAsFile(
          new Blob([res.data]),
          `movement-history-report-${instrument.cmpEngName}.xlsx`,
        );
      })
      .catch((e) => {
        alert('Fail to download file');
      });
  };

  return (
    <PaginationTable
      key={instrument?.insOid}
      defaultPageSize={15}
      columns={COLUMNS}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      searchPlaceholder={t('share_movement_history_search_placeholder')}
      placeholder={t('share_movement_history_placeholder')}
      filterByDate
      renderHeaderRight={
        <Button
          variant="contained"
          className={classes.downloadButton}
          onClick={onClickDownloadReport}
          disableElevation
        >
          {t('common:export')}
        </Button>
      }
    />
  );
};

export default TabShareMovementHistory;
