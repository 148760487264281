import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Paper,
  Button,
} from '@material-ui/core';
import {
  dots,
  dollarOrange,
  dollarPurple,
  teamGreen,
  teamPurple,
  peopleBlue,
  IssueSummaryIcon,
} from '../../../../assets';
import HolderList from './HolderList';
import { CompanyInstrument } from '../../../../models/CompanyInstrument';
import { selectLang } from '../../../settings/duck/selector';
import DownloadHolderReportDialog from './DownloadHolderReport';
import LearnMoreDialog from '../../../../components/LearnMoreDialog';
import AuthService from '../../../../api/auth';
import { CompanyUser } from '../../../../models/CompanyUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   flexGrow: 1,
    },
    paper: {
      flex: 1,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
    },
    shareClassContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    classOfShare: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
    },
    summaryItem: {
      flex: 1,
      padding: 16,
    },
    summaryItemIcon: {
      height: 24,
      width: 24,
    },
    select: {
      width: '100%',
      borderRadius: 4,
      border: 'solid 0.5px #b4b4b4',
      backgroundColor: '#fff',
      paddingLeft: 12,
      '&:focus': {
        borderRadius: 4,
      },
    },
    selectIcon: {
      top: 'unset',
      fill: '#fd101b',
      height: 18,
      width: 18,
    },
    company: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#262626',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '1.4rem',
      color: '#707070',
      whiteSpace: 'break-spaces',
    },
    value: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      color: '#262626',
    },
    sortOn: {
      backgroundColor: '#fd0f1b',
      color: '#fff',
      textTransform: 'capitalize',
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: '#fd101b',
        opacity: 0.8,
      },
      '& svg': {
        fill: '#fff',
        stroke: '#fff',
      },
    },
    sortOff: {
      fontSize: '1rem',
      color: '#262626',
      textTransform: 'capitalize',
      '& svg': {
        fill: '#fd0f1b',
        stroke: '#fd0f1b',
      },
    },
    contactUs: {
      fontSize: 12,
      marginLeft: 4,
      color: '#fff',
      textDecoration: 'unset',
      textTransform: 'none',
      backgroundColor: '#fd0f1b',
      '&:hover': {
        backgroundColor: '#fd0f1b',
      },
    },
  }),
);

interface Props {
  instrument?: CompanyInstrument;
  user?: CompanyUser | null;
}

const TabHolderList: React.FC<Props> = ({ user, instrument }) => {
  const classes = useStyles();
  const { t } = useTranslation('register_of_members');
  const lang = useSelector(selectLang);
  const [message, setMessage] = React.useState<string>();

  const summaries = [
    {
      field: `${t('no_of_holders')}\n `,
      value: instrument?.hdrCnt?.toLocaleString() || '-',
      icon: peopleBlue,
    },
    {
      field: `${t('issued_capital')}\n `,
      value: instrument?.localCap?.toLocaleString() || '-',
      icon: dots,
    },
    {
      field: `${t('par_value')}\n `,
      value: instrument?.parValue
        ? `${instrument.parValueCurrency?.[lang]} ${parseFloat(
            instrument?.parValue.toString(),
          ).toLocaleString(undefined, {
            maximumFractionDigits: 20,
            minimumFractionDigits: 0,
          })}`
        : t('not_applicable'),
      icon: dollarOrange,
    },
    {
      field: `${t('trading_counter')}\n `,
      value: instrument?.tradeCurrency?.[lang] || '-',
      icon: dollarPurple,
    },
    {
      field: t('registered_holders'),
      value: t(
        instrument?.corpComm === 'T' ? 'has_corp_comm' : 'not_applicable',
      ),
      contactUs: instrument?.corpComm !== 'T',
      icon: teamPurple,
      feature: 'eCorpCommForRH',
    },
    {
      field: t('hkscc_non_registered_holders'),
      value: t(
        instrument?.corpCommNRH === 'T' ? 'has_corp_comm' : 'not_applicable',
      ),
      contactUs: instrument?.corpCommNRH !== 'T',
      icon: teamGreen,
      feature: 'eCorpCommForNonRH',
    },
  ];

  const onClickLearnMore = (feature: string) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (!user) return;

    AuthService.learnMore(
      user.id,
      user.accessRights.registerOfMember ? `${feature}?insOid=${instrument?.insOid}` : 'CSCompany'
      ,
    ).then((res) => {
      setMessage(
        t('common:learn_more_message', {
          feature: t(
            `common:${
              feature === 'eCorpCommForRH'
                ? 'learn_more_hkscc_registered_holders'
                : 'learn_more_hkscc_non_registered_holders'
            }`,
          ),
        }),
      );
    });
  };

  return (
    <div className={classes.root}>
      <LearnMoreDialog
        open={!!message}
        handleClose={() => {
          setMessage(undefined);
        }}
        message={message || ''}
      />
      <div className="section-container">
        <IssueSummaryIcon className="section-icon" />
        <span className="section-title f1">{t('issue_summary')}</span>
        {instrument && <DownloadHolderReportDialog instrument={instrument} />}
      </div>
      <Grid container spacing={2} alignItems="stretch">
        {summaries.map((item) => (
          <Grid item key={item.field} xs={3} style={{ display: 'flex' }}>
            <Paper className={classes.summaryItem}>
              <div className={classes.label}>
                {item.field}
                <img
                  src={item.icon}
                  alt={item.field}
                  className={classes.summaryItemIcon}
                />
              </div>
              <div className={classes.value}>
                {item.value}
                {item.contactUs && item.feature && (
                  <Button
                    disableElevation
                    className={classes.contactUs}
                    onClick={onClickLearnMore(item.feature)}
                    size="small"
                  >
                    {t('request_for_service')}
                  </Button>
                )}
              </div>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12}>
          <HolderList instrumentId={instrument?.insOid} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TabHolderList;
