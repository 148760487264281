/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useReducer, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import Person from '@material-ui/icons/PersonRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import TabPanel, { a11yProps } from '../../../../components/TabPanel';
import { registerOfMember, entityManagement } from '../../../../assets';
import TabUserProfile from './TabUserProfile';
import TabEntityManagement from './TabEntityManagement';
import TabRegisterOrMembers from './TabRegisterOfMembers';
import AdminService from '../../../../api/admin';
import { userReducer, UserReducerContext } from '../duck';
import TabTricorChecker from './TabTricorChecker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   flexGrow: 1,
    },
    backContainer: {
      padding: '0 !important',
    },
    backButton: {
      fontSize: '1.2rem',
      color: '#707070',
      textTransform: 'none',
      '& svg': {
        fontSize: '1.25rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#262626',
      paddingBottom: '0px !important',
    },
    tab: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      backgroundColor: '#f3f3f3',
      border: '1px solid #e7e7e7',
      color: '#ababab',
      marginRight: 4,
      borderTop: '4px solid #e7e7e7',
    },
    tabSelected: {
      borderBottomWidth: 0,
      backgroundColor: '#fff',
      color: '#000',
      borderTop: '4px solid #fd0f1b',
    },
    tabBorder: {
      width: '100%',
      height: 1,
      position: 'absolute',
      background: '#e7e7e7',
      bottom: 0,
    },
    wrapper: {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0px !important',
        marginRight: 6,
        height: '1em',
        width: '1em',
        '& path': {
          fill: '#f50057',
          opacity: 1,
        },
      },
    },
    indicator: {
      display: 'none',
    },
  }),
);

const TABS = [
  { label: 'profile', icon: Person },
  { label: 'register_of_members', icon: registerOfMember },
  { label: 'entity_management', icon: entityManagement },
  // { label: 'contact_point', icon: entityManagement },
];

const TABS_MAKER = [{ label: 'tricor_checker', icon: entityManagement }];

const ManageStaff: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('manage_user');
  const [state, dispatch] = useReducer(userReducer, { user: undefined });

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const [value, setValue] = React.useState(0);

  const { id } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    AdminService.getStaff(id)
      .then((res) => {
        dispatch({ type: 'SET_USER', payload: { user: res.data } });
      })
      .catch((e) => {});
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <UserReducerContext.Provider value={contextValue}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.backContainer}>
            <Button
              className={classes.backButton}
              startIcon={<ArrowBackIcon htmlColor="#707070" />}
              disableFocusRipple
              disableTouchRipple
              disableRipple
              onClick={() => history.goBack()}
            >
              {t('back_staff')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={8} className={classes.name}>
            <div>{state.user?.name}</div>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
              classes={{ indicator: classes.indicator }}
            >
              <div className={classes.tabBorder} />
              {TABS.map((item, index) => (
                <Tab
                  {...a11yProps(index)}
                  key={item.label}
                  icon={<item.icon htmlColor="#f50057" />}
                  value={index}
                  label={t(item.label)}
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  classes={{
                    root: clsx(classes.tab, {
                      [classes.tabSelected]: index === value,
                    }),
                    wrapper: classes.wrapper,
                  }}
                />
              ))}
              {state.user?.role === 'TricorMaker' &&
                TABS_MAKER.map((item, index) => (
                  <Tab
                    {...a11yProps(TABS.length + index)}
                    key={item.label}
                    icon={<item.icon />}
                    value={TABS.length}
                    label={t(item.label)}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    classes={{
                      root: clsx(classes.tab, {
                        [classes.tabSelected]: TABS.length + index === value,
                      }),
                      wrapper: classes.wrapper,
                    }}
                  />
                ))}
            </Tabs>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
          <TabUserProfile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabRegisterOrMembers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabEntityManagement />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <TabContactPersons />
        </TabPanel> */}
        {state.user?.role === 'TricorMaker' && (
          <TabPanel value={value} index={3}>
            <TabTricorChecker />
          </TabPanel>
        )}
      </div>
    </UserReducerContext.Provider>
  );
};

export default ManageStaff;
