/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Rating } from '@material-ui/lab';
import { DialogTitle, DialogContent } from './Dialog';
import { CompanyUser } from '../models/CompanyUser';
import AuthService from '../api/auth';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0',
  },
  description: {
    flex: 1,
    fontSize: '1rem',
    fontStyle: 'italic',
    color: '#262626',
  },
  feedbackButton: {
    backgroundColor: '#b4b4b4',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#b4b4b4',
      opacity: 0.8,
    },
  },
  labelFeedback: {
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    color: 'white',
  },
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  form: {
    '& label': {
      color: 'red',
    },
  },
  rating: {
    fontSize: '1.3rem',
    fontWeight: 'bold',

    marginTop: 20,
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonSubmit: {
    backgroundColor: '#fd101b',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelSubmit: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
  star: {
    color: '#454545 !important',
  },
}));

interface Props {
  user: CompanyUser;
  open: boolean;
  onClick?: () => void;
  onClose: () => void;
}

const FeedbackDialog: React.FC<Props> = ({ user, open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="feeback-dialog-title"
      open={open}
    >
      <DialogTitle id="feeback-dialog-title" onClose={onClose}>
        {t('feedback:give_us_feedback_title')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.message}>
          {t('feedback:give_us_feedback_message')}
        </div>
        <Formik
          initialValues={{
            rating: 5,
            comments: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (user) {
              AuthService.sendUserFeedback(
                user.id,
                values.rating,
                values.comments,
              )
                .then((res) => {
                  onClose();
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }
          }}
        >
          {({ values, submitForm, isSubmitting, setFieldValue }) => (
            <>
              <Form className={classes.form}>
                <div>
                  <label className={classes.rating}>
                    {t('feedback:rating')}
                  </label>
                </div>
                <Rating
                  style={{ marginTop: 8 }}
                  size="large"
                  classes={{ label: classes.star }}
                  name="simple-controlled"
                  precision={1}
                  value={values.rating}
                  onChange={(event, newValue) => {
                    setFieldValue('rating', newValue);
                  }}
                />
                <Field
                  fullWidth
                  component={TextField}
                  name="comments"
                  label={t('feedback:comments')}
                  inputProps={{
                    // placeholder: 'Input content here',
                    style: {
                      minHeight: 200,
                      borderRadius: 2,
                      border: 'solid 1px #b4b4b4',
                      padding: 8,
                    },
                  }}
                  multiline
                />
              </Form>
              <div style={{ textAlign: 'center', marginTop: 24 }}>
                <Button
                  variant="contained"
                  color="primary"
                  classes={{
                    root: classes.buttonSubmit,
                    label: classes.labelSubmit,
                  }}
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {t('submit')}
                </Button>
              </div>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackDialog;
