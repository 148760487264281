import React from 'react';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Button, Theme } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useHistory, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import CardCompanyProfile from '../../../components/CardCompanyProfile';
import Entity from '../../../models/Entity';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyUser } from '../../../models/CompanyUser';
import RemoveUserDialog from '../components/RemoveUserDialog';
import EntityService from '../../../api/entity';
import SelectUser from '../components/SelectUserDialog';

const COLUMNS: Array<Column<CompanyUser>> = [
  {
    Header: 'name_card:name',
    accessor: 'name',
    disableFilters: true,
  },
  {
    Header: 'name_card:email',
    accessor: 'username',
    disableFilters: true,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  backContainer: {
    padding: '0 !important',
  },
  backButton: {
    fontSize: '1.2rem',
    color: '#707070',
    textTransform: 'none',
    '& svg': {
      fontSize: '1.25rem !important',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },
  left: {
    height: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  buttonCreate: {
    backgroundColor: '#fd101b',
    marginRight: 8,
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelCreate: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
}));

const ManageCompany: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [company, setCompany] = React.useState<Entity>();
  const [users, setUsers] = React.useState<CompanyUser[]>([]);

  const getUsers = () => {
    EntityService.getUsers(id).then(({ data }) => setUsers(data));
  };

  React.useEffect(() => {
    EntityService.getEntity(id).then((res) => setCompany(new Entity(res)));
    getUsers();
  }, []);

  const removeUser = (user: CompanyUser, onSuccess: () => void) => {
    if (!company) return;
    EntityService.removeUser(user.id, company.fileCode).then(() => {
      onSuccess();
      getUsers();
    });
  };

  const renderActions = (data: CompanyUser) => {
    if (!company) return null;

    return (
      <div className="table-row-actions">
        <RemoveUserDialog user={data} removeUser={removeUser} />
      </div>
    );
  };

  const onSaveUsers = (
    usersMap: { [userId: string]: boolean },
    onSuccess: () => void,
    onError: () => void,
  ) => {
    if (!company) return;
    const promiseArr = Object.keys(usersMap).map((userId) =>
      usersMap[userId]
        ? EntityService.addUser(userId, company.fileCode)
        : EntityService.removeUser(userId, company.fileCode),
    );
    Promise.all(promiseArr)
      .then(() => {
        onSuccess();
        getUsers();
      })
      .catch((e) => {
        onError();
      });
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={4}
      className={classes.root}
    >
      <Grid item xs={12} className={classes.backContainer}>
        <Button
          className={classes.backButton}
          startIcon={<ArrowBackIcon htmlColor="#707070" />}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          onClick={() => history.goBack()}
        >
          {t('manage_company:back')}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} className={classes.left}>
        <div className={classes.column}>
          <div className="section-container">
            <PeopleAltRoundedIcon className="section-icon" />
            <span className="section-title f1">
              {t('manage_company:existing_users')}
            </span>
            <SelectUser
              buttonText={t('manage_company:add_user')}
              onSave={onSaveUsers}
              currentUsers={users}
            />
          </div>
          <PaginationTable
            disableSearch
            manualControl={false}
            defaultPageSize={20}
            columns={COLUMNS}
            data={users}
            loading={false}
            renderActions={renderActions}
            placeholder={t('manage_company:existing_users_placeholder')}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="section-container">
          <NotesIcon className="section-icon" />
          <span className="section-title">
            {t('manage_company:company_profile')}
          </span>
        </div>
        <CardCompanyProfile
          name={company?.name}
          address={company?.address}
          directors={company?.directors}
        />
      </Grid>
    </Grid>
  );
};

export default ManageCompany;
