/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Grid,
  makeStyles,
  Button,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { Column } from 'react-table';
import { useSelector } from 'react-redux';
import GroupIcon from '@material-ui/icons/GroupRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import StarIcon from '@material-ui/icons/StarBorderRounded';
import clsx from 'clsx';
import PaginationTable from '../../../../components/PaginationTable';
import AdminService from '../../../../api/admin';
import { TricorStaff } from '../../../../models/TricorStaff';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingTop: 12,
  },
  paper: {
    flex: 1,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
  },
  gridRight: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridLeft: {
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  assignButtonOff: {
    color: '#fff',
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
  },
  assignButton: {
    minWidth: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
    padding: '4px 8px',
    fontSize: '1rem',
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  buttonIcon: {
    fontSize: 14,
    marginLeft: 4,
  },
  name: {
    fontSize: '1.4rem',
    color: '#707070',
  },
  mail: {
    fontSize: '1.2rem',
    color: '#707070',
  },
  chip: {
    borderRadius: 4,
    marginLeft: 8,
    height: 'unset',
  },
  chipLabel: {
    fontSize: '1.2rem',
    paddingLeft: 4,
    paddingRight: 4,
  },
  tooltip: {
    fontSize: '1.2rem',
  },
  placeholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholder: {
    alignSelf: 'center',
    fontSize: '1.2rem',
    fontStyle: 'italic',
    color: '#b4b4b4',
  },
}));

const COLUMNS: Array<Column<TricorStaff>> = [
  {
    Header: 'manage_user:name',
    accessor: 'displayName',
    disableFilters: true,
  },
  {
    Header: 'manage_company:email',
    accessor: 'mail',
    disableFilters: true,
  },
];

interface Props {
  contactPoints: {
    isPrimary: boolean;
    staff: TricorStaff;
  }[];

  onChange: (
    data: {
      isPrimary: boolean;
      staff: TricorStaff;
    }[],
  ) => void;
}

const TabContactPersons: React.FC<Props> = ({ contactPoints, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_user');
  const [editing, setEditing] = React.useState(false);
  const [staffs, setStaffs] = React.useState<TricorStaff[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  React.useEffect(() => {
    if (contactPoints?.length === 0) setEditing(false);
  }, [contactPoints]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getTricorStaffs(
        pageSize,
        pageIndex,
        query,
        sortBy,
        filters?.[0]?.id,
        filters?.[0]?.value,
      ).then(({ data: { data, totalPage } }) => {
        if (fetchId === fetchIdRef.current) {
          setStaffs(data);
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const assignRM = React.useCallback(
    (staff: TricorStaff) => {
      onChange([
        ...contactPoints,
        { staff, isPrimary: contactPoints.length === 0 },
      ]);
    },
    [contactPoints],
  );

  const unassignRM = React.useCallback(
    (staff: TricorStaff) => {
      const assignedStaff = contactPoints.find(
        (item) => item.staff.id === staff.id,
      );

      onChange(
        contactPoints
          .filter((item) => item.staff.id !== staff.id)
          .map((item, index) =>
            index === 0 && assignedStaff?.isPrimary
              ? { ...item, isPrimary: true }
              : item,
          ),
      );
    },
    [contactPoints, onChange],
  );

  const setPrimary = React.useCallback(
    (staff: TricorStaff) => {
      onChange(
        contactPoints.map((item) => ({
          ...item,
          isPrimary: item.staff.id === staff.id,
        })),
      );
    },
    [contactPoints, onChange],
  );

  const renderActions = (item: TricorStaff) => {
    const assigned = contactPoints?.find((staff) => staff.staff.id === item.id);

    return (
      <div className="table-row-actions">
        <Button
          aria-label="assign"
          variant="contained"
          className={clsx(classes.assignButton, {
            [classes.assignButtonOff]: assigned,
          })}
          onClick={() => {
            if (assigned) {
              unassignRM(item);
            } else {
              assignRM(item);
            }
          }}
        >
          {t(assigned ? 'common:unassign' : 'common:assign')}
        </Button>
      </div>
    );
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={4}
      className={classes.root}
    >
      <Grid item xs={12} md={6} className={classes.gridLeft}>
        <PaginationTable
          defaultPageSize={10}
          columns={COLUMNS}
          data={staffs}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          searchPlaceholder="Search by name or email"
          placeholder={t('manage_user:staffs_placeholder')}
          renderActions={renderActions}
          renderHeaderLeft={
            <>
              <GroupIcon className="section-icon" />
              <span className="section-title">{t('manage_user:staffs')}</span>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridRight}>
        <div className="section-container">
          <GroupIcon className="section-icon" />
          <span className="section-title f1">
            {t('manage_user:management_team')}
          </span>
          {contactPoints && contactPoints?.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={
                editing ? (
                  <CheckIcon className={classes.buttonIcon} />
                ) : (
                  <EditIcon className={classes.buttonIcon} />
                )
              }
              className="button-create"
              onClick={() => setEditing((state) => !state)}
            >
              {t(`common:${editing ? 'done' : 'edit'}`)}
            </Button>
          )}
        </div>
        <Paper
          className={clsx(classes.paper, {
            [classes.placeholderContainer]: contactPoints.length === 0,
          })}
        >
          {contactPoints && contactPoints?.length > 0 ? (
            <List>
              {contactPoints.map((item) => {
                const labelId = `checkbox-list-secondary-label-${item.staff.id}`;
                return (
                  <ListItem key={item.staff.id}>
                    <ListItemText
                      id={labelId}
                      primary={
                        <>
                          {item.staff.displayName}
                          {item.isPrimary && (
                            <Chip
                              label={t('primary')}
                              color="primary"
                              classes={{
                                root: classes.chip,
                                label: classes.chipLabel,
                              }}
                            />
                          )}
                        </>
                      }
                      secondary={item.staff.mail}
                      classes={{
                        primary: classes.name,
                        secondary: classes.mail,
                      }}
                    />
                    {editing && (
                      <ListItemSecondaryAction>
                        {!item.isPrimary && (
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={t('set_as_primary') || ''}
                            placement="top"
                          >
                            <IconButton
                              aria-label="primary"
                              onClick={() => setPrimary(item.staff)}
                            >
                              <StarIcon htmlColor="#000" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          title={t('remove') || ''}
                          placement="top"
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => unassignRM(item.staff)}
                          >
                            <CloseIcon htmlColor="#fd101b" />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div className={classes.placeholder}>Assign a staff</div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TabContactPersons;
