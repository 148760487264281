/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  makeStyles,
  Theme,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { DialogTitle, DialogContent } from '../../../../components/Dialog';
import { selectLang } from '../../../settings/duck/selector';
import RomService from '../../../../api/rom';
import { saveAsFile } from '../../../../utils';

function add(A: string, B: string) {
  const AL = A.length;
  const BL = B.length;
  const ML = Math.max(AL, BL);

  let carry = 0;
  let sum = '';

  for (let i = 1; i <= ML; i++) {
    const a = +A.charAt(AL - i);
    const b = +B.charAt(BL - i);

    let t = carry + a + b;
    carry = (t / 10) | 0;
    t %= 10;

    sum = i === ML && carry ? carry * 10 + t + sum : t + sum;
  }

  return sum;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonEdit: {
    height: 39,
    borderRadius: 20,
    backgroundColor: '#e84637',
    '&:hover': {
      backgroundColor: '#e84637',
      opacity: 0.8,
    },
    '& .MuiButton-label': {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: '#fff',
    },
  },
  buttonDownload: {
    height: 25,
    borderRadius: 20,
    marginBottom: 20,
    backgroundColor: '#e84637',
    '&:hover': {
      backgroundColor: '#e84637',
      opacity: 0.8,
    },
    '& .MuiButton-label': {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: '#fff',
    },
  },
  from: {
    fontSize: '1.4rem',
    paddingRight: 28,
    color: '#262626',
  },
  to: {
    fontSize: '1.4rem',
    padding: '0 28px',
    color: '#262626',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
      padding: '8px 0',
    },
    marginBottom: 8,
    '& td': {
      verticalAlign: 'initial',
    },
  },
  sectionTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: '#262626',
  },
  label: {
    fontSize: '1.2rem',
    color: '#262626',
    flex: 2,
  },
  value: {
    flex: 3,
    fontSize: '1.2rem',
    color: '#262626',
  },
  input: {
    padding: '6px 15px',
    textAlign: 'end',
    '&:focus-visible': {
      outline: '#e84637 auto 1px',
    },
  },
}));

interface Props {
  insOid?: string;
}

const RangeOfHoldingsReport: React.FC<Props> = ({ insOid }) => {
  const classes = useStyles();
  const lang = useSelector(selectLang);
  const { t } = useTranslation('register_of_members');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [ranges, setRanges] = React.useState([
    { from: '1', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
    { from: '', to: '' },
  ]);
  const [errors, setErrors] = React.useState<{ [key: number]: boolean }>({});

  const handleClose = () => {
    setOpen(false);
    setRanges([
      { from: '1', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
      { from: '', to: '' },
    ]);
    setErrors({});
  };

  const onValueChange = (index: number, type: 'from' | 'to') => (
    value: string,
  ) => {
    setRanges((state) => {
      let newRanges = [...state];
      newRanges[index][type] = value;

      if (
        index < ranges.length - 1 &&
        type === 'to' &&
        BigInt(newRanges[index].to) > BigInt(newRanges[index].from)
      ) {
        newRanges[index + 1].from = (BigInt(value) + BigInt(1)).toString();
      }

      let clearAll = false;
      newRanges = newRanges.map((item, i) => {
        if (clearAll) return { from: '', to: '' };
        if (BigInt(item.from) > BigInt(item.to)) {
          if (i < index) {
            clearAll = true;
            return { ...item, to: '' };
          }
          if (i === index) {
            clearAll = true;
            return item;
          }
        }
        return item;
      });

      return newRanges;
    });
  };

  const onClickDownload = () => {
    if (!insOid) return;
    setLoading(true);
    RomService.getRangeOfHoldingsReport(insOid, lang, ranges)
      .then((res) => {
        saveAsFile(new Blob([res.data]), `range_of_holdings_${insOid}.xlsx`);
      })
      .catch((e) => {
        alert('Fail to download file');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        className={classes.buttonEdit}
        endIcon={<EditIcon htmlColor="#fff" />}
        style={{ marginRight: 24 }}
        onClick={() => setOpen(true)}
      >
        {t('common:edit')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="range-of-holdings-dialog-title"
        open={open}
      >
        <DialogTitle id="range-of-holdings-dialog-title" onClose={handleClose}>
          {t('range_of_holdings')}
        </DialogTitle>
        <DialogContent>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {ranges.map((range, index) => (
                <TableRow>
                  <TableCell>
                    <Typography className={classes.from}>
                      {t('from')}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <NumberFormat
                      className={classes.input}
                      thousandSeparator
                      value={range.from}
                      allowNegative={false}
                      inputmode="numeric"
                      disabled
                      onValueChange={(values) => {
                        onValueChange(index, 'from')(values.value);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.to}>{t('to')}</Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <NumberFormat
                      className={classes.input}
                      thousandSeparator
                      decimalScale={0}
                      value={range.to}
                      allowNegative={false}
                      inputmode="numeric"
                      disabled={index > 0 && !ranges[index].from}
                      onValueChange={(values) => {
                        onValueChange(index, 'to')(values.value);
                      }}
                      onBlur={(event) => {
                        const value = event.target.value.replace(/,/g, '');
                        if (value) {
                          setErrors((state) => ({
                            ...state,
                            [index]: BigInt(value) <= BigInt(range.from),
                          }));
                        } else {
                          setErrors((state) => ({ ...state, [index]: false }));
                        }
                      }}
                    />
                    {errors[index] && (
                      <div
                        className="MuiFormHelperText-root Mui-error"
                        style={{ color: '#fd101b', maxWidth: 170 }}
                      >
                        {t('error_input_value_should_be_larger_than', {
                          value: BigInt(range.from).toLocaleString(),
                        })}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              className={classes.buttonDownload}
              onClick={onClickDownload}
              disabled={
                loading ||
                Object.keys(errors).some(
                  (key) => errors[Number(key)] as boolean,
                )
              }
            >
              {t('generate_range_of_holdings_report')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RangeOfHoldingsReport;
