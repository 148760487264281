import React from "react";
import { useAppDispatch } from "../../store"
import { logout } from "./duck/authDuck";
import { useHistory } from "react-router-dom";

const Error: React.FC<{ error: Error }> = ({ error }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    // dispatch(logout());
    alert('Unauthorized');

    React.useEffect(() => {
        history.replace('/');
    }, [])

    return null;
}

export default Error;