import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { selctAccessToken as selectAccessToken } from '../../pages/auth/duck/selector';
import { useOktaAuth } from "@okta/okta-react";
import { setOktaAccessToken } from "../../api/index";
import { OktaTokens } from "../../services/okta/interface";
import { getApplicationAccessToken } from "../../pages/auth/duck/authDuck";
import { useAppDispatch } from "../../store";
import { AuthState } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";

const OktaInterceptor: React.FC = () => {
    const accessToken = useSelector(selectAccessToken);
    const { oktaAuth, authState } = useOktaAuth();
    const dispatch = useAppDispatch();
    const history = useHistory();

    oktaAuth.authStateManager.subscribe((authState: AuthState) => {
        // if (authState.isAuthenticated && authState.accessToken && authState.idToken && authState.refreshToken && !accessToken) {
        //     const oktaTokens: OktaTokens = {
        //         accessToken: authState.accessToken?.accessToken,
        //         idToken: authState.idToken?.idToken,
        //         refreshToken: authState.refreshToken?.refreshToken,
        //         expiresIn: authState.accessToken?.expiresAt,
        //         scope: authState.accessToken?.scopes.join(' '),
        //     }
        //     dispatch(getApplicationAccessToken(oktaTokens))
        //         .then(() => {
        //             history.replace('/');
        //         });
        // }
    });

    return null;
}

export default OktaInterceptor