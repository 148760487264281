import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './App.css';
import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import Login from './pages/auth/Login';
import ForgetPassword from './pages/auth/ForgetPassword';
import SetPassword from './pages/auth/SetPassword';
import RecoverExpired from './pages/auth/RecoverExpired';
import RecoverSuccess from './pages/auth/RecoverSuccess';
import Dashboard from './pages/client/Dashboard';
import AdminDashboard from './pages/admin/AdminDashboard';
import GoogleAnalytics from './GoogleAnalytics';
import StaffDashboard from './pages/staff/StaffDashboard';
import OTP from './pages/auth/OTP';
import RedirectToOktaLogin from './pages/auth/RedirectToOktaLogin';
import OktaInterceptor from './components/okta/OktaInterceptor';
import { SecureRoute, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import OktaService from './services/okta';
import OktaLoginCallback from './pages/auth/OktaLoginCallback';

class ZhLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: number | Date) {
    return format(date, 'yyyy年L月', { locale: this.locale });
  }

  getDatePickerHeaderText(date: number | Date) {
    return format(date, 'M月d日, 星期EEEEE', { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  zh: zhLocale,
  cn: cnLocale,
};

const localeUtilsMap = {
  en: DateFnsUtils,
  zh: ZhLocalizedUtils,
  cn: ZhLocalizedUtils,
};

const oktaAuth = OktaService().oktaAuth;

oktaAuth.start();

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = localeMap[i18n.language as 'en' | 'zh' | 'cn'] || localeMap.en;
  const utils = localeUtilsMap[i18n.language as 'en' | 'zh' | 'cn'] || localeUtilsMap.en;
  const redirectToMaint = false;
  const history = useHistory();

  const restoreOriginalUri: RestoreOriginalUriFunction = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const currentAuthState = oktaAuth.authStateManager.getAuthState();
    if (!currentAuthState || !currentAuthState.isAuthenticated) {
      //user is not authenticated by Okta
      history.replace('/login');
    }
  };

  return (
    <MuiPickersUtilsProvider utils={utils} locale={locale}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
        <OktaInterceptor />
        {!redirectToMaint ?
          <Switch>
            <Route path="/login/callback" component={OktaLoginCallback} />
            <Route path="/login/auth" component={RedirectToOktaLogin} />
            <Route path="/login" exact component={Login} />
            <SecureRoute path="/admin" component={AdminDashboard} />
            <SecureRoute path="/" component={Dashboard} />
          </Switch>
          :
          <Route path="/" component={() => {
            window.location.href = "https://www.tricorglobal.com/systemsuspensionnotice";
            return null;
          }} />
        }
      </Security>
      <GoogleAnalytics />
      {/* <HashRouter>
        {!redirectToMaint ?
          <Switch>
            <Route path="/login/auth" component={RedirectToOktaLogin} />
            <Route path="/login" exact component={Login} />
            <Route path="/otp" component={OTP} />
            <Route path="/recover/code" component={SetPassword} />
            <Route path="/recover/success" component={RecoverSuccess} />
            <Route path="/recover/expire" component={RecoverExpired} />
            <Route path="/recover" exact component={ForgetPassword} />
            <Route path="/admin" component={AdminDashboard} />
            <Route path="/" component={Dashboard} />
          </Switch> :
          <Route path="/" component={(props: any) => {
            window.location.href = "https://www.tricorglobal.com/systemsuspensionnotice";
            return null;
          }} />
        }
        <GoogleAnalytics />
      </HashRouter> */}
    </MuiPickersUtilsProvider>
  );
};

export default App;
