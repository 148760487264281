import React from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button, Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { useSelector } from 'react-redux';
import PaginationTable from '../../../components/PaginationTable';
import AdminService from '../../../api/admin';
import { AccessTime } from '../../../assets';
import { Application } from '../../../models/Application';
import RejectDialog from './RejectDialog';
import {
  selectIsChecker,
  selectIsMaker,
  selectStaffInfo,
} from '../../auth/duck/selector';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import { selectLang } from '../../settings/duck/selector';
import SendReminderDialog from './SendReminderDialog';
import DeleteDialog from './DeleteDialog';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 12,
    '& .react-table .td': {
      minHeight: 34,
      lineHeight: 'unset',
      height: 'unset',
      whiteSpace: 'break-spaces',
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleIcon: {
    '& g': {
      '& g': {
        '& path': {
          fill: '#000 !important',
        },
      },
    },
  },
  buttonAccept: {
    backgroundColor: '#fd101b',
    color: '#fff',
    fontWeight: 600,
    textTransform: 'none',
  },
  buttonReject: {
    backgroundColor: '#fff',
    color: '#fd101b',
    borderColor: '#fd101b',
    fontWeight: 600,
    textTransform: 'none',
  },
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

interface Props {
  setSelectedApprovals: (data: any[]) => void;
  selecting: boolean;
}
const TabPendingApproval: React.FC<Props> = ({
  setSelectedApprovals,
  selecting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_applications');
  const history = useHistory();
  const user = useSelector(selectStaffInfo);
  const isChecker = useSelector(selectIsChecker);
  const isMaker = useSelector(selectIsMaker);

  const [data, setData] = React.useState<Application[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getPendingApprovals(pageSize, pageIndex, query, sortBy).then(
        (res) => {
          if (fetchId === fetchIdRef.current) {
            setData(res.data);
            setPageCount(res.totalPage);
            setLoading(false);
          }
        },
      );
    },
    [],
  );

  const COLUMNS = React.useMemo<Array<Column<Application>>>(
    () => [
      {
        Header: 'manage_applications:name_email',
        accessor: 'username',
        disableFilters: true,
        Cell: ({ row: { original } }: any) =>
          `${original.name || ''} (${original.username})`,
      },
      {
        Header: 'manage_applications:account_type',
        accessor: 'accountType',
        disableFilters: true,
        maxWidth: 120,
      },
      {
        Header: 'manage_applications:user_type',
        accessor: 'role',
        disableFilters: true,
        maxWidth: 120,
        Cell: ({ value }: any) =>
          value === 'ClientUser'
            ? 'Client User'
            : value === 'ClientMaker'
            ? 'Client Maker'
            : value === 'ClientChecker'
            ? 'Client Checker'
            : value === 'TricorSuperAdmin'
            ? 'Tricor Super Admin'
            : value === 'TricorMaker'
            ? 'Tricor Maker'
            : value === 'TricorChecker'
            ? 'Tricor Checker'
            : '-',
      },
      {
        Header: 'manage_applications:date',
        accessor: 'requestedAt',
        disableFilters: true,
        maxWidth: 100,
        Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd'),
      },
      {
        Header: 'manage_applications:requested_by',
        accessor: 'requestedBy',
        disableFilters: true,
      },
    ],
    [t],
  );

  const onClickRow = (item: Application) => {
    if (item.id) {
      history.push(`/admin/manage_application/${item.id}`);
    }
  };

  const renderActions = React.useCallback(
    (item: Application, selected?: boolean) => {
      if (isMaker) {
        return (
          <div className="table-row-actions" style={{ visibility: 'visible' }}>
            <SendReminderDialog
              disabled={selecting}
              applicationIds={[item.id]}
              onSent={() => {
                setCount((state) => state + 1);
              }}
              onSendError={(e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              }}
            />
            <DeleteDialog
              disabled={selecting}
              applicationIds={[item.id]}
              onDeleted={() => {
                setCount((state) => state + 1);
              }}
              onDeleteError={(e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              }}
            />
          </div>
        );
      }
      if (!(isChecker || user?.role === 'TricorSuperAdmin')) return null;
      return (
        <div className="table-row-actions" style={{ visibility: 'visible' }}>
          <Button
            disabled={selecting}
            variant="contained"
            classes={{
              contained: classes.buttonAccept,
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              AdminService.approveApplications([item.id])
                .then(() => {
                  setCount((state) => state + 1);
                })
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                });
            }}
          >
            {t('button_approve')}
          </Button>
          <RejectDialog
            disabled={selecting}
            applicationIds={[item.id]}
            onRejected={() => {
              setCount((state) => state + 1);
            }}
            onRejectError={(e) => {
              let errorMsgKey = 'message';
              if (lang === 'zh') errorMsgKey = 'messageZH';
              else if (lang === 'cn') errorMsgKey = 'messageCN';
              setError(e.response.data[errorMsgKey]);
            }}
          />
        </div>
      );
    },
    [selecting, isChecker, user?.role],
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        key={count}
        defaultPageSize={10}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by user name or email"
        placeholder={t('manage_user:select_user_placeholder')}
        onClickRow={onClickRow}
        renderActions={renderActions}
        renderHeaderLeft={
          <>
            <AccessTime className={clsx('section-icon', classes.titleIcon)} />
            <span className="section-title">
              {t('manage_applications:pending_for_approval')}
            </span>
          </>
        }
        allowSelection
        onSelectedFlatRowsChange={(rows) => {
          setSelectedApprovals(rows);
        }}
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TabPendingApproval;
