/* eslint-disable import/no-cycle */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import ThoughtLeadershipService, {
  CreateArticleValue,
} from '../../../api/thoughtLeadership';
import ArticleForm from './ArticleForm';
import Fileservice from '../../../api/fileservice';
import { Article } from '../../../models/Article';

const useStyles = makeStyles((theme: Theme) => ({
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonSubmit: {
    backgroundColor: '#fd101b',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelSubmit: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

interface Props {
  onEditSuccess: () => void;

  article: Article;
}

const EditArticle: React.FC<Props> = ({ article, onEditSuccess }) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_thought_leadership');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = (values: CreateArticleValue) => {
    const errors: any = {};
    if (!values.title) errors.title = 'Required';
    if (!values.description) errors.description = 'Required';
    if (!values.webLink) errors.webLink = 'Required';

    return errors;
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <EditRoundedIcon htmlColor="#a5a5a5" />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="edit-article-dialog-title"
        open={open}
      >
        <DialogTitle id="edit-article-dialog-title" onClose={handleClose}>
          {t('edit_article')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              ...article,
            }}
            validate={validate}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors },
            ) => {
              const { imageFile, ...others } = values;
              let { imageUrl } = article;
              if (values.imageFile) {
                imageUrl = await Fileservice.uploadArticleImage(
                  values.imageFile,
                );
              }

              ThoughtLeadershipService.editArticle(article.id, {
                ...others,
                imageUrl,
              })
                .then(() => {
                  onEditSuccess();
                  setSubmitting(false);
                  resetForm();
                  handleClose();
                })
                .catch((e) => {
                  setErrors({ title: 'Update article fail.' });
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ values, submitForm, isSubmitting }) => (
              <>
                <ArticleForm values={values} />
                <div className={classes.bottomContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.buttonSubmit,
                      label: classes.labelSubmit,
                    }}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    {t('common:confirm')}
                  </Button>
                  <Button
                    variant="outlined"
                    classes={{ label: classes.labelCancel }}
                    onClick={handleClose}
                    disabled={isSubmitting}
                  >
                    {t('common:cancel')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditArticle;
