/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Tabs,
  Tab,
  Container,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import ReactGA from 'react-ga';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { connect, useSelector } from 'react-redux';
import TabPanel, { a11yProps } from '../../../components/TabPanel';
import { registerOfMember, shareholderAnalytics } from '../../../assets';
import TabHolderList from './holdersList/TabHolderList';
import TabShareMovementHistory from './movementHistory/TabShareMovementHistory';
import { ListedCompany } from '../../../models/ListedCompany';
import SelectCompanyDialog from './SelectCompanyDialog';
import { CompanyInstrument } from '../../../models/CompanyInstrument';
import TabMemberAnalysis from './memberAnalysis/TabMemberAnalysis';
import RomService from '../../../api/rom';
import { CompanyUser } from '../../../models/CompanyUser';
import { RootState } from '../../../store';
import { selectUserInfo } from '../../auth/duck/selector';
import HeaderGroup from '../../../components/HeaderGroup';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
    },
    headerContainer: {
      backgroundColor: '#fff',
      padding: '0 16px',
    },
    contentContainer: {
      padding: '28px 32px',
    },
    company: {
      verticalAlign: 'middle',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
      marginBottom: 8,
    },
    indicator: {
      display: 'none',
    },
    wrapper: {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0px !important',
        marginRight: 6,
        height: '1em',
        width: '1em',
        '& path': {
          fill: '#f50057',
          opacity: 1,
        },
      },
    },
    classOfSecurities: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
    },
    buttonSelectClass: {
      background: '#fff',
      borderRadius: 4,
      border: 'solid 2px #e84637',
      height: 33,
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#e84637',
      textTransform: 'none',
    },
    tooltip: {
      margin: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      padding: 0,
    },
    listItemGutters: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    listItemText: {
      fontSize: '1.6rem !important',
      fontWeight: 'bold',
      color: '#b4b4b4',
    },
  }),
);

const TABS = [
  { label: 'holder_list', icon: registerOfMember },
  { label: 'issued_share_movement_history', icon: SwapHorizIcon },
  { label: 'member_analysis', icon: shareholderAnalytics },
];

interface Props {
  user: CompanyUser | null;

  company?: ListedCompany;

  setCompany: (company: ListedCompany) => void;

  canSelectCompany: boolean;

  setCanSelectCompany: (enable: boolean) => void;
}

const RegisterOfMembers: React.FC<Props> = ({
  user,
  company,
  setCompany,
  canSelectCompany,
  setCanSelectCompany,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('register_of_members');
  const [value, setValue] = React.useState(0);
  const [selected, setSelected] = React.useState<CompanyInstrument>();
  const [open, setOpen] = React.useState(false);

  const lang = useSelector(selectLang);

  React.useEffect(() => {
    const tabNames = [
      'holder-list',
      'issued-secuirites-movement-history',
      'member-analysis',
    ];
    ReactGA.pageview(`/register-of-members/${tabNames[value]}`);
  }, [value]);

  React.useEffect(() => {
    if (!company && user) {
      RomService.getUserRom(user.id, 1, 1).then((res) => {
        setCompany(res.data.data[0]);
        setCanSelectCompany(res.data.totalPage > 1);
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (company?.instruments && company.instruments.length > 0) {
      setSelected(company.instruments[0]);
    }
  }, [company]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <span className={classes.company}>
            {lang === 'en'
              ? company?.cmpEngName
              : company?.cmpChiName || company?.cmpEngName}
            {selected?.insCode ? `(${selected?.insCode})` : ''}
          </span>
          {canSelectCompany && (
            <SelectCompanyDialog
              selectCompany={(item: ListedCompany) => {
                setCompany(item);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
            classes={{ indicator: classes.indicator }}
          >
            {TABS.map((item, index) => (
              <Tab
                {...a11yProps(index)}
                value={index}
                label={t(item.label)}
                disableFocusRipple
                disableTouchRipple
                disableRipple
                className={index === value ? 'filetab selected' : 'filetab'}
                key={item.label}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ display: 'flex', marginBottom: 48 }}
          >
            {company?.instruments && (
              <>
                <span className={classes.classOfSecurities}>
                  {t('class_of_securities')}
                </span>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <Tooltip
                      interactive
                      placement="bottom-start"
                      classes={{ tooltip: classes.tooltip }}
                      PopperProps={{ disablePortal: true }}
                      onClose={() => setOpen(false)}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <List>
                          {company?.instruments
                            ?.filter((item) => item.insOid !== selected?.insOid)
                            .map((item) => (
                              <ListItem
                                button
                                key={item.insOid}
                                onClick={() => {
                                  setOpen(false);
                                  setSelected(item);
                                }}
                                classes={{ gutters: classes.listItemGutters }}
                              >
                                <ListItemText
                                  classes={{ primary: classes.listItemText }}
                                  primary={item?.mitName?.[lang]}
                                />
                              </ListItem>
                            ))}
                        </List>
                      }
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setOpen(true)}
                        className={classes.buttonSelectClass}
                      >
                        {selected?.mitName?.[lang] || ''}
                      </Button>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </>
            )}
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
          <TabHolderList instrument={selected} user={user} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabShareMovementHistory instrument={selected} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabMemberAnalysis instrument={selected} />
        </TabPanel>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

export default connect(mapStateToProps)(RegisterOfMembers);
