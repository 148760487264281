/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useReducer, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Tabs,
  Tab,
  Button,
  Dialog,
} from '@material-ui/core';
import Person from '@material-ui/icons/PersonRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import TabPanel, { a11yProps } from '../../../../components/TabPanel';
import {
  registerOfMember,
  entityManagement,
  applications,
} from '../../../../assets';
import TabUserProfile from './TabUserProfile';
import TabEntityManagement from './TabEntityManagement';
import TabRegisterOrMembers from './TabRegisterOfMembers';
import AdminService from '../../../../api/admin';
import { userReducer, UserReducerContext } from './duck';
import TabContactPersons from './TabContactPersons';
import TabClientChecker from './TabClientChecker';
import RejectDialog from '../RejectDialog';
import { CompanyUser } from '../../../../models/CompanyUser';
import TabHKTicker from './TabHKTicker';
import { selectIsChecker, selectStaffInfo } from '../../../auth/duck/selector';
import { DialogContent, DialogTitle } from '../../../../components/Dialog';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   flexGrow: 1,
    },
    backContainer: {
      padding: '0 !important',
    },
    backButton: {
      fontSize: '1.2rem',
      color: '#707070',
      textTransform: 'none',
      '& svg': {
        fontSize: '1.25rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#262626',
      paddingBottom: '0px !important',
    },
    tab: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      backgroundColor: '#f3f3f3',
      border: '1px solid #e7e7e7',
      color: '#ababab',
      marginRight: 4,
      borderTop: '4px solid #e7e7e7',
    },
    tabSelected: {
      borderBottomWidth: 0,
      backgroundColor: '#fff',
      color: '#000',
      borderTop: '4px solid #fd0f1b',
    },
    tabBorder: {
      width: '100%',
      height: 1,
      position: 'absolute',
      background: '#e7e7e7',
      bottom: 0,
    },
    wrapper: {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0px !important',
        marginRight: 6,
        height: '1em',
        width: '1em',
        '& path': {
          fill: '#f50057',
          opacity: 1,
        },
      },
    },
    indicator: {
      display: 'none',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 12,
      backgroundColor: '#fff',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      alignItems: 'center',
    },

    buttonAccept: {
      backgroundColor: '#fd101b',
      color: '#fff',
      fontWeight: 600,
      textTransform: 'none',
    },
    buttonReject: {
      backgroundColor: '#fff',
      color: '#fd101b',
      borderColor: '#fd101b',
      fontWeight: 600,
      textTransform: 'none',
      marginLeft: 12,
    },
    errorMessage: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
      textAlign: 'center',
      color: '#707070',
      whiteSpace: 'pre-line',
      margin: '24px 0',
    },
  }),
);

const TABS = [
  { label: 'profile', icon: Person },
  // { label: 'relationship_manager', icon: ContactPhoneIcon },
  { label: 'hk_ticker', icon: registerOfMember },
  { label: 'register_of_members', icon: registerOfMember },
  { label: 'entity_management', icon: entityManagement },
  { label: 'contact_point', icon: entityManagement },
  { label: 'client_checker', icon: entityManagement },
];

const ManageApplication: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('manage_user');
  const [state, dispatch] = useReducer(userReducer, {
    user: undefined,
    application: undefined,
  });
  const [loading, setLoading] = React.useState(false);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const [value, setValue] = React.useState(0);

  const { id } = useParams();
  const history = useHistory();

  const isChecker = useSelector(selectIsChecker);
  const user = useSelector(selectStaffInfo);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    let application: CompanyUser | undefined = undefined;
    AdminService.getApplication(id)
      .then((data) => {
        application = data;
        // user already exsists
        if ((application as any).userId > 0) {
          return AdminService.getCompanyUser((application as any).userId);
        }
        // new user
        dispatch({ type: 'SET_APPLICATION', payload: { application } });
        throw Error('user id not found');
      })
      .then((user) => {
        dispatch({
          type: 'SET_APPLICATION',
          payload: { application: application as any },
        });
        dispatch({ type: 'SET_USER', payload: { user } });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const onPressApprove = React.useCallback(() => {
    setLoading(true);
    AdminService.approveApplications([id])
      .then(() => {
        history.goBack();
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <UserReducerContext.Provider value={contextValue}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.backContainer}>
            <Button
              className={classes.backButton}
              startIcon={<ArrowBackIcon htmlColor="#707070" />}
              disableFocusRipple
              disableTouchRipple
              disableRipple
              onClick={() => history.goBack()}
            >
              {t('back')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={8} className={classes.name}>
            <div>{state.user?.name}</div>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
              classes={{ indicator: classes.indicator }}
            >
              <div className={classes.tabBorder} />
              {TABS.filter((item) => {
                switch (item.label) {
                  case 'contact_point':
                    return state.application?.role?.includes('Client');
                  case 'client_checker':
                    // return state.application?.role?.endsWith('Maker');
                    return false;
                  default:
                    return true;
                }
              }).map((item, index) => (
                <Tab
                  {...a11yProps(index)}
                  key={item.label}
                  icon={<item.icon htmlColor="#f50057" />}
                  value={index}
                  label={t(
                    item.label === 'client_checker' &&
                      state.application?.role?.includes('Tricor')
                      ? 'tricor_checker'
                      : item.label,
                  )}
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  classes={{
                    root: clsx(classes.tab, {
                      [classes.tabSelected]: index === value,
                    }),
                    wrapper: classes.wrapper,
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
          <TabUserProfile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabHKTicker />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabRegisterOrMembers />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TabEntityManagement />
        </TabPanel>
        {!state.application?.role?.includes('Tricor') && (
          <TabPanel value={value} index={4}>
            <TabContactPersons />
          </TabPanel>
        )}
        <TabPanel
          value={value}
          index={state.application?.role?.includes('Tricor') ? 4 : 5}
        >
          <TabClientChecker />
        </TabPanel>
        {state.application?.status === 'Pending' &&
          (isChecker || user?.role === 'TricorSuperAdmin') && (
            <div className={classes.footer}>
              <div style={{ flex: 1 }} />
              <Button
                disabled={loading}
                variant="contained"
                classes={{
                  contained: classes.buttonAccept,
                }}
                onClick={onPressApprove}
              >
                {t('manage_applications:button_approve')}
              </Button>
              <RejectDialog
                applicationIds={[id]}
                onRejected={() => {
                  history.goBack();
                }}
                onRejectError={(e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                }}
              />
            </div>
          )}
        <Dialog
          onClose={() => setError('')}
          aria-labelledby="error-dialog-title"
          open={Boolean(error)}
        >
          <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
            Error
          </DialogTitle>
          <DialogContent>
            <div className={classes.errorMessage}>{error}</div>
          </DialogContent>
        </Dialog>
      </div>
    </UserReducerContext.Provider>
  );
};

export default ManageApplication;
